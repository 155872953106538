<template>


    <div class="row">
        <axios :data="input" :url="url" type="post" :trigger="trigger" v-on:success="done" v-on:error="fail"></axios>
        <div class="col-6 col-sm-4 col-md-3">
            <div class="form-group">
                <!--<label for="">Search By</label>-->
                <select id="" class="form-control" v-model="input.keyword_type">
                    <!--<option value="0">Search By</option>-->
                    <option v-for="(search,index) in search_types" :value="index" v-bind:key="index">
                        Search by {{search}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3">
            <div class="form-group">
                <!--<label for="">Keyword</label>-->
                <input type="text" v-model="input.keyword" class="form-control" placeholder="Enter keyword to search">
            </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3">
            <div class="form-group">
                <input type="button" @click="startSearch" :disabled="input.keyword.length==0"
                       class="btn  btn-outline-info mr-2 "
                       value="Search">
                <input type="button" @click="$emit('clear')"
                       class="btn  btn-outline-info "
                       value="All Records">
            </div>

        </div>
    </div>

</template>
<script>
    import Axios from '@/views/actions/Axios';
    export default{
        components: {Axios},
        props: ['search_types', 'url'],
        data(){
            return {
                input: {
                    keyword_type: "1",
                    keyword: '',
                },
                trigger: false,
            }
        },
        methods: {
            done(response){
                this.$emit('filtered', response.data);
                this.trigger = false;
            },
            fail(){
                this.trigger = false;
            },
            startSearch(){
                this.trigger = true;
            }

        }
    }
</script>