<template>
    <div class="col-12 text-right mb-1">
        <button type="button" class="btn btn-outline-info custom-btn" data-toggle="modal"
                data-target="#orderPaymentPopup">
            Add Payment
        </button>
        <div class="modal fade" id="orderPaymentPopup" tabindex="-1" role="dialog" aria-labelledby="orderPaymentPopup"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Make Payment</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <OrderPayment :order="order" :refresh="refresh"
                                      v-on:payment_done="payment_done()"></OrderPayment>
                    </div>
                    <div class="modal-footer">
                        <button type="button" ref="close_payment_popup" class="btn btn-outline-info custom-btn"
                                data-dismiss="modal">Close
                        </button>
                        <!--<button type="button" class="btn btn-outline-info custom-btn">Save changes</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderPayment from '@/views/payment/OrderPayment.vue';
    export default{
        props: ['order', 'refresh'],
        components: {OrderPayment},
        methods: {
            payment_done(){
                this.$refs['close_payment_popup'].click();
                this.$emit('payment_done');
            }
        }
    }
</script>
