<template>
    <div>
        <div class="alert alert-danger" v-if="login_error">{{login_error_msg}}</div>
        <div class="form-group">
            <label for="">Username or e-mail</label>
            <input type="text" class="form-control" v-model="user_name"/>
            <span class="text-danger">{{getError('user_name')}}</span>
        </div>
        <div class="form-group">
            <label for="">Password</label>
            <input type="password" class="form-control" v-model="password"/>
            <span class="text-danger">{{getError('password')}}</span>
        </div>
        <div class="text-center">
            <pulse-loader :loading="loading"></pulse-loader>
        </div>


        <input type="button" @click="login" class="btn btn-outline-info btn-block" value="LOGIN">
        <p class="text-danger mt-3">New to USHalal?</p>
        <router-link :to="{name:'customer.request'}"
                     class="btn btn-block btn-outline-info mt-2 text-uppercase">
            Registration Here
        </router-link>

    </div>

</template>
<script>
    import Forms from '../../forms';
    import PulseLoader from "../../../node_modules/vue-spinner/src/PulseLoader";
    export default{
        components: {PulseLoader},
        extends: Forms,
        data(){
            return {

                user_name: '',
                password: '',
                login_error: false,
                login_error_msg: 'Username or password is incorrect',

            }
        },
        methods: {
            login(){
                let app = this;
                this.loading = true;
                this.$auth.login({
                    params: {
                        user_name: app.user_name,
                        password: app.password
                    },
                    success: function () {
                        let redirect = this.$route.query.redirect;
                        if (redirect == undefined) {
                            this.$router.push({'name': 'dashboard'});
                        } else {
                            this.$router.push({'name': 'dashboard', query: {redirect: redirect}});
                        }

                        app.loading = false;
                    },
                    error: function (error) {
                        app.loading = false;
                        app.clearErrors();
                        if (error.response.status == 401) {
                            app.login_error = true;

                            if (error.response.data.error == 2) {
                                app.login_error_msg = 'Sorry! your account is disabled, please contact support';
                            }
                        }

                        app.setErrors(error.response);
                    },
                    rememberMe: true,

                    fetchUser: true,
                });

            }
        },
        mounted(){
            console.log(this.$route.query.redirect);
        }
    }
</script>