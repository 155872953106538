<template>

    <div class="row ">
        <div class="col-sm-12 bg-light shadow p-3 text-left ">
            <p class="heading">Admin Dashboard</p>
            <hr>

            <div class="row">
                <div class="col-sm-10 mb-2 offset-sm-1">

                    <!-- <DailyChart></DailyChart> -->

                </div>
            </div>

            <div class="row">
                <DashboardIcon route="inventory" src="assets/icons/inventory.png"
                               name="Inventory"></DashboardIcon>
                <DashboardIcon route="accounts" src="assets/icons/accounts.png"
                               name="Accounts"></DashboardIcon>


                <DashboardIcon route="messages" src="assets/icons/message1.png"
                               name="Messages">
                </DashboardIcon>


                <DashboardIcon route="routes" src="assets/icons/route.png"
                               name="Routes"></DashboardIcon>

                <DashboardIcon route="customer.requests" src="assets/icons/registration.jpg"
                               name="Registrations"></DashboardIcon>
                <DashboardIcon route="orders" src="assets/icons/order.png"
                               name="Orders"></DashboardIcon>


            </div>

        </div>
    </div>

</template>
<script>

    import DashboardIcon from '@/views/components/DashboardIcon.vue';
    export default{
        components: {DashboardIcon},
        data(){
            return {}
        },

        methods: {}, mounted(){


        }
    }
</script>

