<template>
    <div class="modal fade" id="add_sales_person" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalCenterTitle">Add Warehouse staff</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-left">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">First Name</label>
                                <input type="text" class="form-control" v-model="input.first_name">
                                <span class="text-danger">{{getError('first_name')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Last Name</label>
                                <input type="text" class="form-control" v-model="input.last_name">
                                <span class="text-danger">{{getError('last_name')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">User Name</label>
                                <input type="text" class="form-control" v-model="input.user_name">
                                <span class="text-danger">{{getError('user_name')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="text" class="form-control" v-model="input.password">
                                <span class="text-danger">{{getError('password')}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="email" class="form-control" v-model="input.email">
                                <span class="text-danger">{{getError('email')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Phone</label>
                                <input type="text" class="form-control" v-model="input.phone">
                                <span class="text-danger">{{getError('phone')}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Address</label>
                                <input type="text" class="form-control" v-model="input.address">
                                <span class="text-danger">{{getError('address')}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>
                    <div class="row" v-else="">
                        <div class="col-sm-12">
                            <button @click="store" type="button" class="btn btn-outline-info shadow">Save</button>
                            &nbsp;
                            <button ref="close_btn" type="button" @click="closeDialog" class="btn btn-danger shadow"
                                    data-dismiss="modal">Close
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';
    //    import {Paths} from '@/paths';
    export default{
        extends: forms,
        props: ['path'],
        data(){
            return {
                input: {
                    first_name: '',
                    last_name: '',
                    user_name: '',
                    password: '',
                    email: '',
                    phone: '',
                    address: ''
                }, success: false,
            }
        }, methods: {
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('added');
                this.$refs.close_btn.click();
            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;
                this.input = {
                    first_name: '',
                    last_name: '',
                    user_name: '',
                    password: '',
                    email: '',
                    phone: '',
                    address: ''
                };
            },
            store(){
                let app = this;
                this.loading = true;
                this.axios.post(app.path, app.input).then(() => {
                    app.success = true;

                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });
            }
        },
        mounted(){

        }
    }
</script>