<template>
    <a href="#" @click.prevent="starClicked">
        <icon icon="star" class=" float-right"
              :class="[is_favourite>0?'text-success':'light-star']"></icon>
        <axios :url="path" :trigger="trigger" :data="{item_id:item_id}" v-on:success="done"
               v-on:error="trigger=false"></axios>
    </a>
</template>
<script>
    import {Paths} from '@/paths';
    import Axios from '@/views/actions/Axios'
    export default{
        props: ['is_favourite', 'item_id'],
        components: {Axios},
        data(){
            return {
                path: '',
                trigger: false,
            }
        },
        methods: {
            done(response){
                this.trigger=false;
                if (this.is_favourite) {
                    this.$emit('notFavourite', response.data);
                    return;
                }
                this.$emit('done', response.data);
            },
            starClicked(){
                this.path = this.is_favourite ? Paths.favourite.cancel : Paths.favourite.do;
                this.trigger = true;

            }
        }
    }
</script>