<template>

    <div class="row mb-5">

        <div class="col-sm-10 col-md-8 col-lg-8 mx-auto">
            <h1 class="text-center font-weight-bold mb-5">Our Product Categories</h1>
        </div>
        <div class="col-sm-10 col-md-8 col-lg-8 mx-auto bg-light mb-5">
            <div class="row">
                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/meat.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Meat and Poultry</h2>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/sea_food.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Seafood</h2>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/dairy.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Dairy</h2>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/produce.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Produce</h2>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/beverages.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Beverages</h2>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/food_service.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Food Service</h2>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/bakery.jpeg" style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Bakery</h2>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/pantry_staple_food.jpeg"
                                 style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Pantry Staple Food</h2>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 text-center mb-4 pl-sm-4 pr-sm-4 ">
                    <div class="row">
                        <div class="col-sm-12 pl-0 pr-0">


                            <img src="../../assets/image/product_categories/specialty_ethnic_food.jpeg"
                                 style="width: 100%;"
                                 alt="">
                            <div class="bg-dark"
                                 style="position: absolute;top:0;left: 0;width: 100%;height: 100%;opacity: .6"></div>
                            <h2 style="position: absolute;width: 100%;top:40%"
                                class="text-light text-center font-weight-bold">Specialty & Ethnic Food</h2>
                        </div>
                    </div>
                </div>


                <!--<div class="col-sm-4 col-md-3 text-center mb-4">-->
                <!--<p style="margin-top: 40%; position: absolute;width: 100%;font-size:1.6em;"-->
                <!--class="text-light text-center font-weight-bold">Foodservice supplies <br>(Disposables, Chemicals,<br>-->
                <!--Supplies and Equipment)</p>-->
                <!--<img src="../../assets/image/secure_payments.jpg" class="opacity" style="width: 100%;" alt="">-->
                <!--</div>-->
                <!--<div class="col-sm-4 col-md-3 text-center mb-4">-->
                <!--<h2 style="margin-top: 50%; position: absolute;width: 100%;"-->
                <!--class="text-light text-center font-weight-bold">Beverages</h2>-->
                <!--<img src="../../assets/image/secure_payments.jpg" class="opacity" style="width: 100%;" alt="">-->
                <!--</div>-->
                <!--<div class="col-sm-4 col-md-3 text-center mb-4">-->
                <!--<h2 style="margin-top: 50%; position: absolute;width: 100%;"-->
                <!--class="text-light text-center font-weight-bold">Specialty and Ethnic</h2>-->
                <!--<img src="../../assets/image/secure_payments.jpg" class="opacity" style="width: 100%;" alt="">-->
                <!--</div>-->

                <!--<div class="col-sm-4 col-md-3 text-center mb-4">-->
                <!--<h2 style="margin-top: 50%; position: absolute;width: 100%;"-->
                <!--class="text-light text-center font-weight-bold">Pantry Staples</h2>-->
                <!--<img src="../../assets/image/secure_payments.jpg" class="opacity" style="width: 100%;" alt="">-->
                <!--</div>-->


            </div>
        </div>

    </div>
</template>
<script>
    export default{}
</script>