<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container">
            <div class="row ">
                <div class="col-sm-12 bg-light shadow p-3 text-left ">
                    <p class="heading">Accounts</p>
                    <hr>

                    <div class="row">
                        <DashboardIcon route="salesman" src="assets/icons/salesman.png"
                                       name="Salesman"></DashboardIcon>
                        <DashboardIcon route="deliveryperson" src="assets/icons/delivery_person.png"
                                       name="Driver"></DashboardIcon>
                        <DashboardIcon route="customer" src="assets/icons/customer.png"
                                       name="Customer">
                        </DashboardIcon>
                        <DashboardIcon route="warehousePerson" src="assets/icons/warehouse_person1.png"
                                       name="Warehouse Staff"></DashboardIcon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import DashboardIcon from '@/views/components/DashboardIcon.vue';
    export default{
        components: {DashboardIcon},
        data(){
            return {}
        },

        methods: {}, mounted(){


        }
    }
</script>