<template>

    <div class="row" v-if="order!=null">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h1 class="text-left">US HALAL FOOD</h1>

                </div>
                <div class="col-6">
                    <h3 class="text-right">INVOICE DETAIL FOR : #{{order.id}}</h3>
                    <p class="text-right">Ordered On : {{order.created_at}}</p>
                </div>
                <div class="col-12">
                    <hr class="mt-0 mb-5"/>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="heading">Sold To</p>
                    <p></p>
                </div>
                <div class="col-6">
                    <p class="heading">Payment Details</p>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <p class="heading">Items ordered</p>
            <hr/>
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th class="border-top-0">#</th>
                        <th class="border-top-0">Item Name</th>
                        <th class="border-top-0">Price</th>
                        <th class="border-top-0">Quantity</th>
                        <th class="border-top-0">Net Price</th>
                    </tr>
                    <tbody>
                    <tr v-for="(item,index) in order.items" v-bind:key="index">
                        <th>{{index + 1}}</th>
                        <td>{{item.item_name}}</td>
                        <td>$ {{item.price}}</td>
                        <td>{{item.quantity}}</td>
                        <td>$ {{(item.price * item.quantity).toFixed(2)}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="3" class="text-right">Total</th>
                        <th>{{order.quantity}}</th>
                        <th>$ {{order.price}}</th>
                    </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    </div>


</template>
<script>

    export default{
        props: ['order'],
        data(){
            return {}
        }, methods: {},
        mounted(){
        }
    }
</script>