<template>
    <div class="row pl-3 pr-3" v-if="show">

        <div class="col-12 border rounded p-3 shadow-sm mb-2">
            <a href="#" @click.prevent="clicked" class="no-link">
                <div class="row">
                    <div class="col-1 p-2">
                        <div class=" rounded-circle bg-success text-light name text-center ">
                            {{to}}
                        </div>
                    </div>
                    <div class="col-11 text-left pl-sm-4 pl-md-4 pl-lg-3 ">
                        <p class="mb-0 font-weight-bold text-dark">{{sender}}</p>
                        <p class="mb-0 text-dark">{{hasLastMessage ? lastMessage.content.substring(0, 50) : ''}}
                            <span v-if="unread>0"
                                  class="badge badge-pill badge-success float-right">{{unread}}</span>
                        </p>
                        <p class="text-right text-secondary font-italic text-xs mb-0">
                            {{hasLastMessage ? lastMessage.created_at : message.created_at}}</p>
                    </div>
                </div>
            </a>


        </div>
    </div>
</template>
<script>

    import {Bus} from '@/main';
    import msg from '@/mixins/Messages.js';
    export default{
        props: ['message'],
        mixins: [msg],
        methods: {
            clicked(){

                Bus.$emit('message-selected', this.message.id);
//                this.mark();
            },

        },

        computed: {}
    }
</script>
