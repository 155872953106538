<template>
    <div class="row pl-4 pr-4 pb-2">

        <div class="col-10 col-sm-8 col-md-6 border shadow rounded-lg  pb-1 pt-3  "
             :class="{'ml-auto bg-success text-light':isSender}">
            <p class="mb-1">{{message.content}}</p>
            <p class="text-right  text-xs mb-0 ">{{message.created_at}}</p>
        </div>
    </div>
</template>
<script>
    import Msg from '@/mixins/Messages.js';
    export default{
        props: ['message'],
        mixins: [Msg],

    }
</script>