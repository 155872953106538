<template>
    <div>
        <div class="container-fluid content-container pl-5 pr-5">
            <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleInventory"
                   v-on:error="setErrors($event.response)"
                   v-on:request="trigger=false;"></axios>

            <div class="row">
                <div class="col-12 bg-light shadow p-3  text-left ">
                    <p class="heading">Inventory</p>
                    <hr>


                    <im type="inventories"></im>

                    <div class="row mb-2">

                        <div class="col-4">
                            <select name="" v-model="category_filter" class="form-control" id="">
                                <option value="0">All Categories</option>
                                <option v-for="category in $store.getters.categories" :key="category.id"
                                        :value="category.id">
                                    {{category.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <input v-model="name_filter" class="form-control" placeholder="Search by name">

                        </div>
                        <div class="col-4">
                            <input type="button" @click="name_filter='';category_filter=0;"
                                   class="btn btn-danger btn-lg" value="Clear Filter">
                        </div>


                    </div>
                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered  " :class="{'opaque':loading}">
                            <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <!--<th scope="col">Product #</th>-->
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Amount</th>
                                <!--<th scope="col">Cost</th>-->
                                <!--<th scope="col">Last Updated</th>-->
                                <th scope="col">Is Popular</th>
                                <th scope="col" class="w-150">Discount</th>
                                <th>Detail</th>
                                <!--<th>Waste</th>-->

                            </tr>
                            </thead>
                            <tbody>

                            <tr v-for="(item,i) in filteredInventory" v-bind:key="i">
                                <td>{{i + 1}}</td>
                                <!--<td>{{item.product_no}}</td>-->
                                <td>{{item.name}}</td>
                                <td>{{item.description}}</td>
                                <td>{{item.inventory_relation == null ? '0' : item.inventory_relation.quantity}}</td>
                                <td>
                                    {{(item.price * (item.inventory_relation == null ? 0 : item.inventory_relation.quantity)) | currency}}
                                </td>
                                <td>{{item.is_famous ? 'Yes' : 'No'}}</td>
                                <td>
                                    {{item.inventory_relation == null ? 0 : item.inventory_relation.discount == null ? 0 : item.inventory_relation.discount.discount | currency}}
                                </td>
                                <td>
                                    <router-link class="btn btn-sm btn-outline-info"
                                                 :to="{name:'inventory.detail',params:{'item_id':item.id}}">Detail
                                    </router-link>
                                </td>
                                <!--<td></td>-->
                            </tr>


                            </tbody>
                        </table>
                    </div>


                    <paging :total="total" @page_changed="fetchData"></paging>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import im from '@/views/layouts/InventoryMenu';
    import forms from '@/forms'
    import Paging from '../components/Paging.vue';
    import {Paths} from '../../paths'
    import axios from  '@/views/actions/Axios';

    export default{
        extends: forms,
        components: {
            Paging, im, axios
        },
        data(){
            return {

                request: 'get',
                url: '',
                data: {},
                items: [],
                category_id: 0,
                total: 0,
                category_filter: 0,
                name_filter: '',
                trigger: false,
                current_page: 0,
            }
        },

        methods: {

            handleInventory(data){
                if (this.url == Paths.item.with_inventory) {
                    console.log(this.url);
                    this.items = data.items;
                    this.total = data.total;
                }

                console.log(JSON.stringify(data.items));

            },
            has_discount(discount){
                if (!discount) {
                    return false;
                }
                if (discount.discount == 0) {
                    return false;
                }

                return true;
            },
            discounted(data){
                let index = this._.findIndex(this.inventories, {'id': data.inventory_id});
                this.inventories[index].discount = data;
            },
            loadItemsList(){
                let app = this;
                this.axios.get(Paths.item.drop_down).then((response) => {
                    app.items = response.data;
                });
            },
            currentData(data){
                this.inventory = data;

            },
            wasteSaved(){
                this.fetchData();
                this.showSuccessMessage('Success! Inventory is updated');
            },
            newRecordAdded(){
                this.fetchData();
                this.showSuccessMessage('Success! Product added to inventory!');
            },
            recordUpdated(){
                this.fetchData();
                this.showSuccessMessageWithID('Success! Inventory updated successfully!', this.inventory.id);
            },

            fetchData(page){

                let app = this;
                app.loading = true;
//                app.items = [];

                app.current_page = page;

                this.axios.get(Paths.item.with_inventory, {params: {'start': page}}).then((response) => {

                    app.items = response.data.items;
                    app.total = response.data.total;
                }).finally(() => {
                    app.loading = false;
                })
            }
        },
        computed: {

            filteredInventory(){
//                console.log(this.name_filter);
                if (this.category_filter == 0 && this.name_filter == '') {
                    return this.items;
                }
                let app = this;


                let filtered = this.items;

                if (this.category_filter != 0) {
                    filtered = this._.filter(this.items, function (item) {

                        return item.category_id == app.category_filter;
                    });
                }


                return this._.filter(filtered, function (item) {

                    return item.name.toLowerCase().indexOf(app.name_filter.toLowerCase()) != -1;
                });

//                return filtered;

            }
        },
        mounted()
        {
            this.url = Paths.item.with_inventory;
            this.trigger = true;
        }
    }
</script>

<style type="text/css">
    .opaque{
        opacity: .3;
    }
</style>