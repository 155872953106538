<template>

    <div class="row">
        <div class="col-sm-12 bg-light shadow p-3 text-left ">
            <p class="heading">Customer Dashboard</p>
            <hr>
            <AccountStatus
                    v-if="!isActiveAccount"></AccountStatus>
            <hr v-if="!isActiveAccount"/>
            <div class="row">

                <DashboardIcon route="shop" src="assets/icons/shop.png"
                               name="View Products"></DashboardIcon>
                <DashboardIcon v-if="isActiveAccount" route="cart.preview" src="assets/icons/cart.png"
                               name="Cart"></DashboardIcon>
                <DashboardIcon v-if="isActiveAccount" route="customer.orders" src="assets/icons/order.png"
                               name="Orders"></DashboardIcon>

            </div>

        </div>
    </div>

</template>

<script>
    import AccountStatus from  '../customer/AccountStatus.vue';
    import DashboardIcon from '@/views/components/DashboardIcon.vue';

    export default{
        components: {AccountStatus, DashboardIcon},
        data(){
            return {
                isActiveAccount: true,
            }
        },
        mounted(){
            if (this.$auth.user().customer.registration != null && this.$auth.user().customer.registration.status != 3) {
                this.isActiveAccount = false;

            }
//
//            this.$router.push({'name': 'shop'});
        }
    }
</script>

