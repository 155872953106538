<template>
    <div class="row">
        <div class="col-12">
            <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div class="col-12" v-if="!loading">
            <div class="table-responsive">
                <table>
                    <tr>
                        <td>Name</td>
                        <td>{{registration.first_name + ' ' + registration.last_name}}</td>
                    </tr>
                </table>
            </div>
            <div class="form-group">
                <label for="">Select Salesman</label>
                <select v-model="user_id" id="">
                    <option value="0">Select</option>
                    <option v-for="(sales_man,index) in sales_men" :key="index" :value="sales_man.id">{{sales_man.full_name}}
                    </option>
                </select>
                <span class="text-danger">{{getError('user_id')}}</span>
            </div>

        </div>
    </div>
</template>
<script>
    import Forms from '@/forms';
    import {Paths} from '@/paths';
    export default{
        extends: Forms,
        props: ['registration'],
        data(){
            return {
                sales_men: [],
                user_id: 0,
            }
        },
        methods: {
            loadSalesMan(){
                let app = this;
                app.startRequest();
                this.axios.get(Paths.salesman.index).then((response) => {
                    app.sales_men = response.data;
                }).finally(() => {
                });
            },
            assign(){

            }
        },
        mounted(){
            this.loadSalesMan();
        }

    }
</script>