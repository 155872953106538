<template>
    <form class="w-100" v-on:submit.prevent>
        <div class="row">
            <div class="col-12">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <button class="btn btn-light dropdown-toggle" @click="newQuery"
                                type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            {{ category.name}}
                        </button>
                        <div class="dropdown-menu">
                            <a href="" class="dropdown-item" @click.prevent="defaultCategory()">All</a>
                            <a class="dropdown-item" @click.prevent="category=cat;"
                               v-for="cat in $store.getters.categories" href="#"
                               v-bind:key="cat.id">{{cat.name}}</a>
                        </div>
                    </div>
                    <input type="text" class="form-control" placeholder="Search Products"
                           aria-label="Text input with dropdown button" v-on:keyup="sendQuery" v-model="query">
                    <div class="input-group-append">
                        <button class="btn btn-light " @click="searchProduct"
                                type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            <icon icon="search"></icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade faster">
            <div class="row suggestion" v-if="show_suggestion">
                <div class="col-12">
                    <div class="list-group">
                        <a href="#" class="list-group-item list-group-item-action" v-if="suggestions.length==0">No product found</a>
                        <a href="#" v-for="suggestion in suggestions"
                           @click.prevent="query=suggestion.name;
                           show_suggestion=false;"
                           class="list-group-item list-group-item-action"
                           v-bind:key="suggestion.id">{{suggestion.name}}</a>

                    </div>
                </div>
            </div>
        </transition>
    </form>
</template>
<script>
    import {Paths} from '@/paths';
    import {Bus} from '@/main';
    export default{

        data(){
            return {
                category: {id: 0, name: 'Any'},
                query: '',
                search_timeout: null,
                show_suggestion: false,
                trigger: false,
                suggestion_clicked: false,
                suggestions: [],
            }
        },
        methods: {
            newQuery(){
                this.query = '';
                this.show_suggestion = false;
            },
            defaultCategory(){
                this.category = {id: 0, name: 'All'};
            },
            searchProduct(){
//                this.newQuery();
                this.show_suggestion = false;
                Bus.$emit('search-product', {'category_id': this.category.id, 'product': this.query});
            },
            loadSuggestions(){
                let app = this;
                app.axios.post(Paths.item.suggestion, {
                    query: app.query,
                    category_id: app.category_id
                }).then((response) => {
                    app.suggestions = response.data;
                }).catch(() => {
                    app.suggestions = [];
                });

            },
            sendQuery(){


                this.loadSuggestions();
//                if (this.search_timeout) clearTimeout(this.search_timeout);
//                let app = this;
//                this.search_timeout = setTimeout(function () {
//                    app.loadSuggestions();
//                }, 300);
            },

        },
        watch: {
            suggestions(val){
                this.show_suggestion = val.length;
            },
            suggestion_clicked(val){
                if (!val) {
                    return;
                }
                this.show_suggestion = false;
            },
            query()
            {
//                this.sendQuery();
            }
        },
        mounted(){
            this.suggestion_path = Paths.item.suggestion;
            this.defaultCategory();
        }
    }
</script>
<style>
    .suggestion {
        position: absolute;
        z-index: 1000;
        width: 100%;
    }
</style>