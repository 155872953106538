<template>

    <tr>
        <td class="text-danger">New Category</td>
        <td>
            <input type="text" @change="store" class="form-control" v-model="input.name"/>
            <span class="text-danger">{{getError('name')}}</span>
        </td>
        <td>
            <input type="text" class="form-control" v-model="input.code">
            <span class="text-danger">{{getError('code')}}</span>
        </td>
        <td>
            <input type="checkbox" v-model="input.is_active">
        </td>
        <td>
            <!--<button v-if="!loading" @click="store" type="button" class="btn btn-outline-info shadow mr-2">Save </button>-->
        </td>
    </tr>
</template>
<script>
    import forms from '@/forms';
    import {Paths} from '@/paths';
    export default{
        extends: forms,
        props: ['is_submit'],
        data(){
            return {
                input: {
                    name: '',
                    code: '',
                    is_active: true,

                }, success: false,
                new_cat: null,
                path: '',
            }
        },
        watch: {
//            'input.name': function (new_val) {
//                if (new_val.length == 0) {
//                    return '';
//                }
//
//                this.input.code = new_val.toUpperCase().replace(' ', '_');
//            }
        },
        methods: {
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;
                this.clearErrors();
                this.loading = false;
                this.input = {
                    name: '',
                    code: '',

                };
                this.$emit('added', this.new_cat);

            },
            store(){
                let app = this;
                this.loading = true;
                this.axios.post(app.path, app.input).then((response) => {
                    app.success = true;
                    app.new_cat = response.data;

                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });
            },

        },
        mounted(){
            this.path = Paths.category.add;
        }
    }
</script>