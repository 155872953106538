<template>
    <div class="modal fade" id="add_inventory" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Add products to inventory</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body bg-light text-left">
                    <add  v-on:added="addSuccess"></add>
                </div>

            </div>
        </div>
    </div>

</template>
<script>

    import Add from '@/views/inventory/form/Add';
    export default{
        props: ['path', 'items'],
        components: {Add},
        methods: {
            addSuccess(){
                this.$emit('added');
            },
        },
        mounted(){

        }
    }
</script>