<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container">

            <!--<add :path="url.add" v-on:added="newRecordAdded"></add>-->
            <!--<edit :path="url.update" :salesman="salesman" v-on:updated="recordUpdated"></edit>-->
            <div class="modal fade" id="registration_detail" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg " role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Customer Detail</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-left">

                            <detail :registration="registration" v-on:assigned="assigned" v-on:approved="approved"
                                    v-on:rejected="rejected"></detail>
                            <div class="row">

                                <div class="col-12 ">
                                    <button type="button" class="btn float-right btn-outline-info btn-sm btn-wide"
                                            data-dismiss="modal">Close
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<detail :registration="registration" v-on:assigned="assigned" v-on:approved="approved"-->
                    <!--v-on:rejected="rejected"></detail>-->
            <div class="row">
                <div class="col-sm-12 bg-light shadow  p-3 text-left">
                    <p class="heading">Registration requests from customers</p>
                    <hr>
                    <div class="text-center">
                        <!--<pulse-loader :loading="loading"></pulse-loader>-->
                    </div>


                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover ">
                            <thead class="">
                            <tr>
                                <th scope="col" class="border-top-0">#</th>
                                <th scope="col" class="border-top-0">Name</th>
                                <th scope="col" class="border-top-0">Username</th>
                                <th scope="col" class="border-top-0">Email</th>
                                <th scope="col" class="border-top-0">Phone</th>
                                <th scope="col" class="border-top-0">Address</th>
                                <th scope="col" class="border-top-0">Status</th>
                                <th scope="col" class="border-top-0">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="registrations.length==0">
                                <td colspan="7">No record found</td>
                            </tr>
                            <tr
                                    v-for="(registration,index) in registrations" :key="index">
                                <th scope="row">{{index + 1}}</th>
                                <td>{{registration.customer.full_name}}</td>
                                <td>{{registration.customer.user_name}}</td>
                                <td>{{registration.customer.email}}</td>
                                <td>{{registration.customer.customer.phone}}</td>
                                <td>{{registration.customer.customer.address}}</td>
                                <td v-if="registration.status==1 || registration.status==2 ">Under Review</td>
                                <td v-else-if="registration.status==3 ">Approved</td>
                                <td v-else-if="registration.status==4 ">Rejected <i
                                        class="text-danger">"{{registration.reason}}"</i></td>

                                <td v-else-if="registration.status==5 && registration.salesman!=null">
                                    Assigned to you
                                </td>
                                <td>
                                    <button @click="currentData(index)"
                                            class="btn btn-outline-info btn-sm border-0"
                                            data-toggle="modal"
                                            data-target="#registration_detail">
                                        Detail
                                    </button>
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import Detail from './Detail.vue';

    import Forms from '@/forms'
    import {Paths} from '@/paths'

    export default{
        extends: Forms,
        components: {Detail},
        data(){
            return {
                registrations: [],
                input: {
                    registration_id: 0,
                    reason: '',
                },
                url: {
                    update: '',
                },
                registration: null,
                is_rejected: false,

            }
        },
        methods: {
            findIndexInRegistrations(){
                if (this.registration == null) {
                    return -1;
                }
                for (let i = 0; i < this.registrations.length; i++) {
                    if (this.registrations[i].id == this.registration.id) {
                        return i;
                    }
                }
                return -1;
            },
            currentData(index){
                this.current_index = index;

                this.registration = this.registrations[index];

            },
            assigned(data){

                this.registration.status = 5;
                this.registration.salesman = data;
            },

            approved(){
                this.registration.status = 3;
            },

            rejected(data){
                this.registration.status = 4;
                this.registration.reason = data;

            },

            fetchData(){
                let app = this;
                app.loading = true;
                this.axios.get(Paths.customer.requests).then((response) => {
                    app.registrations = response.data;
                }).finally(() => {
                    app.loading = false;
                })
            },
            reject(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.reject, this.input).then(() => {
                    app.registrations[app.current_index].status = 4;
                    app.registrations[app.current_index].reason = app.input.reason;
                }).catch((error) => {
                    app.setErrors(error.response)
                }).finally(() => {
                    app.endRequest()
                });
            },
            approve(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.approve, app.input).then(() => {
                    app.registrations[app.current_index].status = 3;

                }).catch(() => {
                }).finally(() => {
                    app.endRequest()
                });
            }
        },
        mounted()
        {
            this.fetchData();
        }
    }
</script>