<template>
    <div>
        <div class="container-fluid content-container pl-5 pr-5">

            <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleItems"
                   v-on:error="setErrors($event.response)"
                   v-on:request="trigger=false;"></axios>

            <div class="row">
                <div class="col-12 bg-light shadow p-3  text-left ">
                    <p class="heading">Food Items</p>
                    <hr>
                    <div class="text-center">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>

                    <im type="items"></im>

                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-sm table-hover table-bordered " :class="{'opaque':loading}">
                            <thead class="">
                            <tr>
                                <th scope="col" rowspan="2">#</th>
                                <th scope="col" rowspan="2">Name</th>
                                <th scope="col" rowspan="2">Product #</th>
                                <th scope="col" rowspan="2">Image</th>
                                <th scope="col" class="text-center" colspan="3">Sales Unit</th>
                                <th scope="col" rowspan="2">Items in base unit</th>
                                <th scope="col" rowspan="2">Price/item in base unit</th>
                                <th scope="col" colspan="2" class="text-center">Weight</th>
                                <th scope="col" rowspan="2">Category</th>
                                <th scope="col" rowspan="2">Brand</th>


                                <th scope="col" rowspan="2">Description</th>

                            </tr>
                            <tr>

                                <th scope="col">Unit</th>

                                <th scope="col">Cost</th>
                                <th scope="col">Price</th>
                                <th scope="col">Base</th>
                                <th scope="col">In units</th>


                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="items.length==0">
                                <td colspan="3">No record found</td>
                            </tr>
                            <tr v-for="(item,i) in items" :key="i">
                                <th scope="row">{{parseInt(i) + 1}}</th>
                                <th scope="row">
                                    <input type="text" @change="save(i)" class="form-control"
                                           v-model="item.name">
                                    <span class="text-danger" v-if="i==index">{{getError('name')}}</span>
                                </th>
                                <th scope="row">
                                    <input type="text" @change="save(i)" class="form-control"
                                           v-model="item.product_no">
                                    <span class="text-danger" v-if="i==index">{{getError('product_no')}}</span>
                                </th>
                                <td>
                                    <img @click="$refs.file[i].click()" class="img-thumbnail"
                                         style="max-width:36px;"
                                         @error="items[i].image=null"
                                         :src="$store.getters.rootPath+'item?image='+item.image" alt="">
                                    <!--<img @click="printRefs(iteration)" class="img-thumbnail" style="max-width:36px;"-->
                                    <!--:src="$store.getters.rootPath+'item?image='+item.image" alt="">-->

                                    <input @change="setFile($event,i);" style="display: none;" type="file"
                                           ref="file">
                                    <span class="text-danger" v-if="i==index">{{getError('image')}}</span>
                                </td>
                                <td>
                                    <select v-model="item.unit_id" @change="save(i)" class="form-control">
                                        <option value="0">Not selected</option>
                                        <option v-for="unit in $store.getters.units" :value="unit.id"
                                                v-bind:key="unit.id">
                                            {{unit.name}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input type="text" @change="save(i)" class="form-control" v-model="item.cost"/>
                                    <span class="text-danger" v-if="i==index">{{getError('cost')}}</span>
                                </td>
                                <td>
                                    <input type="text" @change="save(i)" class="form-control" v-model="item.price"/>
                                    <span class="text-danger" v-if="i==index">{{getError('price')}}</span>
                                </td>
                                <th scope="row">
                                    <input type="text" @change="save(i)" class="form-control"
                                           v-model="item.items_in_base_unit">
                                    <span class="text-danger" v-if="i==index">{{getError('items_in_base_unit')}}</span>
                                </th>
                                <th scope="row">
                                    <input type="text" @change="save(i)" class="form-control"
                                           v-model="item.price_per_item_in_base_unit">
                                    <span class="text-danger" v-if="i==index">{{getError('price_per_item_in_base_unit')}}</span>
                                </th>
                                <th scope="row">
                                    <input type="text" @change="save(i)" class="form-control"
                                           v-model="item.weight_base">
                                    <span class="text-danger" v-if="i==index">{{getError('weight_base')}}</span>
                                </th>
                                <th scope="row">
                                    <input type="text" @change="save(i)" class="form-control"
                                           v-model="item.weight_base_unit">
                                    <span class="text-danger" v-if="i==index">{{getError('weight_base_unit')}}</span>
                                </th>

                                <td>
                                    <select v-model="item.category_id" @change="save(i)" class="form-control">
                                        <option value="0">Not selected</option>
                                        <option v-for="(category) in $store.getters.categories" v-bind:key="category.id"
                                                :value="category.id">{{category.name}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="item.brand_id" @change="save(i)" class="form-control">
                                        <option value="0">Not selected</option>
                                        <option v-for="brand in $store.getters.brands" :value="brand.id"
                                                v-bind:key="brand.id">
                                            {{brand.name}}
                                        </option>
                                    </select>
                                </td>

                                <td>
                                    <textarea v-model="item.description" @change="save(i)" class="form-control"
                                              cols="30"
                                              rows="1"></textarea>

                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>

                    <paging :total="total" @page_changed="fetchData"></paging>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    //    import Add from './Add.vue';
    //    import Edit from './Edit.vue';
    import forms from '../../forms';
    import Paging from '../components/Paging.vue';
    import {Paths} from '../../paths';
    import axios from '@/views/actions/Axios';
    import im from '@/views/layouts/InventoryMenu';

    export default{
        extends: forms,
        components: {Paging, axios, im},
        data(){
            return {
                items: [],
                index: -1,
                request: 'get',
                url: '',
                trigger: false,
                data: {},
                total: 0,
                page: 0,
                file: null,
            }
        },
        watch: {},
        methods: {
            printRefs(i){
                console.log('i=' + i);
                console.log(this.$refs.file);
            },

            pushEmptyModel(){

                if (this.items.length > 0 && this.items[this.items.length - 1].id == 0) {
                    return;
                }

                this.items.push(this.emptyModel());
            },
            emptyModel(){
                return {
                    id: 0,
                    product_no: null,
                    name: '',
                    image: null,
                    category_id: 0,
                    unit_id: 0,
                    price: 0,
                    cost: 0,
                    sub_category_id: 0,
                    ulf: null,
                    description: '',
                    brand_id: 0,
                    items_in_base_unit: 0,
                    price_per_item_in_base_unit: 0,
                    weight_base: 0,
                    weight_base_unit: '',


                };
            },

            resetRequest(){
                this.request = 'get';
                this.url = Paths.item.all;

            },

            isLastRowEmpty(){
                return this.items[this.items.length - 1].id == 0;
            },

            handleItems(data){
                if (this.url == Paths.item.all) {
                    this.items = data.items;
                    this.total = data.total;
                    this.pushEmptyModel();
                    return;
                }
                this.items[this.index].id = data.data.id;
                this.items[this.index].image = data.data.image;
                if (!this.isLastRowEmpty()) {
                    this.pushEmptyModel();
                }
                this.resetRequest();
            },
            currentData(data){
                this.item = data;

            },

            save(index){
                this.index = index;
                this.data = this.getFormData();
                this.url = Paths.item.store;
                this.request = 'post';
                this.trigger = true;

            },

            fetchData(page){
                let app = this;
                app.loading = true;

//                app.items = [];
                this.axios.get(Paths.item.all, {params: {'start': page}}).then((response) => {
                    app.items = response.data.items;
                    app.total = response.data.total;
                }).finally(() => {
                    app.loading = false;
                })
            },
            getFormData: function () {
                let data = new FormData();

                data.append('id', this.items[this.index].id);
                data.append('category_id', this.items[this.index].category_id);
                data.append('unit_id', this.items[this.index].unit_id);
                data.append('brand_id', this.items[this.index].brand_id);
                data.append('name', this.items[this.index].name);
                data.append('price', this.items[this.index].price);
                data.append('items_in_base_unit', this.items[this.index].items_in_base_unit);
                data.append('price_per_item_in_base_unit', this.items[this.index].price_per_item_in_base_unit);
                data.append('weight_base', this.items[this.index].weight_base);
                data.append('weight_base_unit', this.items[this.index].weight_base_unit);
                data.append('cost', this.items[this.index].cost);
                data.append('product_no', this.items[this.index].product_no);
                data.append('sub_category_id', this.items[this.index].sub_category_id);
                data.append('description', this.items[this.index].description);
                if (this.file == null) {
                    return data;
                }
                data.append('image', this.file);
                return data;
            },
            setFile: function (event, index) {
                if (this.items[index].name.length == 0) {
                    alert('Enter product name');
                    return;
                }
                this.index = index;
                this.file = event.target.files[0];
                this.items[this.index].image = this.file.name;
                let app = this;
                setTimeout(function () {
                    app.save(index);
                }, 500);

            }
        },
        mounted()
        {
//            this.data
            this.url = Paths.item.all;
            this.trigger = true;
        }
    }
</script>


<style type="text/css">
    .opaque {
        opacity: .3;
    }
</style>