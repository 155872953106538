<template>
    <div class="container-fluid pl-5 pr-5 content-container">

        <edit :path="url.edit" :user="user"
              v-on:updated="updated"></edit>
        <password :path="url.password" v-on:updated="showSuccessMessage('Password updated successfully')"></password>
        <div class="row">
            <div class="col-sm-12 bg-light shadow p-3 text-left ">
                <p class="heading ">Profile Details</p>
                <hr>
                <div class="row">

                    <div class="col-sm-6 table-responsive">
                        <div class="alert alert-success" v-if="success">{{message}}</div>
                        <user-info :user="user"></user-info>
                        <input type="button" data-toggle="modal" data-target="#update_admin"
                               class="btn btn-outline-info  btn-sm  mb-3 shadow mr-2"
                               value="Change Details"/>
                        <input type="button" data-toggle="modal"
                               data-target="#change_password"
                               class="btn btn-outline-info  btn-sm  mb-3 shadow"
                               value="Change Password"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <p class="heading pt-3">Shipping Address</p>
                        <hr>
                        <shipping-address></shipping-address>
                    </div>
                    <div class="col-sm-6">
                        <p class="heading pt-3">Billing Address</p>
                        <hr>
                        <billing-address></billing-address>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>

    import UserInfo from '@/views/account/UserInfo';
    import Edit from '@/views/account/ChangeInfo.vue';
    import Password from '@/views/account/ChangePassword.vue';
    import BillingAddress from '@/views/shop/BillingInformation';
    import ShippingAddress from '@/views/shop/DeliveryInformation';
    import Loader from  '@/loader';
    import {Paths} from '@/paths';
    export default{
        extends: Loader,
        components: {UserInfo, Edit, Password, BillingAddress, ShippingAddress},
        data(){
            return {
                url: {
                    edit: '',
                    password: '',
                },
                user: null,
            }
        }, methods: {
            updated(){
                let app = this;
                this.showSuccessMessage('Profile updated successfully');
                this.$auth.fetch().then(() => {
                    app.loadUser();
                });


            },
            loadUser(){
                this.user = {};
                this.setDetails();

            },
            loadPaths(){
                this.url.password = Paths.api.change_password;
                if (this.isCustomer()) {
                    this.url.edit = Paths.customer.update_profile;

                    return;
                }
                if (this.isAdmin()) {
                    this.url.edit = Paths.admin.update;
                    return;
                }

                if (this.isSalesMan()) {
                    this.url.edit = Paths.salesman.update_profile;
                    return;
                }

                if (this.isDeliveryMan()) {
                    this.url.edit = Paths.deliveryperson.update_profile;
                    return;
                }

            },
            setDetails(){

                this.user.first_name = this.$auth.user().first_name;
                this.user.last_name = this.$auth.user().last_name;
                this.user.user_name = this.$auth.user().user_name;
                this.user.email = this.$auth.user().email;
                this.user.role = this.$auth.user().role;


                let detail = this.getDetailsForRole();
                if (detail == null) {
                    return;
                }
                this.user.address = detail.address;
                this.user.phone = detail.phone;
                if (detail.company != undefined) {
                    this.user.company = detail.company;
                }
            },
            getDetailsForRole(){
                if (this.isCustomer()) {
                    return this.$auth.user().customer;
                }
                if (this.isAdmin()) {
                    return null;
                }
                if (this.isSalesMan()) {
                    return this.$auth.user().salesman;
                }

                if (this.isDeliveryMan()) {
                    return this.$auth.user().deliveryman;
                }


            },
            isSalesMan(){
                return this.user.role == 'sales';
            },
            isAdmin(){
                return this.user.role == 'admin';
            },
            isDeliveryMan(){
                return this.user.role == 'delivery';
            },
            isCustomer(){
                return this.user.role == 'customer';
            },
        },
        mounted(){

            this.loadUser();
            this.loadPaths();


        }
    }
</script>