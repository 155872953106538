<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row">
            <div class="col-sm-4 col-md-3 col-xl-2">
                <div class="row pr-sm-2">
                    <side-panel v-on:sub_category_clicked="subCategoryClicked"
                                @category_clicked="categoryClicked"
                                v-on:has_sub_categories="subCategoriesUpdated" :category_id="category"></side-panel>
                </div>
            </div>
            <div :class="{'col-sm-8 col-md-9 col-xl-10':has_sub_categories,'col-12':!has_sub_categories}">
                <div class="row pl-sm-2">
                    <div class="col-12 bg-light shadow  p-3">
                        <p class="heading">OUR PRODUCTS</p>
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <pulse-loader :loading="loading" size="30px"></pulse-loader>
                            </div>

                        </div>
                        <div class="row" v-if="parsedItems.length==0 && !loading">

                            <div class="col-12">
                                <div class="alert alert-danger">
                                    Sorry! No record found
                                </div>
                            </div>
                        </div>
                        <transition-group name="fade" :duration="{ enter: 300, leave: 0 }"
                                          tag="div"
                                          class="card-columns">


                            <div class="card  border mb-4 shadow" v-for="(item) in pagedItems"
                                 v-bind:key="item.id">

                                <div class="ribbon-wrapper" v-if="item.discount">
                                    <div class="ribbon red">Sale</div>
                                </div>
                                <div class="ribbon-wrapper" v-else-if="item.is_famous">
                                    <div class="ribbon green">Popular</div>
                                </div>
                                <router-link
                                        :to="{name:'shop.detail',params:{category:item.item.category_id,name:item.item.name}}"
                                        class="no-link" title="Show Detail">

                                    <img class="card-img-top "
                                         :src="$store.getters.rootPath+'item?image='+item.item.image"
                                         alt="Card image cap">

                                    <div class="card-body border-top">
                                        <h4 class="card-title custom-color   bold">
                                            {{item.item.name}}
                                        </h4>


                                        <div class="card-text custom-color  bold">

                                            <span v-if="item.item.product_no!=null">Product # : {{item.item.product_no}}</span>
                                            <br>
                                            <span>Package Size : {{item.item.unit_relation.name}}</span> <br>
                                            <span v-if="$auth.check()">
                                            <span v-if="!item.discount">
                                        <span>Price : ${{item.item.price}}</span>

                                    </span>
                                            <span v-else>
                                        <del class="text-danger mr-3"><span> Price: ${{item.item.price}}</span></del>
                                        <span>
                                            <span>${{(item.item.price - (item.item.price * item.discount.discount / 100)).toFixed(2)}}</span>
                                        </span>

                                    </span>
                                                </span>

                                            <favourite v-on:done="favDone" v-on:notFavourite="favCancel"
                                                       :is_favourite="item.item.favourites.length>0"
                                                       :item_id="item.item.id"></favourite>
                                        </div>
                                    </div>
                                </router-link>

                            </div>
                        </transition-group>

                        <div class="row" v-if="limit<parsedItems.length">
                            <div class="col-sm-6 col-md-5 col-lg-4 mx-auto">
                                <div class="text-center">
                                    <input type="button" class="btn btn-block btn-outline-info" @click="limit+=50"
                                           value="Load More">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--</div>-->
            <!--</div>-->

        </div>
    </div>
</template>
<script>
    import Loader from '../../loader'
    import {Paths} from '../../paths'
    import Favourite from  './Favourite.vue';
    import SidePanel from '@/views/shop/SidePanel.vue';

    export default{
        extends: Loader,
        components: {Favourite, SidePanel},
        props: ['query', 'category'],
        data(){
            return {
                items: [],
                has_sub_categories: true,
                category_id: 0,
                sub_category_id: 0,
                search: '',
                index: -1,
                limit: 50,
                total_items: 0,

            };
        },
        watch: {
            category(){

                this.setParams();
//                console.log(this.category_id);
            },
            query(){
                this.setParams();
            }
        },
        computed: {

            parsedItems: function () {

                let search = this.search.trim().toLowerCase();
                let filtered = this.items;

                if (search.length > 0) {
                    filtered = this._.filter(this.items, function (o) {
                        return o.item.name.toLowerCase().indexOf(search) > -1;
                    })
                }

//                if (this.category_id == -1) {
//                    filtered = this._.filter(filtered, function (o) {
//                        return o.item.favourites.length > 0;
//                    });
//                }
//
                if (this.sub_category_id != 0) {
                    filtered = this._.filter(filtered, {item: {'sub_category_id': parseInt(this.sub_category_id)}});
                }

                if (this.category_id != 0) {
                    filtered = this._.filter(filtered, {item: {'category_id': parseInt(this.category_id)}});
                }

                return filtered;

//                this.total_items = filtered.length;
//
//
//                return filtered.slice(0, this.limit);
            },
            pagedItems: function () {
                return this.parsedItems.slice(0, this.limit);
            }

        },
        methods: {
            categoryClicked(data){
                this.category_id = data;
            },
            subCategoriesUpdated(data){

                data = true;
                this.has_sub_categories = data;

            },
            subCategoryClicked(data){
                this.sub_category_id = data;
//                console.log(data);
            },
            setParams(){

                this.category_id = this.category == undefined ? 0 : this.category;
                this.search = this.query == undefined ? '' : this.query;
                this.sub_category_id = 0;

            },
            favDone(data){
                let index = this._.findIndex(this.items, {'item': {id: data.item_id}});
                if (index > -1) {
                    this.items[index].item.favourites.push(data);
                }

            },
            favCancel(data){
                let index = this._.findIndex(this.items, {'item': {id: data.item_id}});
                if (index > -1) {
                    this.items[index].item.favourites = [];
                }
            },
            loadShop(){
                let app = this;
                app.loading = true;
                this.axios.get(Paths.shop.available).then((response) => {
                    app.items = response.data
                    console.log(app.items.length);
                }).finally(() => {
                    app.loading = false;
                });

            }
        },
        created(){

        },
        mounted(){
            this.loadShop();
            this.setParams();
        }
    }
</script>

<style>
    .card:hover {
        /*background-color: #e2e3e5 !important;*/
        border: 1px solid green !important;
        cursor: pointer;
    }

    .card-columns {
        display: inline-block;
        column-break-inside: avoid;
    }

    .fade-leave {
        display: none;
    }

    .fade-leave-active {
        display: none;
    }

    @media (min-width: 320px) {
        .card-columns {
            -webkit-column-count: 1 !important;
            -moz-column-count: 1 !important;
            column-count: 1 !important;
        }
    }

    @media (min-width: 450px) {
        .card-columns {
            -webkit-column-count: 2 !important;
            -moz-column-count: 2 !important;
            column-count: 2 !important;
        }
    }

    @media (min-width: 860px) {
        .card-columns {
            -webkit-column-count: 3 !important;
            -moz-column-count: 3 !important;
            column-count: 3 !important;
        }
    }

    @media (min-width: 1200px) {
        .card-columns {
            -webkit-column-count: 4 !important;
            -moz-column-count: 4 !important;
            column-count: 4 !important;
        }
    }

    @media (min-width: 1600px) {
        .card-columns {
            -webkit-column-count: 5 !important;
            -moz-column-count: 5 !important;
            column-count: 5 !important;
        }
    }

    .ribbon-wrapper {
        width: 85px;
        height: 88px;
        overflow: hidden;
        position: absolute;
        top: -3px;
        right: -3px;
    }

    .ribbon {
        font-size: 12px;
        color: #FFF;
        text-transform: uppercase;
        font-family: 'Montserrat Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        letter-spacing: .05em;
        line-height: 15px;
        text-align: center;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        position: relative;
        padding: 7px 0;
        right: 0px;
        top: 16px;
        width: 113px;
        /*height: 28px;*/
        -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
        box-shadow: 0 0 3px rgba(0, 0, 0, .3);
        background-color: #dedede;
        background-image: -webkit-linear-gradient(top, #ffffff 45%, #dedede 100%);
        background-image: -o-linear-gradient(top, #ffffff 45%, #dedede 100%);
        background-image: linear-gradient(to bottom, #ffffff 45%, #dedede 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffdedede', GradientType=0)
    }

    .ribbon:before,
    .ribbon:after {
        content: "";
        border-top: 3px solid #9e9e9e;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position: absolute;
        bottom: -3px
    }

    .ribbon:before {
        left: 0
    }

    .ribbon:after {
        right: 0
    }

    .ribbon.green {
        background-color: #2da285;
        background-image: -webkit-linear-gradient(top, #2da285 45%, #227a64 100%);
        background-image: -o-linear-gradient(top, #2da285 45%, #227a64 100%);
        background-image: linear-gradient(to bottom, #2da285 45%, #227a64 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff2da285', endColorstr='#ff227a64', GradientType=0)
    }

    .ribbon.green:before,
    .ribbon.green:after {
        border-top: 3px solid #113e33
    }

    .ribbon.red {
        background-color: #bc1a3a;
        background-image: -webkit-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
        background-image: -o-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
        background-image: linear-gradient(to bottom, #a61733 45%, #bc1a3a 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa61733', endColorstr='#ffbc1a3a', GradientType=0)
    }

    .ribbon.red:before,
    .ribbon.red:after {
        border-top: 3px solid #8f142c
    }


</style>