<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row">
            <div class="col-sm-12 bg-light shadow  p-3 text-left">
                <p class="heading">Details of registration request</p>
                <hr>
                <detail v-if="$auth.check('admin')" :registration="detail" v-on:assigned="loadDetail"
                        v-on:approved="loadDetail"
                        v-on:rejected="loadDetail"></detail>
                <sales-detail v-if="$auth.check('sales')" :registration="detail" v-on:assigned="loadDetail"
                              v-on:approved="loadDetail"
                              v-on:rejected="loadDetail"></sales-detail>
                <customer-detail v-if="$auth.check('customer')" :registration="detail"></customer-detail>


            </div>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Detail from '@/views/registrations/Detail';
    import SalesDetail from '@/views/registrations/sales/Detail';
    import CustomerDetail from '@/views/registrations/customer/Detail';
    export default{
        props: ['id'],
        components: {Detail, SalesDetail, CustomerDetail},
        data(){
            return {detail: null}
        },
        methods: {

            loadDetail()
            {
                let app = this;
                this.axios.post(Paths.registrations.get, {'customer_id': this.id}).then((response) => {
                    app.detail = response.data;
                });
            },
            readNotification(){

                this.axios.post(Paths.notifications.read, {'n_key': this.id, 'category': 'register'}).then(() => {

                });

            }
        },
        watch: {
            id(){
                this.loadDetail();
            },
            detail(){
                this.readNotification();
            }
        },
        mounted(){
            this.loadDetail();
        }
    }
</script>