<template>
    <div class="col-6 col-sm-3  mb-2">

        <div class="row">
            <div class="col-12 text-center pt-3 pb-3">
                <router-link :to="{name:route}">
                    <img class="img-fluid " style="width:20%;min-width: 64px;" :src="require('@/'+src)" alt="">
                    <p class="custom-color ">{{name}}</p>
                </router-link>
            </div>
        </div>

    </div>
</template>
<script>
    export default{
        props: {'route': String, 'src': String, 'name': String},
    }
</script>