<template>
    <div class="row pl-3 pr-3 " v-if="message!=undefined">
        <div class="col-12 pl-0"><p class="font-weight-bold">Conversation with {{sender}}</p></div>
        <div class="col-12 bg-white border overflow-auto pt-3 pb-3" style="height: 300px;max-height: 300px;">
            <div class="overflow-container">
                <div v-for="(content) in message.messages" v-bind:key="content.id">
                    <message-content :message="content"></message-content>
                </div>
            </div>
        </div>
        <div class="col-12">
            <input-area :message_id="message.id" :to="isSender?message.sent_to:message.sent_by"></input-area>
            <!--<input type="button" @click="scroll">-->
        </div>
    </div>
</template>
<script>
    import msg from '@/mixins/Messages.js';
    import MessageContent from './Content.vue';
    import InputArea from './Input.vue';
    export default{
        props: ['message'],
        mixins: [msg],
        components: {MessageContent, InputArea},
        methods: {},
        watch: {
            message(){

            }
        },
        updated(){

            let overflowContainer = this.$el.querySelector('.overflow-auto');
            if (overflowContainer == null) {
                return;
            }
            overflowContainer.scrollTop = overflowContainer.querySelector('.overflow-container').clientHeight;

        },
    }
</script>