<template>
    <span>{{balance|currency}}</span>
</template>

<script>
    import {Paths} from '@/paths';
    export default{
        props:['customer_id'],
        data(){
            return{
                balance:0,
            }
        },
        methods:{

            load_balance(customer_user_id){
                let app=this;
                console.log('Loading balance');
                this.axios.post(Paths.customer.balance,{'user_id':customer_user_id}).then(response=>{

                        app.balance=response.data;
                        console.log(app.balance);
                });
            }
        },
        watch:{
            customer_id:function (val) {
                if(val==0){
                    this.balance=0;
                    return;
                }

                this.load_balance(val);

            },
        }
    }
</script>