<template>
    <div class="container-fluid">

        <subcat></subcat>

    </div>
</template>
<script>
    import subcat from '@/views/subcategory/Index';
    export default{
        components: {subcat}

    }
</script>