<template>
    <div class="form-group">
        <label for="">{{label}}</label>
        <input type="text" class="form-control" v-model="input"/>
        <span class="text-danger">{{error}}</span>
    </div>
</template>
<script>
    export default{
        props: ['label', 'error'],
        data(){
            return {
                input: '',
            }
        },
        watch: {
            input(){
                this.$emit('changed')
            }
        }

    }
</script>