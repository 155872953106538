<template>

    <tr>
        <td class="text-danger">New Unit</td>
        <td>
            <input type="text" class="form-control" @change="store" v-model="input.name">
            <span class="text-danger">{{getError('name')}}</span>
        </td>
        <td>
            <input type="text" class="form-control" v-model="input.code">
            <span class="text-danger">{{getError('code')}}</span>
        </td>
        <td>
            <input type="checkbox" v-model="input.is_active">
        </td>
        <td>
            <!--<input v-if="!loading" @click="store" type="button" class="btn btn-outline-info shadow" value="Save">-->
        </td>
    </tr>


</template>
<script>
    import forms from '@/forms';
    import {Paths} from  '@/paths';
    export default{
        extends: forms,

        data(){
            return {

                input: {
                    name: '',
                    code: '',
                    is_active: 1,

                },

                trigger: false,
                url: '',
            }
        }, methods: {
            addSuccess(data){

                this.clearErrors();
                this.loading = false;
                this.input = {
                    name: '',
                    code: '',
                    is_active: true,

                };
                this.$emit('added', data);

            },
            store(){

                let app = this;
                this.loading = true;
                this.axios.post(app.url, app.input).then((response) => {
                    app.addSuccess(response.data);

                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.loading = false;

                });
            },

        },
        mounted(){
            this.url = Paths.unit.add;
        }
    }
</script>