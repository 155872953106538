<template>
    <ul class="nav navbar-nav navbar-right" v-if="$auth.check()">
        <li class="dropdown">
            <a href="#" class="dropdown-toggle text-light no-link" data-toggle="dropdown" role="button"
               aria-haspopup="true"
               aria-expanded="false">Notification <b class="badge badge-pill badge-danger">{{notifications.length}}</b></a>
            <ul class="dropdown-menu notify-drop">
                <div class="drop-content">
                    <li v-for="notification in notifications" v-bind:key="notification.id">
                        <div class="col-12">
                            <router-link
                                    :to="{name:getName(notification.category),params:{id:notification.n_key,nid:notification.id}}"
                                    href=""
                                    class="no-link text-success">{{notification.message}}
                            </router-link>

                            <hr>
                            <p class="time">{{notification.created_at}}</p>
                        </div>
                    </li>

                </div>

            </ul>
        </li>
    </ul>
</template>
<script>
    import {Paths} from '@/paths';
    import {Bus} from '@/main';
    export default{
        data(){
            return {
                notifications: {},
                interval: null,
            }
        },
        methods: {
            loadNotifications(){
                if (!this.$auth.check()) {
                    return;
                }
//                return;
                let app = this;
                app.axios.post(Paths.notifications.all).then((response) => {
                    app.notifications = response.data;
                })
            },
            getName(val){
                if (val == 'orders') {
                    return 'order.detail';
                }
                if (val == 'register') {
                    return 'customer.request.detail';
                }
                if (val == 'message') {
                    return 'messages';
                }
                return 'none';
            }
        },
        mounted(){

            let app = this;

            Bus.$on('refresh_notifications', function () {
                app.loadNotifications();
            });

        },
        created(){
            if (this.interval != null) {
                clearInterval(this.interval);
                this.interval = null;
            }
            let app = this;
            this.interval = setInterval(function () {
                app.loadNotifications();
            }, 10000)
            app.loadNotifications();
        },
        beforeDestroy(){
            clearInterval(this.interval);
        }
    }
</script>