<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left " style="min-height: 400px!important;">

                <div class="row position-relative">
                    <div class="col-sm-4 col-lg-3 border-right ">
                        <p class="heading">Messages</p>
                        <hr/>
                        <input v-if="!$auth.check('customer')" type="button" class="btn btn-outline-info btn-block mb-3"
                               @click="message=undefined"
                               value="New Message">
                        <p class="text-success" v-if="loading">Loading messages...</p>
                        <list :messages="messages"></list>
                    </div>
                    <div class="col-sm-8 col-lg-9 ">
                        <p class="heading">Message Window</p>
                        <hr>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <new-message v-if="message==undefined"></new-message>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <chat :message="message"></chat>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {Paths} from '@/paths';
    import Loader from '@/loader';
    import List from './List.vue';
    import Chat from './Chat.vue';
    import NewMessage from './NewMessage.vue';
    import {Bus} from '@/main';
    export default{
        extends: Loader,
        components: {List, Chat, NewMessage},
        props: ['id'],
        data(){
            return {
                messages: [],
                message: undefined,
                message_interval: null,
            }
        },
        methods: {
            isValidMessage(){
                if (this.message == undefined) {
                    return false;
                }
                if (this.message == null) {
                    return false;
                }
                return true;
            },
            loadMessages(){
                let app = this;
//                app.startLoading();
                this.axios.post(Paths.message.all).then((response) => {
                    app.messages = response.data;
                }).finally(() => {
//                    app.endLoading();
                });
            },
            loadMessageById(){
                if (this.id == undefined) {
                    return;
                }

                if (this.message == undefined || this.message == null) {
                    this.message = this._.find(this.messages, {'id': parseInt(this.id)});
                    return;
                }


            },
            mark(){

                if (!this.isValidMessage()) {
                    return;
                }

                let app = this;
                this.axios.post(Paths.message.mark, {'id': app.message.id}).then((response) => {
                    app.message.messages = response.data;
                });
            },
            updateContent(){
                if (!this.isValidMessage()) {
                    return;
                }
                this.message = this._.find(this.messages, {'id': this.message.id});
            },
            destroy(){
            },
            clearMessageInterval(){
                if (this.message_interval == null) {
                    return;
                }
                clearInterval(this.message_interval);
            }
        },
        watch: {
            messages(){
                this.loadMessageById();
                this.updateContent();

            },
            message(){
                this.mark();
            },

        },
        created(){
            this.clearMessageInterval();
            let app = this;
            this.message_interval = setInterval(function () {
                app.loadMessages();
            }, 5000);
        },
        beforeDestroy(){
            Bus.$off('message-selected', this.destroy())
            Bus.$off('message-new', this.destroy());
            Bus.$off('message-created', this.destroy());
            this.clearMessageInterval();
        },
        mounted(){
            this.loadMessages();
            let app = this;
            Bus.$on('message-selected', function (data) {
                app.message = app._.find(app.messages, {'id': data});
            });
            Bus.$on('message-new', function (data) {
                app.message.messages.push(data);

            });
            Bus.$on('message-created', function (data) {
                let index = this._.findIndex(app.messages, {'id': data.id});
                if (index == -1) {

                    app.messages.splice(0, 0, data);

                }
                app.message = data;

            });


        }
    }
</script>