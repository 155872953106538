<template>
    <div class="modal fade" id="edit_delivery_person" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Edit Delivery Person</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-left">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">First Name</label>
                                <input type="text" class="form-control" v-model="input.first_name">
                                <span class="text-danger">{{getError('first_name')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Last Name</label>
                                <input type="text" class="form-control" v-model="input.last_name">
                                <span class="text-danger">{{getError('last_name')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">User Name</label>
                                <input type="text" class="form-control" v-model="input.user_name">
                                <span class="text-danger">{{getError('user_name')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="text" class="form-control" v-model="input.password">
                                <span class="text-danger">{{getError('password')}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="email" class="form-control" v-model="input.email">
                                <span class="text-danger">{{getError('email')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Phone</label>
                                <input type="text" class="form-control" v-model="input.phone">
                                <span class="text-danger">{{getError('phone')}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Address</label>
                                <input type="text" class="form-control" v-model="input.address">
                                <span class="text-danger">{{getError('address')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Route</label>
                                <RouteDropDown :route_id="input.route_id" v-on:changed="routeChanged"></RouteDropDown>
                                <span class="text-danger">{{getError('route_id')}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>
                    <div class="row" v-else="">
                        <div class="col-sm-12">
                            <button @click="store" type="button" class="btn btn-outline-info shadow">Save changes
                            </button>
                            &nbsp;
                            <button ref="close_btn" type="button" @click="closeDialog" class="btn btn-danger shadow"
                                    data-dismiss="modal">Close
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';
    import RouteDropDown from  '@/views/components/dropdown/Route.vue';
    //    import {Paths} from '@/paths';
    export default{
        extends: forms,
        components: {RouteDropDown},
        props: ['path', 'delivery_person'],
        data(){
            return {
                input: {
                    id: '',
                    first_name: '',
                    last_name: '',
                    user_name: '',
                    password: '',
                    email: '',
                    phone: '',
                    address: '',
                    route_id: 0,
                }, success: false,
            }
        }, methods: {
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('updated');
                this.$refs.close_btn.click();
            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;

            },
            routeChanged(data){
                this.input.route_id = data;
            },
            store(){
                let app = this;
                this.loading = true;
                this.axios.post(app.path, app.input).then(() => {
                    app.success = true;

                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });
            }
        },
        watch: {
            delivery_person(newVal){
                if (newVal == null) {
                    return;
                }
                this.input.first_name = newVal.first_name;
                this.input.last_name = newVal.last_name;
                this.input.user_name = newVal.user_name;
                this.input.email = newVal.email;
                this.input.phone = newVal.deliveryman.phone;
                this.input.address = newVal.deliveryman.address;
                this.input.id = newVal.id;
                this.input.route_id = newVal.deliveryman.route_id;

            }
        },
        mounted(){

            if (this.delivery_person == null) {
                return;

            }
            this.input.first_name = this.delivery_person.first_name;
            this.input.last_name = this.delivery_person.last_name;
            this.input.user_name = this.delivery_person.user_name;
            this.input.email = this.delivery_person.email;
            this.input.phone = this.delivery_person.deliveryman.phone;
            this.input.address = this.delivery_person.deliveryman.address;
            this.input.id = this.delivery_person.id;
            this.input.route_id = this.delivery_person.deliveryman.route_id;

        }
    }
</script>