<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container">

            <add :path="url.add" v-on:added="newRecordAdded"></add>
            <edit :path="url.update" :staff="staff" v-on:updated="recordUpdated"></edit>
            <detail :staff="staff"></detail>
            <div class="row">
                <div class="col-sm-12 bg-light shadow  p-3 text-left">
                    <p class="heading">Warehouse Staff</p>
                    <hr>
                    <div class="text-center">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>


                    <div class="row">
                        <div class="col-12 mb-3">
                            <input type="button" data-toggle="modal" data-target="#add_sales_person"
                                   class="btn btn-outline-info shadow float-right " value="Create"/>
                        </div>
                    </div>
                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover ">
                            <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Username</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Address</th>
                                <th>Detail</th>
                                <th>Edit</th>
                                <th>Enable/Disable</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="staffs.length==0">
                                <td colspan="9">No record found</td>
                            </tr>
                            <tr :class="{'table-success':changed_id==man.id,'table-danger':!man.is_active}"
                                v-for="(man,index) in staffs" :key="index">
                                <th scope="row">{{index + 1}}</th>
                                <td>{{man.full_name}}</td>
                                <td>{{man.user_name}}</td>
                                <td>{{man.email}}</td>
                                <td>{{man.ware_house_person.phone}}</td>
                                <td>{{man.ware_house_person.address}}</td>
                                <td>

                                    <button @click="currentData(man)" class="bg-transparent text-grey border-0 "
                                            data-toggle="modal"
                                            data-target="#sales_person_detail">
                                        <icon icon="info" size="1x"></icon>
                                    </button>
                                </td>
                                <td>

                                    <button @click="currentData(man)" class="bg-transparent text-grey border-0"
                                            data-toggle="modal"
                                            data-target="#edit_sales_person">
                                        <icon icon="user-edit" size="1x"></icon>
                                    </button>
                                </td>
                                <td>

                                    <button @click="changeStatus(man)" class="bg-transparent text-grey border-0">

                                        <icon icon="toggle-on" size="1x" v-if="man.is_active"></icon>
                                        <icon icon="toggle-off" size="1x" v-else=""></icon>
                                    </button>
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Add from './Add.vue';
    import Detail from './Detail.vue';
    import Edit from './Edit.vue';
    import Loader from '../../loader'
    import {Paths} from '../../paths'

    export default{
        extends: Loader,
        components: {Add, Detail, Edit},
        data(){
            return {
                staffs: [],
                url: {
                    add: '',
                    update: '',
                    index: '',
                },
                triggers: {
                    index: false,
                    add: false,
                    update: false,
                },
                staff: null,

            }
        },
        methods: {
            currentData(data){
                this.staff = data;

            },
            newRecordAdded(){
                this.fetchData();
                this.showSuccessMessage('Salesman added successfully!');
            },
            recordUpdated(){
                this.fetchData();
                this.showSuccessMessageWithID('Salesman updated successfully!', this.staff.id);
            },
            changeStatus(man){

                let app = this;

                man.is_active = 1 - man.is_active;
                man._method = 'put';
                man.role = 5;

                this.axios.post(Paths.warehouse_staff.update, man).then(() => {
//                    man.is_active = !man.is_active;
                    if (man.is_active) {
                        app.showSuccessMessageWithID('Account of warehouse staff is enabled', man.id);
                    } else {
                        app.showSuccessMessageWithID('Account of warehouse staff is disabled', man.id);
                    }
                }).catch(() => {
                });

            },
            fetchData(){
                let app = this;
                app.loading = true;
                this.axios.get(this.url.index).then((response) => {
                    app.staffs = response.data;
                }).finally(() => {
                    app.loading = false;
                })
            }
        },
        mounted()
        {
            this.url.add = Paths.warehouse_staff.store;
            this.url.update = Paths.warehouse_staff.update;
            this.url.index = Paths.warehouse_staff.index;
            this.fetchData();
        }
    }
</script>