<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <div class="row">
            <div class="col-sm-12 mb-3">
                <div class="card">
                    <div class="card-body">
                        <p class="heading">Add Payment</p>
                        <hr>

                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <router-link :to="{'name':'payments'}"  class="btn btn-outline-info btn-sm ">Payments</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <CustomerDropDown v-model="customer"></CustomerDropDown>

                                <div class="form-group">
                                    <label for="">Payment Date</label>
                                    <input type="date" class="form-control" v-model="date">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-stripped">
                                        <tr>
                                            <td>Order No</td>
                                            <!--<td></td>z-->
                                            <td>Order Date</td>
                                            <td>Status</td>
                                            <td>Payment Status</td>
                                            <td>Items</td>
                                            <td>Amount Due</td>

                                            <td>Amount Paid</td>
                                            <td>Net Remaining</td>

                                            <td>Discount</td>

                                        </tr>

                                        <tr :class="{
                                    'table-warning':order.payment_status=='Partial Paid',
                                    'table-success':order.payment_status=='Paid',

                                }"
                                            v-for="(order,index) in orders" v-bind:key="index">
                                            <!--<td><input type="checkbox"></td>-->
                                            <td>{{order.id}}</td>
                                            <td>{{order.created_at}}</td>
                                            <td>{{order.order_status}}</td>
                                            <td>{{order.payment_status}}</td>
                                            <td>{{order.quantity}}</td>
                                            <td>{{order.price | currency}}</td>
                                            <td>{{order.amount_paid | currency}}</td>
                                            <td>{{order.payment_due  | currency}}</td>

                                            <td>{{order.discount_amount | currency}}</td>

                                        </tr>

                                        <tr class="table-dark" v-if="orders.length!=0">
                                            <th colspan="5" class="text-center">Total</th>
                                            <th>{{total_due|currency}}</th>
                                            <th>{{total_paid|currency}}</th>
                                            <th>{{(total_due-total_paid)|currency}}</th>
                                            <th>{{total_discount|currency}}</th>
                                        </tr>


                                        <tr v-if="orders.length!=0">
                                            <th colspan="6" class="text-right">Enter Payment</th>
                                            <td colspan="2" class="text-center">
                                                <input type="number" min="1" v-model="payment" class="form-control">
                                            </td>
                                            <td><button class="btn btn-outline-info btn-lg" @click="save_payment">Save</button></td>
                                        </tr>

                                        <tr v-if="orders.length==0">
                                            <td colspan="20" class="text-danger">No unpaid order found</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CustomerDropDown from '@/views/customer/DropDown'
    import {Paths} from '@/paths';
    export default{
        components: {CustomerDropDown},
        data(){
            return {
                customer: null,
                date:'',
                orders: [],
                payment:0,
                paid_orders:[],

            }
        },
        methods: {
            load_orders_for_payment(){
                let app = this;
                if (app.customer == null) {
                    return;
                }

                this.orders=[];
                this.axios.post(Paths.order.payment_due_for_customer, {'customer_id': app.customer.code}).then(function (response) {
                    app.orders = response.data;
                    app.update_payment();
                })
            },
            update_payment(){
                this.payment=this.total_due-this.total_paid;
            },

            save_payment(){

                let app = this;

//                console.log(JSON.stringify({
//                    'user_id':app.customer.code,
//                    orders:app.paid_orders,
//                    payment_date:app.date,
//                    amount_paid:app.payment,
//                    balance:app.balance,
//
//                }));
//
//                return;
                this.axios.post(Paths.order.save_payment,
                    {
                        'user_id':app.customer.code,
                        orders:app.paid_orders,
                        payment_date:app.date,
                        amount_paid:app.payment,
                        balance:app.balance,

                    }).then(function (response) {
                        app.$router.push({'name':'payments'});
                        console.log(JSON.stringify(response));
                });
            },

            update_order_status(amount){
                let app=this;
                let to_pay=0;
                app.paid_orders=[];
                for(let i=0;i<this.orders.length;i++){
                    to_pay=parseFloat(app.orders[i].price)-parseFloat(app.orders[i].amount_paid);
                    console.log(to_pay);
                    if(amount>=to_pay){
                        app.paid_orders.push({
                            order_id:app.orders[i].id,
                            'amount_paid':to_pay,
                            'paid_by':app.customer.code,
                            'is_partial':false,
                            'payment_date':app.date,
                        });
                    }
                    else if(amount<to_pay && amount>0){
                        app.paid_orders.push({
                            order_id:app.orders[i].id,
                            'amount_paid':amount,
                            'paid_by':app.customer.code,
                            'is_partial':true,
                            'payment_date':app.date,
                        });
                    }

                    amount-=to_pay;
                }

            }

        },
        watch: {
            customer: function (value) {
                if (value == null) {
                    this.orders = [];
                    return;
                }
                this.load_orders_for_payment();
            },
            payment:function (amount) {
                this.update_order_status(parseFloat(amount));
            }
        },
        computed: {
            total_due:function () {
//                console.log(JSON.stringify(this.orders));

                return this._.sumBy(this.orders,function (o) {
                    console.log(JSON.stringify(o));
                   return  parseFloat(o.price);
                });
            },
            total_paid:function () {
                return this._.sumBy(this.orders,function (o) {
                    return  parseFloat(o.amount_paid);
                });
            },
            total_discount:function () {
                return this._.sumBy(this.orders,function (o) {
                    return  parseFloat(o.discount_amount);
                });
            },
            balance:function () {
                return this.payment-(this.total_due-this.total_paid);
            }
        },
        mounted(){
            this.date=new Date().toISOString().slice(0,10);
        }
    }
</script>