<template>

    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="">Select product</label>
                <select name="" @change="updateUnit" class="form-control" id="" v-model="input.item_id">
                    <option value="0">Select</option>
                    <option v-for="(item,index) in items" v-bind:key="index"
                            :value="item.id">{{item.name}} ( {{item.product_no == null ? "---" : item.product_no}} )
                    </option>
                    <option value="-1">New</option>
                </select>
                <span class="text-danger">{{getError('item_id')}}</span>
            </div>

            <transition name="fade">
                <item-form ref="add_item_form" v-on:added="itemAdded" :is_submit="false"
                           v-if="input.item_id==-1"></item-form>
            </transition>

            <div v-if="input.item_id!=0">
                <div class="form-group" v-if="input.item_id!=-1">
                    <p>{{old_quantity}} is available in inventory</p>
                    <p>Per unit price is $ {{input.price}}</p>
                </div>
                <div class="form-group">
                    <label for="">Add quantity <span v-if="input.item_id!=-1">in ({{unit}})</span> </label>
                    <input type="text" min="0" class="form-control" v-model="input.debit">
                    <span class="text-danger">{{getError('debit')}}</span>
                </div>

            </div>

        </div>
        <div class="col-12">
            <div v-if="loading">
                <pulse-loader :loading="loading"></pulse-loader>
            </div>
            <div class="row" v-else="">
                <div class="col-sm-12">
                    <button @click="store" type="button" class="btn btn-outline-info shadow mb-1 mr-2">Save
                    </button>

                    <button ref="close_btn" type="button" @click="closeDialog"
                            class="btn btn-danger shadow mb-1"
                            data-dismiss="modal">Close
                    </button>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
    import forms from '@/forms';
    import {Paths} from '@/paths';
    import ItemForm from '@/views/items/form/Add';

    export default{
        extends: forms,
//        props: ['items'],
        components: {ItemForm},
        data(){
            return {
                unit: '',
                old_quantity: 0,
                input: {
                    item_id: 0,
                    debit: 0,
                    price: 0,
                }, success: false,
                path: '',
                items: {},
            }
        }, methods: {
            loadItemsList(){
                let app = this;
                this.axios.get(Paths.item.drop_down).then((response) => {
                    app.items = response.data;
                });
            },
            itemAdded(data){
                this.items[data.id] = data;
                this.input.item_id = data.id;
                this.store();
            },
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('added');
                this.$refs.close_btn.click();
            },

            clearData(){
                this.input = {
                    item_id: 0,
                    debit: 0,
                    price: 0,

                };
                this.unit = '';
                this.old_quantity = 0;

            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;

                this.clearData();
            },
            updateUnit(){
                if (this.input.item_id < 1) {
                    return;
                }
                this.unit = this.items[this.input.item_id].unit;
                this.input.price = this.items[this.input.item_id].price;
                this.old_quantity = this.items[this.input.item_id].quantity_with_unit;
            },

            store(){
                if (this.input.item_id == -1) {
                    this.$refs.add_item_form.store();
                    return;
                }
                let app = this;
                this.loading = true;

                this.axios.post(this.path, this.input).then(() => {
                    app.success = true;
                }).catch((errors) => {
                    app.setErrors(errors.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });

            }
        },
        mounted(){
            this.path = Paths.transaction.store;
            this.loadItemsList();
        }
    }
</script>