<template>

    <div class="row">
        <div class="col-sm-12 bg-light shadow p-3 text-left ">
            <p class="heading">Welcome {{$auth.user().first_name}}</p>
            <hr>
            <div class="row">

                <DashboardIcon route="deliveries.new" src="assets/icons/order.png"
                               name="Orders"></DashboardIcon>
                <DashboardIcon route="messages" src="assets/icons/message1.png"
                               name="Messages"></DashboardIcon>


            </div>

        </div>
    </div>

</template>

<script>
    import DashboardIcon from '@/views/components/DashboardIcon.vue';
    export default{
        components: {DashboardIcon},
    }
</script>

