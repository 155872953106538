<template>
    <div class="form-group">
        <label>Customer</label>
        <v-select :options="customers_dropdown" v-model="customer" @search="search_customers"/>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    export default{
        data(){
            return {
                customers: [],
                customer: null,

            }
        },
        methods: {
            search_customers(data){
                let app = this;
                this.axios.post(Paths.customer.search, {company: data}).then(function (response) {
                    app.customers = response.data;
                })

            },
        },
        watch: {
            customer: function (value) {
                this.$emit('input', value);
            }
        },
        computed: {
            customers_dropdown(){
                let options = [];

                let app = this;
                for (let i = 0; i < this.customers.length; i++) {
                    options.push({
                        label: app.customers[i].company,
                        code: app.customers[i].user_id,
                        credit_limit: app.customers[i].max_payment_limit
                    });
                }
                return options;
            },
        },
        mounted(){

        }

    }
</script>