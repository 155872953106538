<template>
    <div>
        <div class="container-fluid content-container pl-5 pr-5">
            <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleInventory"
                   v-on:error="setErrors($event.response)"
                   v-on:request="trigger=false;"></axios>
            <add :path="url.add" :items="items" v-on:added="newRecordAdded"></add>
            <detail :path="url.detail" :inventory="inventory"></detail>
            <Waste :inventory="inventory" v-on:wasteSaved="wasteSaved"></Waste>
            <div class="row">
                <div class="col-12 bg-light shadow p-3  text-left ">
                    <p class="heading">Inventory</p>
                    <hr>
                    <div class="text-center">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>

                    <im type="inventories"></im>

                    <div class="row">

                        <div class="col-4">
                            <select name="" v-model="category_filter" class="form-control" id="">
                                <option value="0">All Categories</option>
                                <option v-for="category in $store.getters.categories" :key="category.id"
                                        :value="category.id">
                                    {{category.name}}
                                </option>
                            </select>
                        </div>

                    </div>
                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered  ">
                            <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Image</th>
                                <th scope="col">Product #</th>
                                <th scope="col">Name</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Last Updated</th>
                                <th scope="col">Is Popular</th>
                                <th scope="col" class="w-150">Discount</th>
                                <th>Detail</th>
                                <th>Waste</th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="filteredInventory.length==0">
                                <td colspan="9">No record found</td>
                            </tr>
                            <tr :class="{'table-success':changed_id==inventory.id}"
                                v-for="(inventory,index) in filteredInventory" :key="index">
                                <th scope="row">{{(parseInt(index) + 1)}}</th>
                                <td><img :src="$store.getters.rootPath+'item?image='+inventory.item_image"
                                         class="img-thumbnail" style="width: 150px;" alt=""></td>
                                <td>{{inventory.item.product_no}}</td>
                                <td>{{inventory.item_name}}</td>
                                <td>{{inventory.quantity_with_units}}</td>
                                <td v-if="has_discount(inventory.discount)">
                                    <del class="text-danger mr-3">$ {{inventory.price}}</del>
                                    <span>$ {{(inventory.price - (inventory.price * inventory.discount.discount / 100)).toFixed(2)}}</span>
                                </td>
                                <td v-else="">$ {{inventory.price}}</td>
                                <td>{{inventory.updated_on}}</td>
                                <td class="text-center">
                                    <famous :inventory_id="inventory.id" :checked="inventory.is_famous"></famous>
                                </td>
                                <td class="">
                                    <discount :inventory_id="inventory.id"
                                              :old_discount="inventory.discount"
                                              v-on:discounted="discounted"></discount>
                                </td>
                                <td>
                                    <button @click="currentData(inventory)" title="Detail"
                                            class="bg-transparent text-grey border-0"
                                            data-toggle="modal"
                                            data-target="#inventory_detail">
                                        <icon icon="info" size="1x"></icon>
                                    </button>
                                </td>
                                <td>
                                    <input type="button" @click="currentData(inventory)" data-toggle="modal"
                                           data-target="#waste"
                                           class="btn btn-outline-info  btn-sm float-right mb-3 shadow"
                                           value="Wasted Items"/>
                                </td>
                                <!--<td>-->

                                <!--<button @click="currentData(inventroy)" title="Edit"-->
                                <!--class="bg-transparent text-grey border-0"-->
                                <!--data-toggle="modal"-->
                                <!--data-target="#edit_item">-->
                                <!--<icon icon="user-edit" size="1x"></icon>-->
                                <!--</button>-->
                                <!--</td>-->

                            </tr>

                            </tbody>
                        </table>
                    </div>


                    <paging :total="total" @page_changed="fetchData"></paging>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Add from './Add.vue';
    import Famous from './form/Famous.vue';
    import Discount from './form/Discount.vue';
    import Waste from './Waste.vue';
    //    import Edit from './Edit.vue';
    import im from '@/views/layouts/InventoryMenu'
    import Detail from './Detail.vue';
    import forms from '@/forms'
    import Paging from '../components/Paging.vue';
    import {Paths} from '../../paths'
    import axios from  '@/views/actions/Axios';

    export default{
        extends: forms,
        components: {Add, Detail, Famous, Discount, Waste, Paging, im, axios},
        data(){
            return {

                request: 'get',
                url: '',
                data: {},
                items: [],
                category_id: 0,
                total: 0,
                category_filter:0,
                trigger:false,
            }
        },

        methods: {
            handleInventory(data){
                if (this.url == Paths.item.with_inventory) {
                    this.items = data.items;
                    this.total = data.total;
                }
                console.log(data);
            },
            has_discount(discount){
                if (!discount) {
                    return false;
                }
                if (discount.discount == 0) {
                    return false;
                }

                return true;
            },
            discounted(data){
                let index = this._.findIndex(this.inventories, {'id': data.inventory_id});
                this.inventories[index].discount = data;
            },
            loadItemsList(){
                let app = this;
                this.axios.get(Paths.item.drop_down).then((response) => {
                    app.items = response.data;
                });
            },
            currentData(data){
                this.inventory = data;

            },
            wasteSaved(){
                this.fetchData();
                this.showSuccessMessage('Success! Inventory is updated');
            },
            newRecordAdded(){
                this.fetchData();
                this.showSuccessMessage('Success! Product added to inventory!');
            },
            recordUpdated(){
                this.fetchData();
                this.showSuccessMessageWithID('Success! Inventory updated successfully!', this.inventory.id);
            },

            fetchData(page){
                console.log('Page in fetch data' + page);
                let app = this;
                app.loading = true;
                app.inventories = [];
                console.log(app.current_page);
                this.axios.get(Paths.inventory.table, {params: {'start': page}}).then((response) => {
                    app.inventories = response.data.records;
                    app.total = response.data.total;
                }).finally(() => {
                    app.loading = false;
                })
            }
        },
        computed: {

//            filteredInventory(){
//                if (this.category_filter == 0) {
//                    return this.inventories;
//                }
//                let app = this;
//                return this._.filter(this.inventories, function (inventory) {
//                    return inventory.item.category_id == app.category_filter;
//                });
//
//            }
        },
        mounted()
        {
            this.url = Paths.item.with_inventory;
            this.trigger = true;
        }
    }
</script>