<template>
    <div class="row">
        <div class="col-12 mt-3 pl-0 pr-0">
            <textarea v-on:keyup.enter="sendMessage()" class="form-control pl-3 pr-3 ml-0 mr-0"
                      v-model="input.content"></textarea>
            <span class="text-danger"></span>
            <input type="button" :disabled="disable_send" @click="sendMessage()"
                   class="btn btn-outline-info btn-sm mt-2 float-right"
                   value="Send Message">
        </div>
    </div>
</template>
<script>
    import UserMixin from '@/mixins/User.js';
    import Form from '@/forms';
    import {Paths} from '@/paths';

    import {Bus} from '@/main';
    export default{
        extends: Form,
        props: {'message_id': {type: Number, default: 0}, 'to': {type: Number, default: 0}},
        mixins: [UserMixin],
        data(){
            return {
                input: {
                    content: '',
                    message_id: 0,
                    sent_by: 0,
                    sent_to: 0,
                }
            }
        },
        watch: {
            message_id(){
                this.loadDefaults();
            }
        },

        methods: {
            clearMessage(){
                this.input.content = '';
            },
            loadDefaults(){
                this.input.message_id = this.message_id;
                this.input.sent_by = this.user;
                this.input.sent_to = this.to;

            },
            sendMessage(){
                let app = this;
                this.startRequest();
                this.axios.post(Paths.message.send, this.input).then((response) => {
                    response.data.message = null;
                    Bus.$emit('message-new', response.data);
                    app.clearMessage();
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest();
                })

            }
        },
        computed: {
            disable_send(){
                if (this.loading) {
                    return true;
                }
                if (this.input.content.length == 0) {
                    return true;
                }
                return false;
            }
        },
        mounted(){
            this.loadDefaults();
        }
    }
</script>