<template>

    <div class="row" v-if="registration!=null">
        <div class="col-sm-12">
            <div class="table-responsive">
                <table class="table table-striped">

                    <tr>
                        <td class="border-top-0">Company</td>
                        <td class="border-top-0">{{registration.customer.customer.company}}</td>
                    </tr>
                    <tr>
                        <td>Account Type</td>
                        <td>{{registration.customer.customer.account_type_name}}</td>
                    </tr>
                    <tr v-if="registration.customer.customer.ti_number!=null">
                        <td>TI Number</td>
                        <td>{{registration.customer.customer.ti_number}}</td>
                    </tr>
                    <tr v-if="registration.customer.customer.exempt_number!=null">
                        <td>Exempt Number</td>
                        <td>{{registration.customer.customer.exempt_number}}</td>
                    </tr>
                    <tr>
                        <td>Contact Person</td>
                        <td>{{registration.customer.full_name}}</td>
                    </tr>

                    <tr>
                        <td>Email</td>
                        <td>{{registration.customer.email}}</td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>
                            {{registration.customer.customer.phone == null ? 'Not Provided' : registration.customer.customer.phone}}
                        </td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>
                            {{registration.customer.customer.address == null ? 'Not Provided' : registration.customer.customer.address}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-sm-12"
             v-if="(registration.status<3 || registration.status==5)">
            <p class="heading">Assign to Sales</p>
            <hr/>
            <div class="row">
                <div class="col-sm-5" v-if="registration.status<3">
                    <div class="form-group">
                        <label for="">Select salesman</label>
                        <select class="form-control" v-model="user_id">
                            <option value="0">Select</option>
                            <option v-for="(salesman,index) in salesmen" v-bind:key="index"
                                    :value="salesman.id">
                                {{salesman.full_name}}
                            </option>
                        </select>
                        <span class="text-danger">{{getError('user_id')}}</span>
                    </div>

                    <input type="button" @click="assign()" :disabled="loading" class="btn btn-outline-info btn-sm"
                           value="Assign">
                </div>
                <div class="col-12" v-else-if="registration.status==5 && registration.salesman!=null">
                    <p>Assigned To : <strong>{{registration.salesman.full_name}}</strong></p>
                </div>
            </div>

        </div>
        <hr>

        <div class="col-sm-12"
             v-if="registration.status!=5 && registration.status<3 ">
            <p class="heading mt-3">Approve</p>
            <hr/>
            <div class="row">
                <div class="col-sm-5">


                    <input type="button" @click="approve()" :disabled="loading"
                           class="btn btn-outline-info btn-sm mr-2 mb-2" value="Approve">
                </div>
            </div>
            <p class="heading mt-3">Reject</p>
            <hr/>
            <div class="row">
                <div class="col-sm-5">
                    <reject v-on:rejected="rejected" :registration_id="registration.id"></reject>
                </div>
            </div>
        </div>
        <div class="col-12" v-else-if="registration.status==3">Request status: <span
                class="text-success">Approved</span></div>
        <div class="col-12" v-else-if="registration.status==4">
            Rejected because  of following reason <br> Reason: <i
                class="text-danger pl-1">{{registration.reason}}.</i>
        </div>
    </div>

</template>
<script>

    import forms from '@/forms';


    import {Paths} from '@/paths';
    import Reject from '@/components/customer/Reject';
    export default{
        extends: forms,
        props: ['registration'],
        components: {Reject},
        data(){
            return {
                salesmen: [],
                user_id: 0,
            }
        }, methods: {
            findAssignedSalesMan(){
                let app = this;
                for (let i = 0; i < this.salesmen.length; i++) {
                    if (app.salesmen[i].id == app.user_id) {
                        return app.salesmen[i];
                    }
                }
                return null;
            },
            assigned(){

                this.$emit('assigned', this.findAssignedSalesMan());
            },
            rejected(data){
                this.$emit('rejected', data);
            },

            loadSalesman(){
                let app = this;

                this.startRequest();
                this.axios.get(Paths.salesman.index).then((response) => {
                    app.salesmen = response.data;
                }).catch(() => {
                }).finally(() => {
                    app.endRequest();
                });
            },
            assign(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.assign, {
                    'user_id': this.user_id,
                    'registration_id': this.registration.id
                }).then(() => {
                    app.assigned();
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest()
                });
            },
            approve(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.approve, {
                    'registration_id': this.registration.id
                }).then(() => {
                    this.$emit('approved');
                }).finally(() => {
                    app.endRequest()
                });
            }
        },
        mounted(){
            this.loadSalesman();
        }
    }
</script>