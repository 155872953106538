<template>
    <div>
        <div class="container-fluid content-container pl-5 pr-5">
            <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleInventory"
                   v-on:error="setErrors($event.response)"
                   v-on:request="trigger=false;"></axios>
            <div class="row">
                <div class="col-12 bg-light shadow p-3  text-left ">
                    <p class="heading">Inventory Detail</p>
                    <hr>
                    <div class="text-center">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>

                    <im type=""></im>

                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive mb-2">
                        <table class="table table-hover table-bordered  ">

                            <tr>

                                <td scope="col">Product #</td>
                                <td colspan="5">{{item.product_no}}</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td colspan="5">{{item.name}}</td>
                            </tr>

                            <tr>
                                <td>Price</td>
                                <td >{{item.price|currency}}</td>
                                <td>Cost</td>
                                <td >{{item.cost|currency}}</td>
                            </tr>
                            <tr>
                                <td>Quantity</td>
                                <td >{{item.inventory_relation==null?0:item.inventory_relation.quantity}}</td>

                                <td>Amount</td>
                                <td >{{(item.price*(item.inventory_relation==null?0:item.inventory_relation.quantity))|currency}}</td>

                            </tr>
                            <tr>
                                <td>Is Popular</td>
                                <td colspan="5"><input type="checkbox"  v-model="item.is_famous" ></td>

                            </tr>
                            <tr>
                                <td>Discount</td>
                                <td>
                                    <input type="checkbox" @click="fixed_discount" v-model="discount.is_fix" > Fixed Discount
                                    <input type="checkbox" @click="percent_discount" v-model="discount.in_percentage" > Discount percentage
                                </td>
                                <td colspan="2">
                                    <label for="">Discount in {{discount_label}}</label>
                                    <input type="text" v-model="discount.discount" class="form-control" >
                                </td>

                            </tr>

                        </table>
                    </div>

                    <h4>Transactions</h4>
                    <hr>

                    <div class="row mb-2">
                        <div class="col-sm-4">

                                <div class="col-sm-12 border p-3 bg-success">

                                        <label for="" class="text-light">No of items to add in inventory</label>
                                        <input type="text" class="form-control" v-model="transaction.debit">



                                    <input type="text" @click="add_inventory" class="btn btn-light btn-sm  mt-2" value="Add to inventory">
                                </div>

                        </div>

                        <div class="col-sm-4 offset-sm-1">

                                <div class="col-sm-12 border p-3 bg-warning">

                                        <label for="" class="text-light">No of items wasted</label>
                                        <input type="text" class="form-control" v-model="transaction.credit">



                                    <input type="text" @click="wasted_inventory" class="btn btn-light btn-sm  mt-2" value="Save">
                                </div>

                        </div>


                    </div>

                    <div class="table-responsive mb-2">
                        <table class="table table-bordered" v-if="item.inventory_relation!=null">
                            <tr>
                                <th>Date</th><th>Added</th><th>Consumed</th><th>Waste</th><th>Action</th>
                            </tr>
                            <tr v-for="(transaction,index) in item.inventory_relation.transactions"
                                 v-bind:key="transaction.id"
                                 v-bind:class="{'table-success':transaction.debit>0,'table-warning':transaction.credit>0,'table-danger':transaction.is_removed}">
                                <td>{{transaction.created_at}}</td>
                                <td>{{transaction.debit>0?"+"+transaction.debit:''}}</td>
                                <td>{{transaction.credit>0 && !transaction.is_waste?"-"+transaction.credit:''}}</td>
                                <td>{{transaction.is_waste?'-'+transaction.credit:''}}</td>
                                <td><button v-if="transaction.debit>0||transaction.is_waste" @click="remove_transaction(index)" class="btn btn-danger btn-sm " >Remove</button></td>

                            </tr>
                        </table>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import im from '@/views/layouts/InventoryMenu'
    import forms from '@/forms'
    import {Paths} from '../../paths'
    import axios from  '@/views/actions/Axios';
    import _ from 'lodash';

    export default{
        extends: forms,
        props:['item_id'],
        components: {
            im, axios
        },
        data(){
            return {

                request: 'post',
                url: '',
                data: {},
                path:'',
                discount:{is_fix:true,in_percentage:false,discount:0},


                trigger: false,
                item:{},
                transaction:{
                    debit:0,
                    credit:0,
                    item_id:0,

                },

                transaction_index:-1,
            }
        },

        methods: {
            set_discount(){
                if(this.item==null){
                    return;

                }
                if(this.item.inventory_relation==null){
                    return;
                }

                if(this.item.inventory_relation.discount==null){
                    return;
                }
                this.discount=this.item.inventory_relation.discount;
            },
            update_item(){
                this.url=Paths.item.update;
                this.data=this.item;
                this.trigger=true;
            },
            set_transaction(){
              if(this.item==null){
                  return;
              }

              if(this.item.inventory_relation==null){
                  return;
              }

              this.transaction.item_id=this.item.id;

            },
            add_inventory(){
                this.url=Paths.transaction.store;
                this.data=this.transaction;
                this.trigger=true;
            },

            wasted_inventory(){
                this.url=Paths.waste.store;
                this.data=this.transaction;
                this.data.inventory_id=this.item.inventory_relation.id;
                this.data.is_waste=true;
                this.trigger=true;
            },
            handleInventory(data){

                if(this.url==Paths.item.detail){
                    this.item = data.data;
                    this.set_discount();
                    this.set_transaction();
                    return;
                }

                if(this.is_transaction_request()){

                    if(data.data.debit>0){
                        this.item.inventory_relation.quantity+=parseInt(data.data.debit);
                        this.item.inventory_relation.transactions.splice(0,0,data.data);
                    }

                    return;

                }

                if(this.is_remove_transaction_request()){
                    this.item.inventory_relation.transactions[this.transaction_index].is_removed=true;
                    if(data.data.is_waste){
                        this.item.inventory_relation.quantity+=parseInt(this.item.inventory_relation.transactions[this.transaction_index].credit);
                        return;
                    }
                    this.item.inventory_relation.quantity-=parseInt(this.item.inventory_relation.transactions[this.transaction_index].debit);

                    return;
                }

                if(this.is_waste_request()){
                    this.item.inventory_relation.quantity-=parseInt(data.data.credit);
                    this.item.inventory_relation.transactions.splice(0,0,data.data);
                }


            },
            is_waste_request(){
              return this.url==Paths.waste.store;
            },
            is_remove_transaction_request(){
                return this.url==Paths.transaction.delete;
            },
            is_transaction_request(){
                return this.url==Paths.transaction.store;
            },
            loadItem(){
                this.data={'id':this.item_id};
                this.trigger=true;
            },
            fixed_discount(){
                this.discount.in_percentage=false;
            },
            percent_discount(){
                this.discount.is_fix=false;
            },
            remove_transaction(index){
                this.transaction_index=index;
                this.transaction.transaction_id=this.item.inventory_relation.transactions[index].id;
                this.transaction.item_id=this.item.id;
                this.data=this.transaction;
                this.url=Paths.transaction.delete;
                this.trigger=true;

            },
            update_discount:_.debounce(function () {
                this.data=this.discount;
                this.data['inventory_id']=this.item.inventory_relation==null?0:this.item.inventory_relation.id;
                this.url=Paths.inventory.discount;
                this.request='post';
                this.trigger=true;

            },1000),
        },
        watch:{
            discount:{
                handler () {
                    this.update_discount();

            },
                deep:true
            },
            'item.is_famous':function () {
                this.update_item();
            }
        },
        computed: {

            discount_label(){
                if(this.discount.is_fix){
                    return '$';
                }
                return '%';
            }


        },
        mounted()
        {
            this.url = Paths.item.detail;
            this.loadItem();

        }
    }
</script>