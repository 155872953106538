<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row">
            <div class="col-sm-12 bg-light shadow  p-3">
                <p class="heading">Payment Successfull</p>
                <hr/>
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-success">
                            <h4>Thank you for your payment</h4>
                        </div>
                    </div>


                    <div class="col-12">
                        <p class="text-success text-center">Loading order detail</p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>
<script>

    export default{
        props: ['order'],


        data(){
            return {}
        },


        mounted(){
            this.$store.commit('clearCart');
            let app = this;
            setTimeout(function () {
                app.$router.push({name: 'order.detail', params: {id: app.order, nid: 0}});
            }, 3000);


        }
    }

</script>