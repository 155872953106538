<template>
    <div class="modal fade" id="edit_category" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog  " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Edit Food Category</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-left">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Name of food category</label>
                                <input type="text" class="form-control" v-model="input.name">
                                <span class="text-danger">{{getError('name')}}</span>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Category code</label>
                                <input type="text" class="form-control"
                                       v-model="input.code">
                                <span class="text-danger">{{getError('code')}}</span>
                            </div>
                        </div>

                    </div>


                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>
                    <div class="row" v-else="">
                        <div class="col-sm-12">
                            <button @click="store" type="button" class="btn btn-outline-info shadow mr-3">Save changes
                            </button>
                            <button ref="close_btn" type="button" @click="closeDialog"
                                    class="btn btn-danger shadow"
                                    data-dismiss="modal">Close
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';
    //    import {Paths} from '@/paths';
    export default{
        extends: forms,
        props: ['path', 'category'],
        data(){
            return {
                input: {
                    id: '',
                    name: '',
                    code: '',

                }, success: false,
            }
        },
        methods: {
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('updated');
                this.$refs.close_btn.click();
            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;

            },
            store(){
                alert('store called');
                let app = this;
                this.loading = true;
                this.axios.post(app.path, app.input).then(() => {
                    app.success = true;

                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });
            }
        },
        watch: {
            category(newVal){
                if (newVal == null) {
                    return;
                }
                this.input.name = newVal.name;
                this.input.code = newVal.code;
                this.input.id = newVal.id;

            },
            'input.name': function (new_val) {
                if (new_val.length == 0) {
                    return '';
                }

                this.input.code = new_val.toUpperCase().replace(/ /g, '_').replace('&', '').replace('__', '_');
            }
        },
        mounted(){

        }
    }
</script>