<template>

    <div class="row">
        <!--<div class="col-sm-12">-->


            <!--&lt;!&ndash;<div class="row" v-if="input.category_id>0">&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="col-sm-6">&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="form-group">&ndash;&gt;-->
            <!--&lt;!&ndash;<label for="">Select sub category</label>&ndash;&gt;-->
            <!--&lt;!&ndash;<sub-category-dropdown v-model="input.sub_category_id"&ndash;&gt;-->
            <!--&lt;!&ndash;:category_id="input.category_id"></sub-category-dropdown>&ndash;&gt;-->
            <!--&lt;!&ndash;<span class="text-danger">{{getError('sub_category_id')}}</span>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="col-sm-6">&ndash;&gt;-->
            <!--&lt;!&ndash;<transition class="fade">&ndash;&gt;-->
            <!--&lt;!&ndash;<add-sub-category v-on:new_sub_cat="newSubCategory" :category_id="input.category_id"&ndash;&gt;-->
            <!--&lt;!&ndash;:show="input.sub_category_id==-1"></add-sub-category>&ndash;&gt;-->
            <!--&lt;!&ndash;</transition>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->

            <!--<div class="row">-->

                <!--&lt;!&ndash;<div class="col-sm-6">&ndash;&gt;-->
                <!--&lt;!&ndash;<transition name="fade">&ndash;&gt;-->
                <!--&lt;!&ndash;<add-unit v-on:added="unitAdded" :is_submit="false" v-if="input.unit_id==-1"></add-unit>&ndash;&gt;-->
                <!--&lt;!&ndash;</transition>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--</div>-->


            <!--<div class="row">-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Name of product</label>-->
                        <!--<input type="text" class="form-control" v-model="input.name">-->
                        <!--<span class="text-danger">{{getError('name')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Product ID</label>-->
                        <!--<input type="text" class="form-control" v-model="input.product_no">-->
                        <!--<span class="text-danger">{{getError('product_no')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Price</label>-->
                        <!--<input type="text" class="form-control" v-model="input.price">-->
                        <!--<span class="text-danger">{{getError('price')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Cost</label>-->
                        <!--<input type="text" class="form-control" v-model="input.cost">-->
                        <!--<span class="text-danger">{{getError('cost')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Select category</label>-->
                        <!--<select name="" class="form-control" v-model="input.category_id">-->
                            <!--<option value="0">Select</option>-->
                            <!--<option v-for="(category,index) in $store.getters.categories"-->
                                    <!--v-bind:key="index"-->
                                    <!--:value="category.id">{{category.name}}-->
                            <!--</option>-->
                            <!--&lt;!&ndash;<option value="-1">New</option>&ndash;&gt;-->
                        <!--</select>-->
                        <!--<span class="text-danger">{{getError('category_id')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Select Brand</label>-->
                        <!--<select name="" class="form-control" v-model="input.brand_id">-->
                            <!--<option value="0">Select</option>-->
                            <!--<option v-for="(brand,index) in $store.getters.brands"-->
                                    <!--v-bind:key="index"-->
                                    <!--:value="brand.id">{{brand.name}}-->
                            <!--</option>-->
                            <!--&lt;!&ndash;<option value="-1">New</option>&ndash;&gt;-->
                        <!--</select>-->
                        <!--<span class="text-danger">{{getError('brand_id')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-sm-6">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Select Unit</label>-->
                        <!--<select name="" class="form-control" v-model="input.unit_id">-->
                            <!--<option value="0">Select</option>-->
                            <!--<option v-for="(unit,index) in $store.getters.units"-->
                                    <!--v-bind:key="index"-->
                                    <!--:value="unit.id">{{unit.name}}-->
                            <!--</option>-->
                            <!--&lt;!&ndash;<option value="-1">New</option>&ndash;&gt;-->
                        <!--</select>-->
                        <!--<span class="text-danger">{{getError('unit_id')}}</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-12">-->
                    <!--<div class="form-group">-->
                        <!--<label for="">Image <sub class="text-info">(Optional)</sub></label>-->

                        <!--<p v-if="image.length>0">Selected file: {{image}}</p>-->
                        <!--<div class="row">-->
                            <!--<div class="col-sm-6 mb-2">-->
                                <!--<input type="button" class="btn btn-outline-info btn-block"-->
                                       <!--@click="$refs.file.click()"-->
                                       <!--value="Select Image">-->
                            <!--</div>-->
                            <!--<div class="col-sm-6">-->
                                <!--<input type="button" class="btn btn-outline-dark btn-block"-->
                                       <!--@click="removeImage"-->
                                       <!--value="Remove Image">-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<input @change="setFile" style="display: none;" type="file" ref="file">-->
                        <!--<p class="text-danger">{{getError('image')}}</p>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-12" v-if="loading">-->
                    <!--<pulse-loader :loading="loading"></pulse-loader>-->
                <!--</div>-->
                <!--<div v-if="is_submit && !loading" class="col-sm-12">-->
                    <!--<button @click="store" type="button"-->
                            <!--class="btn btn-outline-info shadow mb-1 mr-2">Save-->
                    <!--</button>-->
                    <!--<button ref="close_btn" type="button" @click="closeDialog"-->
                            <!--class="btn btn-danger shadow mb-1"-->
                            <!--data-dismiss="modal">Close-->
                    <!--</button>-->
                <!--</div>-->
            <!--</div>-->


        <!--</div>-->
    </div>
</template>
<script>

    import forms from '@/forms';
    import {Paths} from '@/paths';
    //    import AddCategory from '@/views/category/forms/Add';
    //    import AddUnit from '@/views/unit/form/Add';
    //    import AddSubCategory from '@/views/subcategory/Create';
    //    import subCategoryDropdown from '@/views/subcategory/Dropdown';
    export default{
        extends: forms,
        props: ['is_submit'],
//        components: {AddCategory},
        data(){
            return {
                image: '',

                input: {
                    category_id: 0,
                    sub_category_id: 0,
                    unit_id: 0,
                    name: '',
                    unit_name: '',
                    price: 0,
                    cost: 0,
                    product_no: '',
                    brand_id: 0,
                }, success: false,

                new_item: null,
                url: '',
            }
        }, methods: {
            categoryAdded(category){
                this.$store.commit('addCategory', category);
                this.input.category_id = category.id;
//                console.log(category);
//                this.input.category_id = 0;
            },
            unitAdded(unit){
                this.$store.commit('addUnit', unit);
                this.input.unit_id = unit.id;
                this.setUnitName();
            },
            setUnitName(){
                if (this.input.unit_id < 1) {
                    this.input.unit_name = '';
                    return;
                }
                let unit = this._.find(this.$store.getters.units, ['id', this.input.unit_id]);
                this.input.unit_name = unit.name;
            },
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('added', this.new_item);
                if (!this.is_submit) {
                    return;
                }
                this.$refs.close_btn.click();
            },

            clearData(){
                this.input = {
                    name: '',
                    category_id: 0,
                    unit_id: 0,
                };
                this.image = '';
            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;
                this.clearData();
            },
            setFile: function (event) {
                this.file = event.target.files[0];
                this.image = this.file.name;
            }, removeImage: function () {
                this.file = null;
                this.image = '';
            },
            getFormData: function () {
                let formData = new FormData();
                formData.append('category_id', this.input.category_id);
                formData.append('unit_id', this.input.unit_id);
                formData.append('brand_id', this.input.brand_id);
                formData.append('name', this.input.name);
                formData.append('price', this.input.price);
                formData.append('cost', this.input.cost);
                formData.append('product_no', this.input.product_no);
                formData.append('sub_category_id', this.input.sub_category_id);
                if (this.file == null) {
                    return formData;
                }
                formData.append('image', this.file);
                return formData;
            },
            unitChanged(){
                this.$emit('unit_changed', this.input.unit_name);
            },
            priceChanged(){
                this.$emit('price_changed', this.input.price);
            },
            newSubCategory(data){
                this.$store.commit('addSubCategory', data);
                let app = this;
                setTimeout(function () {
                    app.input.sub_category_id = data.id;
                }, 500);

            },
            store(){
                let app = this;
                this.loading = true;
                let formData = this.getFormData();
                this.axios.post(this.url, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                    app.new_item = response.data;
                    app.success = true;

                }).catch((errors) => {
                    app.setErrors(errors.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });

            }
        },

        computed: {
            allowItemInput(){
                if (this.input.category_id > 0 && this.input.unit_id > 0) {
                    return true;
                }
                return false;
            }
        },
        watch: {},
        mounted(){
            this.url = Paths.item.store;
        }
    }
</script>