<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <h4>Order # {{id}}</h4>
                <hr>
                <detail v-if="$auth.check('admin') || $auth.check('sales')" :order="order" v-on:assigned="loadOrder()"
                        v-on:completed="loadOrder()"
                        v-on:rejected="loadOrder()" v-on:approved="loadOrder()"
                        v-on:sales_man_assigned="salesManAssigned"></detail>
                <customer-detail v-else-if="$auth.check('customer')" :order="order"></customer-detail>
                <router-link :to="{name:'customer.orders'}" class="btn btn-sm btn-outline-info">View Orders</router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Codes from '@/order_codes';
    import Detail from '@/views/order/salesman/OrderDetail';
    import CustomerDetail from '@/views/order/CustomerOrderDetail';
    import {Bus} from '@/main';
    export default{
        components: {Detail, CustomerDetail},
        props: ['id', 'nid'],
        data(){
            return {
                order: null,
            }
        },
        methods: {
            loadOrder(){
                let app = this;
                this.axios.post(Paths.order.get, {
                    'notification_id': this.nid,
                    'order_id': this.id
                }).then((response) => {
                    app.order = response.data;
                    Bus.$emit('refresh_notifications');
                });
            },
            salesManAssigned(){
                this.loadOrder();
            },
            approved(){
                this.order.status = Codes.forApproved();
            },

        },
        watch: {
            id(){
                this.loadOrder();
            }
        },
        mounted(){
            this.loadOrder();
        }
    }
</script>