export default {
    methods: {
        async listByPost(path, params){

            await this.axios.post(path, params).then(response => {
                    return response.data
                }
            );
        },
        // listByGet(path, params){
        //
        //     return await this.get(path, params);
        // },
        async get(path, params){
            return await this.axios.get(path, params);
        },
        test(){
            alert(1234);
        }
    }, mounted(){
        console.log('Created');
    },
    created(){
        console.log('Created');
    }
}
