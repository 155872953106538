<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <div class="modal fade" id="order_detail" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Order Detail</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-left">
                        <detail :order="order"></detail>
                    </div>
                    <div class="row">

                        <div class="col-12 p-5">
                            <button type="button" class="btn float-right btn-outline-info btn-sm btn-wide"
                                    data-dismiss="modal">Close
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <p class="heading">Your Orders</p>
                <hr/>
                <div class="table-responsive">
                    <table class="table table-hover table-striped">
                        <tr>
                            <th scope="col" class="border-top-0">#</th>
                            <!--<th scope="col" class="border-top-0">Items</th>-->
                            <th scope="col" class="border-top-0">Quantity</th>
                            <th scope="col" class="border-top-0">Price</th>
                            <th scope="col" class="border-top-0">Status</th>
                            <th scope="col" class="border-top-0">Date</th>
                            <th scope="col" class="border-top-0">Payment Method</th>
                            <th scope="col" class="border-top-0">Payment Status</th>

                            <th scope="col" class="border-top-0">Detail</th>

                        </tr>
                        <tr v-if="orders.length==0">
                            <td colspan="6">No Record found</td>
                        </tr>
                        <tbody>
                        <tr :class="{'table-danger':order.status==3,'table-success':order.status==5,'table-info':order.status==4}"
                            v-for="(order,index) in orders" v-bind:key="index">
                            <th>{{index + 1}}</th>
                            <!--<td>{{order.}}</td>-->
                            <td>{{order.quantity}}</td>
                            <td>{{order.price}}</td>
                            <td>{{readCode(order.status)}}</td>
                            <td>{{order.created_at}}</td>
                            <td>{{order.payment_method_name}}</td>
                            <td>{{order.paypal == null || order.paypal.payer_id == null ? 'Not Paid' : 'Paid'}}</td>
                            <td>
                                <router-link :to="{name:'order.detail',params:{'id':order.id,'nid':0}}"
                                             class="bg-transparent text-grey border-0 ">
                                    <icon icon="info" size="1x"></icon>
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/loader';
    import Codes from '@/order_codes';
    import {Paths} from '@/paths';
    import Detail from './CustomerOrderDetail.vue';
    export default{
        extends: Loader,
        components: {Detail},
        data(){

            return {
                orders: [],
                order: null,
            }
        },
        methods: {

            currentData(order){
                this.order = order;
            },
            fetchOrders(){
                let app = this;
                this.startLoading();
                this.axios.get(Paths.order.customer_orders).then((response) => {
                    app.orders = response.data;
                }).finally(() => {
                    app.endLoading()
                });
            },
            readCode(code){
                return Codes.readCode(code);
            }
        },
        mounted(){
            this.fetchOrders();

        }
    }
</script>