<template>
    <div class="container-fluid top-margin">
        <div class="row pl-3 pr-3 mb-5">
            <div class="col-sm-6 col-md-5 mx-auto bg-light shadow text-left p-4">

                <div class="font-weight-bold "><p class="heading">Register
                </p></div>
                <hr/>
                <div v-if="!register_request">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Company Name</label>
                                <input type="text" class="form-control" @change="updateAddress('company')"
                                       v-model="input.company">
                                <span class="text-danger">{{getError('company')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Phone</label>
                                <input type="text" class="form-control" v-model="input.phone"
                                       @change="updateAddress('phone')">
                                <span class="text-danger">{{getError('phone')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="email" class="form-control" @change="updateAddress('email')"
                                       v-model="input.email">
                                <span class="text-danger">{{getError('email')}}</span>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Address</label>
                                <input type="text" class="form-control" v-model="input.address">
                                <span class="text-danger">{{getError('address')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-sm-6">
                            <label>Company Type</label>
                            <select v-model="input.account_type" id="" class="form-control">
                                <option value="0">Select Account Type</option>
                                <option v-for="(account,index) in account_types" :key="index" :value="index">
                                    {{account}}
                                </option>
                            </select>
                            <span class="text-danger">{{getError('account_type')}}</span>
                        </div>
                        <div class="col-sm-6" v-if="showTI">
                            <label for="">EI Number</label>
                            <input type="text" class="form-control" v-model="input.ti_number">
                            <span class="text-danger">{{getError('ti_number')}}</span>
                        </div>
                        <div class="col-sm-6" v-if="showExempt">
                            <label for="">Exemption No</label>
                            <input type="text" class="form-control" v-model="input.exempt_number">
                            <span class="text-danger">{{getError('exempt_number')}}</span>
                        </div>

                    </div>
                    <p class="heading">Contact Person</p>
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">First Name</label>
                                <input type="text" class="form-control" v-model="input.first_name"
                                       @change="updateAddress('first_name')">
                                <span class="text-danger">{{getError('first_name')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Last Name</label>
                                <input type="text" class="form-control" v-model="input.last_name"
                                       @change="updateAddress('last_name')">
                                <span class="text-danger">{{getError('last_name')}}</span>
                            </div>
                        </div>
                    </div>

                    <p class="heading">Shipping Address</p>
                    <hr>

                    <shipping-address v-on:change="shippingAddressUpdated" :errors="errors"
                                      :address="input.shipping_address"></shipping-address>

                    <p class="heading">Billing Address</p>
                    <hr>
                    <div class="form-group">
                        <input type="checkbox" v-model="input.is_same_as_shipping"> &nbsp; Same as shipping address
                    </div>

                    <billing-address v-if="!input.is_same_as_shipping" :errors="errors"
                                     v-on:change="billingAddressUpdated"
                                     :address="input.billing_address"></billing-address>

                    <p class="heading">Account sign up</p>
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">User Name</label>
                                <input type="text" class="form-control" v-model="input.user_name">
                                <span class="text-danger">{{getError('user_name')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">

                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="password" class="form-control" v-model="input.password">
                                <span class="text-danger">{{getError('password')}}</span>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Confirm Password</label>
                                <input type="password" class="form-control" v-model="input.password_confirmation">
                                <span class="text-danger">{{getError('password_confirmation')}}</span>
                            </div>

                        </div>
                    </div>


                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>


                    <input type="button" @click="register" class="btn btn-outline-info btn-block btn-sm" value="REGISTER">

                </div>
                <div v-else="">
                    <div class="alert alert-success">
                        Your registration request has been received. Please login to check status of your account.
                        <br>
                        Thank You!
                    </div>
                    <router-link :to="{name:'login'}" class="btn btn-outline-info btn-block">Login</router-link>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Forms from '../../forms';
    import PulseLoader from "../../../node_modules/vue-spinner/src/PulseLoader";
    import {Paths} from '@/paths';

    import BillingAddress from '@/views/address/billing/Form';
    import ShippingAddress from '@/views/address/delivery/Form';
    export default{
        components: {PulseLoader, ShippingAddress, BillingAddress},
        extends: Forms,

        data(){
            return {


                account_types: {
                    1: 'Childcare center',
                    2: 'Restaurant',
                    3: 'Schools',
                    4: 'Non profits',
                    5: 'Families',
                },
                errors: [],
                input: {
                    first_name: '',
                    last_name: '',
                    user_name: '',
                    password: '',
                    password_confirmation: '',
                    email: '',
                    phone: '',
                    address: '',
                    company: '',
                    account_type: 0,

                    shipping_address: {
                        first_name: '',
                        last_name: '',
                        email_address: '',
                        phone: '',
                        state_id: '',
                        zip_code_id: '',
                        street_address1: '',
                        company: '',

                    },
                    is_same_as_shipping: true,
                    billing_address: {
                        first_name: '',
                        last_name: '',
                        email_address: '',
                        phone: '',
                        state_id: '',
                        zip_code_id: '',
                        street_address1: '',
                        company: '',

                    },

                },

                register_request: false,


            }
        },
        computed: {
            showTI: function () {
                if (this.input.account_type < 4 && this.input.account_type > 0) {
                    return true;
                }
                return false;
            },
            showExempt: function () {
                if (this.input.account_type == 4) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            addSuccess(){
                if (!this.register_request) {
                    return;
                }
                this.register_request = true;
                this.closeDialog();
            },
            updateAddress(key)
            {
                let address_key = key;
                if (address_key == 'email') {
                    address_key = 'email_address';
                }
                if (this.input.shipping_address[address_key] !== undefined) {
                    if (this.input.shipping_address[address_key].length == 0) {
                        this.input.shipping_address[address_key] = this.input[key];
                    }
                }

                if (this.input.billing_address[address_key] !== undefined) {
                    if (this.input.billing_address[address_key].length == 0) {
                        this.input.billing_address[address_key] = this.input[key];
                    }
                }


            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;
                this.input = {
                    first_name: '',
                    last_name: '',
                    user_name: '',
                    password: '',
                    password_confirmation: '',
                    email: '',
                    phone: '',
                    address: '',
                    company: '',
                    shipping_address: {
                        first_name: '',
                        last_name: '',
                        email_address: '',
                        phone: '',
                        state_id: '',
                        zip_code_id: '',
                        street_address1: '',
                        company: '',
                    },
                    is_same_as_shipping: true,
                    billing_address: {
                        first_name: '',
                        last_name: '',
                        email_address: '',
                        phone: '',
                        state_id: '',
                        zip_code_id: '',
                        street_address1: '',
                        company: '',
                    }

                };
            },
            shippingAddressUpdated(data){
                this.input.shipping_address[data.key] = data.value;
            },
            billingAddressUpdated(data){
                this.input.billing_address[data.key] = data.value;
            },
            register(){
                let app = this;
                this.loading = true;
                this.axios.post(Paths.customer.register, app.input).then(() => {
                    app.register_request = true;

                }).catch((error) => {
                    app.setErrors(error.response);
                    app.errors = app.getErrors();
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });
            }
        },
        mounted(){
            this.success = true;
        }
    }
</script>