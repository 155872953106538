<template>

    <div class="form-group" v-if="show">
        <label for="">Name</label>
        <input @change="store" type="text" class="form-control"
               v-model="input.name"/>
        <span class="text-danger">{{getError('name')}}</span>
    </div>


</template>
<script>
    import Forms from '@/forms';
    import {Paths} from '@/paths';
    export default{
        extends: Forms,
        props: ['category_id', 'show'],
        data(){
            return {
                input: {
                    category_id: 0,
                    name: '',
                }
            }
        },
        methods: {
            store(){

                let app = this;
                app.axios.post(Paths.subcategory.create, app.input).then((response) => {
                    app.$emit('new_sub_cat', response.data);
                    app.input.name = '';
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {

                });
            },
        },
        watch: {
            category_id(){
                this.input.category_id = this.category_id;
            }
        },
        mounted(){
            this.input.category_id = this.category_id;

        }
    }
</script>