<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container ">


            <add :path="url.add" v-on:added="newRecordAdded"></add>
            <edit :path="url.update" :delivery_person="delivery_person" v-on:updated="recordUpdated"></edit>
            <detail :delivery_person="delivery_person"></detail>
            <div class="row">
                <div class="col-sm-12 bg-light shadow p-3 text-left">
                    <p class="heading">Delivery Men Records</p>
                    <hr>
                    <div class="text-center">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>


                    <div class="row">
                        <div class="col-12 ">
                            <input type="button" data-toggle="modal" data-target="#add_delivery_person"
                                   class="btn btn-outline-info shadow float-right mb-3" value="Create"/>
                        </div>
                    </div>
                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover  ">
                            <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Username</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Address</th>
                                <th scope="col">Route</th>
                                <th>Detail</th>
                                <th>Edit</th>
                                <th>Enable/Disable</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="deliverypersons.length==0">
                                <td colspan="9">No record found</td>
                            </tr>
                            <tr :class="{'table-success':changed_id==man.id,'table-danger':!man.is_active}"
                                v-for="(man,index) in deliverypersons" :key="index">
                                <th scope="row">{{index + 1}}</th>
                                <td>{{man.full_name}}</td>
                                <td>{{man.user_name}}</td>
                                <td>{{man.email}}</td>
                                <td>{{man.deliveryman.phone}}</td>
                                <td>{{man.deliveryman.address}}</td>
                                <td>{{man.deliveryman.route?man.deliveryman.route.name:'Not Assigned'}}</td>
                                <td>

                                    <button @click="currentData(man)" title="View Detail"
                                            class="bg-transparent border-0 text-grey"
                                            data-toggle="modal"
                                            data-target="#delivery_person_detail">
                                        <icon icon="info" size="1x" alt="Edit"></icon>
                                    </button>
                                </td>
                                <td>

                                    <button @click="currentData(man)" title="Edit"
                                            class="bg-transparent border-0 text-grey"
                                            data-toggle="modal"
                                            data-target="#edit_delivery_person">
                                        <icon icon="user-edit" size="1x"></icon>
                                    </button>
                                </td>
                                <td>

                                    <button @click="changeStatus(man)"
                                            class="bg-transparent border-0 text-grey">
                                        <icon icon="toggle-on" size="1x" v-if="man.is_active"></icon>
                                        <icon icon="toggle-off" size="1x" v-else=""></icon>

                                    </button>
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import Add from './Add.vue';
    import Detail from './Detail.vue';
    import Edit from './Edit.vue';
    import Loader from '../../loader'
    import {Paths} from '../../paths'

    export default{
        extends: Loader,
        components: {Add, Detail, Edit},
        data(){
            return {
                deliverypersons: [],
                url: {
                    add: '',
                    update: '',
                },
                delivery_person: null,

            }
        },
        methods: {
            currentData(data){
                this.delivery_person = data;

            },
            newRecordAdded(){
                this.fetchData();
                this.showSuccessMessage('Delivery person added successfully!');
            },
            recordUpdated(){
                this.fetchData();
                this.showSuccessMessageWithID('Delivery person updated successfully!', this.delivery_person.id);
            },
            changeStatus(man){
                let app = this;
                this.axios.post(Paths.user.status, {'id': man.id}).then(() => {
                    man.is_active = !man.is_active;
                    if (man.is_active) {
                        app.showSuccessMessageWithID('Delivery person account enabled', man.id);
                    } else {
                        app.showSuccessMessageWithID('Delivery person account disabled', man.id);
                    }
                }).catch(() => {
                });

            },
            fetchData(){
                let app = this;
                app.loading = true;
                this.axios.get(Paths.deliveryperson.index).then((response) => {
                    app.deliverypersons = response.data;
                }).finally(() => {
                    app.loading = false;
                })
            }
        },
        mounted()
        {
            this.url.add = Paths.deliveryperson.add;
            this.url.update = Paths.deliveryperson.update;
            this.fetchData();
        }
    }
</script>