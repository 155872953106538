<template>

    <div class="table-responsive">
        <table class="table table-hover ">
            <thead class="">
            <tr>
                <th scope="col" class="border-top-0">#</th>
                <th scope="col" class="border-top-0">Name</th>
                <th scope="col" class="border-top-0">Username</th>
                <th scope="col" class="border-top-0">Email</th>
                <th scope="col" class="border-top-0">Phone</th>
                <th scope="col" class="border-top-0">Address</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="registrations.length==0">
                <td colspan="6">No record found</td>
            </tr>
            <tr
                    v-for="(registration,index) in registrations" :key="index">
                <th scope="row">{{index + 1}}</th>
                <td>{{registration.customer.user.full_name}}</td>
                <td>{{registration.customer.user.user_name}}</td>
                <td>{{registration.customer.user.email}}</td>
                <td>{{registration.customer.phone}}</td>
                <td>{{registration.customer.address}}</td>


            </tr>

            </tbody>
        </table>
    </div>
</template>
<script>


    import Forms from '@/forms'
    import {Paths} from '@/paths'

    export default{
        extends: Forms,
        data(){
            return {
                registrations: [],
            }
        },
        methods: {

            fetchData(){
                let app = this;
                app.loading = true;
                this.axios.get(Paths.registrations.new).then((response) => {
                    app.registrations = response.data;
                    app.$emit('loaded', response.data);
                }).finally(() => {
                    app.loading = false;
                })
            },

        },
        mounted()
        {
            this.fetchData();
        }
    }
</script>