<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container">

            <div class="row">
                <div class="col-12 bg-light shadow p-3 mb-3 ">
                    <p class="heading">Filters</p>
                    <hr/>
                    <keyword-filter :url="url.filter" :search_types="{1:'First Name',2:'Email','3':'Phone'}"
                                    v-on:filtered="setFilteredData" v-on:clear="fetchData"></keyword-filter>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 bg-light shadow p-3 text-left ">
                    <p class="heading">Customer Records</p>
                    <hr>
                    <div class="text-center">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>


                    <div class="row">
                        <div class="col-12 ">
                            <router-link :to="{name:'customer.add'}" class="btn btn-outline-info float-right mb-3 shadow">Create </router-link>

                        </div>
                    </div>
                    <transition name="fade">
                        <div class="row" v-if="success">
                            <div class="col-12">
                                <div class="alert alert-success">{{message}}</div>
                            </div>
                        </div>
                    </transition>
                    <div class="table-responsive">
                        <table class="table table-hover ">
                            <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Company</th>
                                <th scope="col">Contact Name</th>
                                <th scope="col">Username</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Address</th>
                                <th scope="col">Max Order Limit</th>
                                <th scope="col">Payment Interval</th>
                                <th>Detail</th>
                                <th>Edit</th>
                                <th>Enable/Disable</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="customers.length==0">
                                <td colspan="15">No record found</td>
                            </tr>
                            <tr :class="{'table-success':changed_id==man.id,'table-danger':!man.is_active}"
                                v-for="(man,index) in customers" :key="index">
                                <th scope="row">{{index + 1}}</th>
                                <td>{{man.customer.company}}</td>
                                <td>{{man.customer.contact_name}}</td>
                                <td>{{man.user_name}}</td>
                                <td>{{man.email}}</td>
                                <td>{{man.customer.phone}}</td>
                                <td>{{man.customer.address}}</td>
                                <td>
                                    <max-payment v-on:updated="man.customer.max_payment_limit=$event"
                                                 :customer_id="man.customer.id"
                                                 :max_payment_limit="man.customer.max_payment_limit"></max-payment>
                                </td>
                                <td>
                                    <payment-interval :customer_id="man.customer.id"
                                                      v-on:updated="man.customer.payment_interval=$event"
                                                      :payment_interval="man.customer.payment_interval"></payment-interval>
                                </td>
                                <td>

                                    <router-link class="text-grey" :to="{name: 'customer.detail',params:{user_id:man.id}}">
                                        <icon icon="info" size="1x" alt="Edit"></icon>
                                    </router-link>

                                </td>
                                <td>

                                    <button @click="currentData(man)" title="Edit"
                                            class="bg-transparent border-0 text-grey"
                                            data-toggle="modal"

                                            data-target="#edit_customer">
                                        <icon icon="user-edit" size="1x"></icon>
                                    </button>
                                </td>
                                <td>

                                    <button @click="changeStatus(man)" class="bg-transparent border-0 text-grey">
                                        <icon icon="toggle-on" size="1x" v-if="man.is_active"></icon>
                                        <icon icon="toggle-off" size="1x" v-else=""></icon>

                                    </button>
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

//    import Edit from './Edit.vue';
    import Loader from '../../loader';
    import {Paths} from '../../paths';
    import KeywordFilter from '@/views/components/filter/Keyword.vue';
    import MaxPayment from '@/views/customer/payment/MaxPayment';
    import PaymentInterval from '@/views/customer/payment/PaymentInterval';

    export default{
        extends: Loader,
        components: {  KeywordFilter, MaxPayment, PaymentInterval},
        data(){
            return {
                customers: [],
                url: {
                    add: '',
                    update: '',
                    filter: '',
                },
                customer: null,

            }
        },
        methods: {
            currentData(data){
                this.customer = data;

            },
            newRecordAdded(){
                this.fetchData();
                this.showSuccessMessage('Customer added successfully!');
            },
            recordUpdated(){
                this.fetchData();
                this.showSuccessMessageWithID('Customer updated successfully!', this.customer.id);
            },
            changeStatus(man){
                let app = this;
                this.axios.post(Paths.user.status, {'id': man.id}).then(() => {
                    man.is_active = !man.is_active;
                    if (man.is_active) {
                        app.showSuccessMessageWithID('Customer account enabled', man.id);
                    } else {
                        app.showSuccessMessageWithID('Customer account disabled', man.id);
                    }


                }).catch(() => {
                });

            },
            setFilteredData(data){
                this.customers = data;
            },
            fetchData(){
                let app = this;
                app.loading = true;
                this.axios.get(Paths.customer.index).then((response) => {
                    app.customers = response.data;
                }).finally(() => {
                    app.loading = false;
                })
            }
        },
        mounted()
        {
            this.url.add = Paths.customer.add;
            this.url.update = Paths.customer.update;
            this.url.filter = Paths.customer.filters;
            this.fetchData();
        }
    }
</script>