<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <axios :url="orders_url" :data="{date:date,range:1}" :trigger="load_orders" v-on:success="ordersLoaded"></axios>
        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <p class="heading">Today's Orders : <strong>{{date}}</strong></p>
                <hr/>

                <div class="row">
                    <div class="col-sm-6 col-md-3 " v-for="order in orders" :key="order.id">
                        <Order :order="order" v-on:start="started" v-on:complete="completed"></Order>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/loader';

    import {Paths} from '@/paths';
    import Axios from '@/views/actions/Axios';
    import Order from '@/views/order/warehouse/Order.vue';
    export default{
        extends: Loader,

        components: {Axios, Order},
        data(){

            return {
                orders: [],
//                order: null,
                orders_url: '',
                load_orders: false,
                date: '12/28/2019',
            }
        },
        methods: {
            ordersLoaded(response){
                this.orders = response.data.orders;
                this.load_orders = false;
            },
            started(preparation){
                let index = this._.findIndex(this.orders, {'id': parseInt(preparation.order_id)});
                if (index < 0) {
                    return;
                }

                console.log('Setting preparation');
                this.orders[index].preparation = preparation;

            },
            completed(preparation){
                let index = this._.findIndex(this.orders, {'id': parseInt(preparation.order_id)});
                if (index < 0) {
                    return;
                }

                this.orders[index].preparation = preparation;
            }
        },
        mounted(){

            this.orders_url = Paths.order.search.date;
            this.date=(new Date()).toISOString().split('T')[0];
            this.load_orders = true;

        }
    }

</script>