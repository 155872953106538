<template>
    <div class="row">
        <div class="col-12">
            <table class="table table-bordered">
                <tr>
                    <td>Total Orders</td>
                    <td>{{orders.length}}</td>
                    <td>Completed</td>
                    <td>{{completed}}</td>
                    <td>Pending</td>
                    <td>{{pending}}</td>
                </tr>
                <tr>
                    <td>Approved</td>
                    <td>{{approved}}</td>
                    <td>Rejected</td>
                    <td>{{rejected}}</td>
                    <td>Assigned</td>
                    <td>{{assigned}}</td>

                </tr>
                <tr>
                    <td>Total Cost</td>
                    <td>{{total_cost | currency}}</td>
                    <td>Total Earned</td>
                    <td>{{total_earned | currency}}</td>
                    <td>Total items</td>
                    <td>{{total_items}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import Codes from '@/order_codes';
    export default{
        props: ['orders'],
        data(){
            return {
                total: 0,
                approved: 0,
                pending: 0,
                rejected: 0,
                assigned: 0,
                completed: 0,
                total_cost: 0,
                total_items: 0,
                total_earned: 0,
            }
        },
        methods: {
            clearCount(){
                this.total = 0;
                this.approved = 0;
                this.pending = 0;
                this.rejected = 0;
                this.assigned = 0;
                this.completed = 0;
                this.total_cost = 0;
                this.total_items = 0;
                this.total_earned = 0;
            },
            orderStats(order){
                this.total_cost += parseFloat(order.price);
                this.total_items += parseInt(order.quantity);
                this.total_earned += parseFloat(order.price) - parseFloat(order.cost);

                if (order.status == Codes.forPending()) {
                    this.pending++;
                    return;
                }
                if (order.status == Codes.forApproved()) {

                    this.approved++;
                    return;
                }
                if (order.status == Codes.forDelivered()) {
                    this.total_earned += order.price;
                    this.completed++;
                    return;
                }
                if (order.status == Codes.forRejected()) {
                    this.rejected++;
                    return;
                }
                if (order.status == Codes.forAssigned()) {
                    this.assigned++;
                    return;
                }
            }
        },
        watch: {
            orders: function (orders) {
                this.clearCount();
                orders.forEach(this.orderStats);
            }
        }
    }
</script>