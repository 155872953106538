<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <edit :path="url.edit" v-on:updated="showSuccessMessage('Profile updated successfully');$auth.fetch()"></edit>
        <password :path="url.password"
                  v-on:updated="showSuccessMessage('Password updated successfully');"></password>
        <div class="row">
            <div class="col-sm-12 bg-light shadow p-3 text-left ">
                <p class="heading">Sales man profile</p>
                <hr>
                <div class="row">

                    <div class="col-sm-6 table-responsive">
                        <div class="alert alert-success" v-if="success">{{message}}</div>
                        <table class="table table-striped">
                            <tr>
                                <td class="border-top-0">Name</td>
                                <td class="border-top-0">{{$auth.user().full_name}}</td>
                            </tr>
                            <tr>
                                <td>Username</td>
                                <td>{{$auth.user().user_name}}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{{$auth.user().email}}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{{$auth.user().salesman.phone}}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{$auth.user().salesman.address}}</td>
                            </tr>
                        </table>
                        <input type="button" data-toggle="modal" data-target="#update_admin"
                               class="btn btn-outline-info  btn-sm  mb-3 shadow mr-2"
                               value="Change Details"/>

                        <input type="button" data-toggle="modal"
                               data-target="#change_password"
                               class="btn btn-outline-info  btn-sm  mb-3 shadow"
                               value="Change Password"/>
                    </div>
                </div>

            </div>
        </div>
    </div>


</template>
<script>

    import Edit from './ChangeInfo.vue';
    import Password from './ChangePassword.vue';
    import Loader from  '@/loader';
    import {Paths} from '@/paths';
    export default{
        extends: Loader,
        components: {Edit, Password},
        data(){
            return {
                url: {
                    edit: '',
                    password: '',
                }
            }
        }, methods: {},
        mounted(){
            this.url.edit = Paths.salesman.update_profile;
            this.url.password = Paths.api.change_password;
        }
    }
</script>