<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <div class="modal" id="order_detail" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Order Detail</h4>
                        <button ref="close_detail" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-left">
                        <detail :order="order"
                                v-on:completed="completed"></detail>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <p class="heading">Today's Orders  : {{date}}</p>
                <hr/>
                <div class="table-responsive">
                    <table class="table table-hover table-striped">
                        <tr>
                            <th scope="col" class="border-top-0">Order#</th>
                            <!--<th scope="col" class="border-top-0">Items</th>-->
                            <th scope="col" class="border-top-0">Quantity</th>
                            <th scope="col" class="border-top-0">Price</th>
                            <th scope="col" class="border-top-0">Status</th>
                            <th scope="col" class="border-top-0">Date</th>
                            <th scope="col" class="border-top-0">Detail</th>

                        </tr>
                        <tr v-if="orders.length==0">
                            <td colspan="6">No Record found</td>
                        </tr>
                        <tbody>
                        <tr v-for="(order,index) in orders" v-bind:key="index" :class="{'bg-success':order.status==5}">
                            <th>{{order.id}}</th>
                            <!--<td>{{order.}}</td>-->
                            <td>{{order.quantity}}</td>
                            <td>$ {{order.price}}</td>
                            <td>{{readCode(order.status)}}</td>
                            <td>{{order.created_at}}</td>
                            <td>
                                <button @click="currentData(order)" class="bg-transparent text-grey border-0 "
                                        data-toggle="modal"
                                        data-target="#order_detail">
                                    <icon icon="info" size="1x"></icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/loader';
    import Codes from '@/order_codes';
    import {Paths} from '@/paths';

    import Detail from '../salesman/OrderDetail.vue';
    export default{
        extends: Loader,
        components: {Detail},
        data(){

            return {
                orders: [],
                order: null,
                date: '12/28/2019',
            }
        },
        methods: {
            completed(){
                this.order.status = 5;
                this.fetchOrders();
                this.$refs['close_detail'].click();

            },
            rejected(){
                this.order.status = Codes.forRejected();
                this.fetchOrders();
            },
            approved(){
                this.order.status = Codes.forApproved();
                this.fetchOrders();
            },
            currentData(order){
                this.order = order;
            },
            fetchOrders(){
                let app = this;
                this.startLoading();
                this.axios.post(Paths.order.delivery_person.assigned, {'date': app.date}).then((response) => {
                    app.orders = response.data;
                }).finally(() => {
                    app.endLoading()
                });
            },
            readCode(code){
                return Codes.readCode(code);
            }
        },
        mounted(){

            this.date = this.$store.getters.current_date;
            this.fetchOrders();
        }
    }
</script>

<style type="text/css">
    .bg-success td {
        color: white !important;
    }
</style>