<template>
    <div class="row">
        <div class="col-12 " v-for="(message,index) in messages" v-bind:key="index">
            <message :message="message"></message>
        </div>
        <div class="col-12" v-if="messages.length==0">
            <div class="alert alert-danger">
                <p class="mb-0">Messages list is empty</p>
            </div>
        </div>
    </div>
</template>
<script>
    import Message from './Message.vue';
    export default{
        props: ['messages'],
        components: {Message},
        methods(){
        }
    }
</script>
