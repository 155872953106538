<template>
    <select class="form-control form-control-sm w-150" v-model="type" @change="$emit('changed',type);">
        <option value="0">All Orders</option>
        <option value="1">New Orders</option>
        <option value="4">Assigned to delivery agent</option>
        <option value="2">Approved</option>
        <option value="3">Rejected</option>
        <option value="5">Delivered</option>
        <!--<option value="6">Assigned to warehouse staff</option>-->
        <option value="7">Cancelled</option>

    </select>

</template>
<script>
    export default{
        props: {'order_type': {default: 0}},
        data(){
            return {
                type: 0,
            }
        },
        mounted(){
            this.type = this.order_type;
        }

    }
</script>