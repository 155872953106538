<template>
    <div>
        <Axios :url="start_path" :trigger="trigger" :data="{order_id:order.id,status:status}"
               v-on:success="success"></Axios>
        <input  v-if="status<2" type="button" @click="start" :disabled="status>0" class="btn btn-sm btn-wide btn-outline-info"
               value="Start">
        <input  v-if="status<2" type="button" :disabled="status==0" @click="complete"
               class="btn btn-sm btn-wide btn-outline-info pull-right" value="Complete">
    </div>
</template>
<script>
    import Axios from '@/views/actions/Axios.vue';
    import {Paths} from '@/paths.js';
    export default{
        props: ['order'],
        components: {Axios},
        data(){
            return {
                start_path: '',
                trigger: false,
                status: 0,

            }
        },
        methods: {
            start(){
                this.status = 1;
                this.trigger = true;

            },
            complete(){
                this.status = 2;
                this.trigger = true;


            },
            success(response){
                this.trigger = false;
                if (this.status == 1) {
                    this.$emit('start', response.data);
                    return;
                }

                this.$emit('complete', response.data);
            }
        },
        mounted(){
            this.start_path = Paths.order.preparation.update;
            this.status = this.order.preparation ? this.order.preparation.status : 0;
        }
    }
</script>