<template>
    <span>{{last_order==null?'None':last_order.created_at}}</span>
</template>

<script>
    import {Paths} from '@/paths';
    export default{
        props:['customer_id'],
        data(){
            return{
                last_order:null,
            }
        },
        methods:{

            load_last_order(customer_user_id){
                let app=this;

                this.axios.post(Paths.customer.last_order,{'user_id':customer_user_id}).then(response=>{

                    app.last_order=response.data;

                });
            }
        },
        watch:{
            customer_id:function (val) {
                if(val==0){
                    this.last_order=null;
                    return;
                }

                this.load_last_order(val);

            },
        }
    }
</script>