/**
 * Created by Asad on 12/27/2019.
 */

export default {
    methods: {
        list(){
            // let app = this;
            // this.axios.post('')
        }
    }
}