<template>
    <select v-model="route" @change="change" id="" class="form-control">
        <option value="0">Select</option>
        <option v-for="route in routes" :value="route.id" :key="route.id">{{route.name}}</option>
    </select>
</template>
<script>
    import {Paths} from '@/paths';

    export default{
        props: ['route_id'],

        data(){
            return {
                route: 0,
                routes: [],
            }
        },
        methods: {
            change(){
                this.$emit('changed', this.route);
            },
            loadRoutes(){
                let app = this;
                this.axios.get(Paths.routes.index).then((response) => {
                    app.routes = response.data;
                });
            }
        },
        watch: {
            route_id(val){
                this.route = val;
            }
        },
        mounted(){
            this.route = this.route_id;
            this.loadRoutes();
        }

    }
</script>