<template>
        <header class="site-navbar site-navbar-target bg-white" role="banner">

        <div class="container">
            <div class="row align-items-center position-relative">

                <div class="col-lg-4">
                    <nav class="site-navigation text-right ml-auto " role="navigation">
                        <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block" v-if="!$auth.check()">
                            <!--<li class="active"><a href="index.html" class="nav-link">Home</a></li>-->
                            <li v-for="(route,index ) in guest" v-bind:key="index">
                                <router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>

                            </li>
                            <li><a href="services.html" class="nav-link">Services</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-4 text-center">
                    <div class="site-logo">
                        <a href="index.html">Marga</a>
                    </div>


                    <div class="ml-auto toggle-button d-inline-block d-lg-none">
                        <a href="#" class="site-menu-toggle py-5 js-menu-toggle text-white">
                            <!--<span class="glyphicon glypicon-list h3 text-primary"></span>-->
                            asad
                        </a></div>
                </div>
                <div class="col-lg-4">
                    <nav class="site-navigation text-left mr-auto " role="navigation">
                        <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                            <li><a href="about.html" class="nav-link">About</a></li>
                            <li><a href="blog.html" class="nav-link">Blog</a></li>
                            <li><a href="contact.html" class="nav-link">Contact</a></li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>

    </header>
</template>
<script>
    export default{
        data(){
            return {
                guest: [
                    {name: 'Home', path: 'index'},
                    {name: 'Login', path: 'login'}
                ],
                admin: {
                    right: [
                        {name: 'Home', path: 'index'},
                        {name: 'Dashboard', path: 'dashboard'},
                        {name: 'Sales Man', path: 'salesman'},
                    ]
                },
            }
        }
    }
</script>