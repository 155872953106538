<template>
    <div class="row" v-if="registration!=null">
        <div class="col-sm-12">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <td class="border-top-0">Name</td>
                        <td class="border-top-0">{{registration.customer.full_name}}</td>
                    </tr>

                    <tr>
                        <td>Email</td>
                        <td>{{registration.customer.email}}</td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>
                            {{registration.customer.customer.phone == null ? 'Not Provided' : registration.customer.customer.phone}}
                        </td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>
                            {{registration.customer.customer.address == null ? 'Not Provided' : registration.customer.customer.address}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="col-sm-12"
             v-if="registration.status==5 || registration.status<3 ">
            <hr/>
            <p class="heading mt-3">Approve</p>

            <div class="row">
                <div class="col-sm-5">
                    <input type="button" @click="approve()"
                           class="btn btn-outline-info btn-sm mr-2 mb-2" value="Approve">
                </div>

                <div class="col-12">
                    <hr/>
                    <p class="heading mt-2">Reject</p>

                    <reject-comp v-if="actionAllowed" v-on:rejected="rejected"
                                 :registration_id="registration.id"></reject-comp>
                </div>
            </div>


        </div>
        <div class="col-12" v-else-if="registration.status==3">Request status: <span
                class="text-success">Approved</span></div>
        <div class="col-12" v-else-if="registration.status==4">
            Application rejected because of following reason : <i
                class="text-danger">{{registration.reason}}</i>
        </div>

    </div>
</template>
<script>

    import forms from '@/forms';
    import {Paths} from '@/paths';
    import RejectComp from '@/components/customer/Reject';
    export default{
        extends: forms,
        props: ['registration'],
        components: {RejectComp},
        data(){
            return {
                salesmen: [],
                user_id: 0,
            }
        }, methods: {
            findAssignedSalesMan(){
                let app = this;
                for (let i = 0; i < this.salesmen.length; i++) {
                    if (app.salesmen[i].id == app.user_id) {
                        return app.salesmen[i];
                    }
                }
                return null;
            },
            assigned(){

                this.$emit('assigned', this.findAssignedSalesMan());
            },

            rejected(data){
                this.$emit('rejected', data);
            },
            loadSalesman(){
                let app = this;

                this.startRequest();
                this.axios.get(Paths.salesman.index).then((response) => {
                    app.salesmen = response.data;
                }).catch(() => {
                }).finally(() => {
                    app.endRequest();
                });
            },
            assign(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.assign, {
                    'user_id': this.user_id,
                    'registration_id': this.registration.id
                }).then(() => {
                    app.assigned();
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest()
                });
            },
            approve(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.approve, {
                    'registration_id': this.registration.id
                }).then(() => {
                    this.$emit('approved');
                }).finally(() => {
                    app.endRequest()
                });
            },
            actionAllowed(){
                if (this.registration.status < 3 || this.registration.status == 5) {
                    return true;
                }
                return false;
            },
            reject(){

            }
        },
        mounted(){
            this.loadSalesman();
        }
    }
</script>