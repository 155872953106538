<template>
    <div class="form-check">
        <axios :trigger="trigger" type="post" v-on:success="done" v-on:error="done" :data="{inventory_id:inventory_id}"
               :url="url"></axios>
        <input @click="trigger=true;" class="form-check-input" type="checkbox" v-model="is_checked">
        <label class="form-check-label">
            <!--Is Popular-->
        </label>
    </div>
</template>
<script>

    // axios component url, trigger, data, on-success, on-error
    import Axios from '@/views/actions/Axios';
    import {Paths} from '@/paths';
    export default{
        props: ['inventory_id', 'checked'],
        components: {Axios},
        data(){
            return {
                trigger: false,
                url: '',
                is_checked: false,
            }
        },
        methods: {
            done(){
                this.trigger = false;
            },
            error(){
                this.trigger = false;
            },
        },
        watch:{
            inventory_id(val){
                this.is_checked=val;
            }
        },mounted(){
            this.url = Paths.inventory.famous;
            this.is_checked = this.checked;
        }
    }
</script>