<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <detail :order="order"></detail>
        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <p class="heading">Orders</p>
                <hr/>
                <div class="table-responsive">
                    <table class="table table-hover table-striped">
                        <tr>
                            <th scope="col" class="border-top-0">#</th>
                            <!--<th scope="col" class="border-top-0">Items</th>-->
                            <th scope="col" class="border-top-0">Quantity</th>
                            <th scope="col" class="border-top-0">Price</th>
                            <th scope="col" class="border-top-0">Status</th>
                            <th scope="col" class="border-top-0">Date</th>
                            <th scope="col" class="border-top-0">Detail</th>

                        </tr>
                        <tr v-if="orders.length==0">
                            <td colspan="6">No Record found</td>
                        </tr>
                        <tbody>
                        <tr v-for="(order,index) in orders" v-bind:key="index">
                            <th>{{index + 1}}</th>
                            <!--<td>{{order.}}</td>-->
                            <td>{{order.quantity}}</td>
                            <td>{{order.price}}</td>
                            <td>{{readCode(order.status)}}</td>
                            <td>{{order.created_at}}</td>
                            <td>
                                <button @click="currentData(order)" class="bg-transparent text-grey border-0 "
                                        data-toggle="modal"
                                        data-target="#order_detail">
                                    <icon icon="info" size="1x"></icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/loader';
    import Codes from '@/order_codes';
    import {Paths} from '@/paths';
    import Detail from './CustomerOrderDetail.vue';
    export default{
        extends: Loader,
        components: {Detail},
        data(){

            return {
                orders: [],
                order: null,
            }
        },
        methods: {

            currentData(order){
                this.order = order;
            },
            fetchOrders(){
                let app = this;
                this.startLoading();
                this.axios.get(Paths.order.customer_orders).then((response) => {
                    app.orders = response.data;
                }).finally(() => {
                    app.endLoading()
                });
            },
            readCode(code){
                return Codes.readCode(code);
            }
        },
        mounted(){
            this.fetchOrders();

        }
    }
</script>