<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="">Reason for rejection</label>
                <textarea class="form-control" v-model="reason"></textarea>
                <span class="text-danger">{{getError('reason')}}</span>
            </div>
            <input type="button" :disabled="loading" @click="reject()" class="btn btn-danger btn-sm mb-2"
                   value="Reject">
        </div>
    </div>
</template>

<script type="text/javascript">
    import {Paths} from '@/paths'
    import Forms from '@/forms'
    export default{
        extends: Forms,
        props: ['registration_id'],
        data(){
            return {
                reason: '',
            }
        },
        methods: {
            reject(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.registrations.reject, {
                    registration_id: app.registration_id,
                    reason: app.reason
                }).then(() => {
                    app.$emit('rejected', app.reason);

                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => {
                    app.endRequest();
                });
            }
        }
    }
</script>