<template>
    <button v-if="item!=null" @click="delete_item()" class="btn btn-outline-info custom-btn mr-1">Delete
    </button>
</template>
<script>
    import {Paths} from '@/paths';
    export default{
        props: ['item'],
        data(){
            return {}
        },
        methods: {
            delete_item(){
                let app = this;
                this.axios.post(Paths.order_items.delete, {
                    'order_id': app.item.order_id,
                    'item_id': app.item.item_id
                }).then(() => {
                }).finally(() => {
                    app.$emit('item_changed');
                });
            }
        },
        mounted(){

        }
    }
</script>