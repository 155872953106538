<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <div class="row">
            <div class="col-sm-12 mb-3">
                <div class="card">
                    <div class="card-body">
                        <p class="heading">Payments</p>
                        <hr>

                        <div class="row">
                            <div class="col-sm-12 mb-3">
                                <div class="row ">
                                    <div class="col-12   p-3  text-left">
                                        <p class="heading">Search By Date</p>
                                        <hr/>
                                        <date-filter  :refresh="refresh_date" :url="url.date_search"
                                                     :types="{0:'Last 30 days',1:'Daily',2:'Weekly',3:'Monthly'}"
                                                     v-on:searched="searchedByDate"></date-filter>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <router-link :to="{'name':'orders'}"  class="btn btn-outline-info btn-sm mr-2 ">Orders</router-link>
                                <router-link :to="{'name':'orders.create'}"  class="btn btn-outline-info btn-sm mr-2 ">New Order</router-link>
                                <router-link :to="{'name':'payment.add'}"  class="btn btn-outline-info btn-sm ">Add Payment</router-link>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <p class="heading">Orders <span v-if="type==1">on</span><span v-else>between</span> <i
                                        class="text-success">{{date_range}}</i></p>

                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <tr>
                                            <th>Order No</th>
                                            <th>Company</th>
                                            <th>Total Items</th>
                                            <th>Total Cost</th>
                                            <th>Total Price</th>
                                            <th>Profit%</th>
                                            <th>Payment Status</th>
                                        </tr>
                                        <tr v-for="(order,index) in orders" v-bind:key="index">
                                            <td>
                                                {{order.id}}
                                            </td>
                                            <td>{{order.customer.customer.company}}</td>
                                            <td>{{order.quantity}}</td>
                                            <td>{{order.cost|currency}}</td>
                                            <td>{{order.price|currency}}</td>
                                            <td>{{order.cost==0?1:((order.price-order.cost)/order.cost)|percent}}</td>
                                            <td>{{order.payment_status}}</td>
                                        </tr>
                                        <tr v-if="orders.length==0">
                                            <td class="text-danger" colspan="20">No Order found</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateFilter from '@/views/components/filter/Date.vue';
    import {Paths} from '@/paths';
    export default{
        components: {DateFilter},
        data(){
            return {
                orders: [],
                type: 1,
                customer_id: 0,
                total_orders: 0,

                url: {
                    date_search: '',
                },
                date_range: '',
                refresh_date: false,
            }
        },
        methods: {
            is_paid(index){
                let payments = this.orders[index].payments;
                console.log(payments);
                if(payments.length==0){
                    return 'Unpaid';
                }




                for(let i=0;i<payments.length;i++){
                    if(payments[i].is_partial==false){
                        return "Paid";
                    }

                }

                return "Partial Paid";
            },
            searchedByDate(data){
                this.orders = data.orders;
                this.type = 1;
                if (data.start_date == data.end_date) {
                    this.date_range = data.start_date;
                    return;
                }
                this.date_range = data.start_date + ' to ' + data.end_date;

            },

            fetchOrders(){
                console.log(this.type);
                this.refresh_date = !this.refresh_date;
            },

        },
        mounted(){
            this.url.date_search = Paths.order.with_payment;
            this.refresh_date = !this.refresh_date;
        }
    }
</script>