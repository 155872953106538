<template>
    <div class="row">
        <div class="col-sm-12" v-if="loading">
            <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div class="col-sm-12" v-else>


            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="exampleModalLabel">Change Items</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <editOrderItem v-on:item_changed="items_changed()"
                                           :order_item="selected_item"></editOrderItem>
                            <button type="button" class="btn btn-secondary" ref="close_popup" data-dismiss="modal"
                                    style="display: none;">Close
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <DeleteOrderItem :item="this.selected_item" v-on:item_changed="items_deleted()"></DeleteOrderItem>

            <button v-if="selected_item!=null" data-toggle="modal" data-target="#exampleModal"
                    class="btn btn-outline-info custom-btn">Edit
            </button>

            <div class="table-responsive custom-table mt-2 ">

                <table class="table table-hover table-striped">
                    <thead>
                    <tr>
                        <td style="min-width: 200px;">Name</td>
                        <td>Price</td>
                        <td>Qty</td>
                        <td>Free</td>
                        <!--<td style="min-width: 70px;">Free</td>-->
                        <!--<td>Total Qty</td>-->
                        <td>Amount</td>
                        <!--<td>Tax</td>-->
                        <td>Total Amount</td>
                        <td>Order Cost</td>
                        <td>Profit</td>
                        <td>Profit %</td>
                        <!--<td>Action</td>-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in items" v-bind:key="index" @click="setItem(index)" class="clickable"
                        :class="{'bg-info text-light':(selected_item!=null &&item.id==selected_item.id)}">

                        <td>
                            {{item.item_name}}
                        </td>
                        <td>
                            {{item.price | currency}}
                        </td>
                        <td>
                            {{item.quantity | currency}}
                        </td>
                        <td>
                            {{item.free}} {{item.item.unit}}
                        </td>
                        <!--<td>-->
                        <!--{{item.free}} {{item.base_unit}}-->
                        <!--</td>-->
                        <!--<td>{{item.total_quantity}} {{item.base_unit}}</td>-->
                        <td>{{(item.cost) | currency}}</td>
                        <!--<td>{{item.tax | currency}}</td>-->
                        <td>{{item.cost * item.quantity | currency}}</td>
                        <td>{{item.price * item.quantity | currency}}</td>
                        <td>{{(item.price * item.quantity) - (item.cost * item.quantity) | currency}}</td>
                        <td>
                            {{(item.cost) == 0 ? '0' : (((item.price * item.quantity) - (item.cost * item.quantity)) / (item.cost * item.quantity)) * 100 | number('0.0')}}%
                        </td>

                        <!--<td>-->
                        <!--<button @click="edit_item(index)" class="btn btn-outline-info">Edit-->
                        <!--</button>-->
                        <!--</td>-->
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>


<script>
    import {Paths} from '@/paths';
    import EditOrderItem from '@/views/order_item/Edit.vue';
    import DeleteOrderItem from '@/views/order_item/Delete.vue';
    export default{
        props: ['order_id'],
        components: {EditOrderItem, DeleteOrderItem},
        data(){
            return {

                items: [],
                loading: true,
                selected_item: null,
                selected_item_index: -1,
            }
        },
        watch: {
            order_id: function () {
                this.selected_item = null;
                this.loadItems();
            }
        },
        methods: {
            setItem(index){
                this.selected_item = this.items[index];
                this.selected_item_index = index;
            },
            loadItems(){
                let app = this;

                app.loading = true;

                app.setItem(null);

                this.axios.post(Paths.order_items.items, {'order_id': app.order_id}).then((response) => {
                    app.items = response.data;

                }).finally(() => {
                    app.loading = false;
                })


            },
            items_changed(){
                this.$emit('items_changed');
                this.$refs['close_popup'].click();
            },
            items_deleted(){
                if (this.selected_item_index == -1) {
                    return;
                }
                this.items.splice(this.selected_item_index, 1);
                this.$emit('items_changed');
            }
        },
        mounted(){
            this.loadItems();
        }
    }
</script>