<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row">
            <div class="col-12 shadow bg-white pt-3">
                <p class="heading">Sub Categories</p>
                <hr/>

                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label for="">Select Category</label>
                            <select v-model="category_id" class="form-control">
                                <option value="0">Select Category</option>
                                <option v-for="category in categories" :value="category.id" :key="category.id">
                                    {{category.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                                <tr v-if="category_id==0 && sub_categories.length==0">
                                    <td colspan="3">Select category to see sub categories</td>
                                </tr>
                                <tr v-else-if="sub_categories.length==0">
                                    <td colspan="3">No sub category found</td>
                                </tr>
                                <tr v-for="(sc,index) in sub_categories" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td>{{sc.name}}</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <create></create>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Create from '@/views/subcategory/Create';
    export default{
        components: {Create},
        data(){
            return {
                categories: [],
                category_id: 0,
                sub_categories: [],
            }
        },
        methods: {
            loadCategories(){
                let app = this;
                app.axios.get(Paths.category.index).then((response) => {
                    app.categories = response.data;
                });
            },
            loadSubCategories(){
                let app = this;
                app.axios.post(Paths.subcategory.index, {category_id: app.category_id}).then((response) => {
                    app.sub_categories = response.data;
                });
            }
        },
        watch: {
            category_id(){
                this.loadSubCategories();
            }
        },
        mounted(){
            this.loadCategories();
        }
    }
</script>