<template>
    <div class="modal fade" id="customer_detail" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Customer Detail</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-left">

                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table table-striped" v-if="customer!=null">
                                <tr>
                                    <td>Name</td>
                                    <td>{{customer.full_name}}</td>
                                </tr>
                                <tr>
                                    <td>Username</td>
                                    <td>{{customer.user_name}}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{{customer.email}}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{{customer.customer_relation.phone}}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{{customer.customer_relation.address}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <button type="button" class="btn float-right custom-btn btn-sm btn-wide"
                                    data-dismiss="modal">Close
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>

    export default{
        props: ['customer'],
        data(){
            return {}
        }, methods: {},
        mounted(){
        }
    }
</script>