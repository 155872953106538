<template>
    <div class="row">
        <div class="col-sm-12 text-center">
            <pulse-loader :loading="loading"></pulse-loader>
            <div class="table-responsive custom-table" v-if="!loading">

                <table class="table text-left">
                    <tr>
                        <th>Order ID</th>
                        <td>{{summary.invoice_number}}</td>
                    </tr>
                    <tr v-if="summary.customer!=null && summary.customer.customer!=null">
                        <th>Customer</th>
                        <td>{{summary.customer.customer.company}}</td>
                    </tr>
                    <tr v-if="summary.customer!=null && summary.customer.customer!=null">
                        <th>Address</th>
                        <td>{{summary.customer.customer.address}}</td>
                    </tr>
                    <tr>
                        <th>Balance</th>
                        <td>
                            {{summary.price | currency}}
                        </td>
                    </tr>
                    <tr>
                        <th>Overdue</th>
                        <td>{{summary.payment_due | currency}}</td>
                    </tr>
                    <tr>
                        <th>Shipping</th>
                        <td>
                            {{summary.ship_type}} <br/>
                            {{new Date(summary.ship_date).toLocaleDateString('en-US')}}
                        </td>
                    </tr>
                    <tr>
                        <th>Order Cost</th>
                        <td>
                            {{summary.cost | currency}}
                        </td>
                    </tr>
                    <tr>
                        <th>Order Profit</th>
                        <td>
                            {{(summary.price - summary.cost) | currency}}
                        </td>
                    </tr>
                    <tr v-if="summary.created_by!=null">
                        <th>Created</th>
                        <td>
                            {{summary.created_by.full_name}} <br>
                            {{summary.created_at}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>

    import {Paths} from '@/paths';

    export default{
        props: ['order_id','refresh'],
        data(){
            return {
                loading: true,
                summary: null,
            }
        }, methods: {
            loadSummary(){
                let app = this;
                if (app.order_id == 0) {
                    app.summary = null;
                    return;
                }
                this.loading = true;
                this.axios.post(Paths.order.summary, {'order_id': app.order_id}).then((response) => {
                    app.summary = response.data;
                }).finally(() => {
                    app.loading = false;
                });

            }

        },
        watch: {
            order_id: function () {
                this.loadSummary();
            },
            refresh:function () {
                this.loadSummary();
            }
        },
        mounted(){
            console.log(this.order_id);
            this.loadSummary();
        }
    }
</script>