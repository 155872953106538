<template>


    <div class="w-100 bg-light shadow p-3">
        <p class="heading">Categories</p>
        <div class="list-group">
            <a href="#" @click.prevent="selected_category=0" class="list-group-item">All Categories</a>
        </div>
        <div class="list-group" v-for="(category,index) in $store.getters.categories" v-bind:key="index">
            <a href="#" @click.prevent="selected_category=category.id" class="list-group-item">{{category.name}}</a>
            <div class="list-group">
                <a href="#" class="list-group-item"
                   v-for="(subcategory,sub_index) in _.filter($store.getters.sub_categories, {category_id: parseInt(category.id)})"
                   v-bind:key="sub_index">{{subcategory.name}}</a>
            </div>

            <!--<a href="#" class="list-group-item">Item 2</a>-->
            <!--<div class="list-group">-->
            <!--<a href="#" class="list-group-item">Item 2.1</a>-->
            <!--<a href="#" class="list-group-item">Item 2.2</a>-->
            <!--<a href="#" class="list-group-item">Item 2.3</a>-->
            <!--</div>-->


            <!--<a href="#" class="list-group-item">Item 3</a>-->
            <!--<div class="list-group">-->
            <!--<a href="#" class="list-group-item">Item 3.1</a>-->
            <!--<a href="#" class="list-group-item">Item 3.2</a>-->
            <!--<a href="#" class="list-group-item">Item 3.3</a>-->
            <!--</div>-->
        </div>

    </div>


    <!--<div class="col-12 bg-light shadow p-3" v-if="sub_categories.length">-->
    <!--<p class="heading">Sub Categories</p>-->
    <!--<hr>-->
    <!--<div class="list-group">-->
    <!--<a href="#" @click.prevent="$emit('sub_category_clicked',0)"-->
    <!--class="list-group-item list-group-item-action">All</a>-->
    <!--<a href="#"-->
    <!--@click.prevent="$emit('sub_category_clicked',category.id)"-->
    <!--v-for="category in sub_categories"-->
    <!--v-bind:key="category.id"-->
    <!--class="list-group-item list-group-item-action">{{category.name}}</a>-->

    <!--</div>-->
    <!--</div>-->
</template>

<script>
    export default{
        props: ['category_id'],
        data(){
            return {
                selected_category: 0,
            }

        },
        watch: {
            selected_category(new_category_id) {
                this.$emit('category_clicked', new_category_id);
            }
        },
        computed: {
            sub_categories(){
                let list = this._.filter(this.$store.getters.sub_categories, {category_id: parseInt(this.category_id)});
                this.$emit('has_sub_categories', list.length);
                return list;
            }
        }
    }
</script>

