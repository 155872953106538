<template>
    <div class="modal fade" id="waste" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Add Wasted Items</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body bg-light text-left">
                    <div class="form-group">
                        <label for="">Enter Quantity</label>
                        <input type="number" class="form-control" v-model="input.credit">
                        <span class="text-danger">{{getError('credit')}}</span>
                    </div>
                    <input type="submit" :disable="disable" @click="saveWaste" class="btn btn-outline-info btn-sm"
                           value="Save">
                    <button ref="close_btn" v-show="false" type="button"
                            class="btn btn-danger shadow mb-1"
                            data-dismiss="modal">Close
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Forms from '@/forms';
    export default{
        extends: Forms,
        props: ['inventory'],
        data(){
            return {
                input: {
                    credit: 0,
                    inventory_id: 0,
                },
                disable: false,

            }
        },
        methods: {
            saveWaste(){
                let app = this;
                app.disable = true;
                this.axios.post(Paths.waste.store, this.input).then(() => {
                    app.$emit('wasteSaved');

                    app.$refs['close_btn'].click();
                }).catch((errors) => {
                    app.setErrors(errors.response);
                }).finally(() => {
                    app.disable = false;
                });
            }
        },
        watch: {
            inventory(newVal){
                this.input.inventory_id = newVal.id;
            }
        },
        mounted(){
            if (this.inventory != null)
                this.input.inventory_id = this.inventory.id;
        }
    }

</script>