<template>

    <select class="form-control ml-sm-2 form-control-sm w-200" v-model="customer_id"
            @change="$emit('changed',customer_id);">
        <option value="0">All Customers</option>
        <option v-for="c in customers" v-bind:key="c.id" :value="c.id">{{c.customer.company}}</option>
    </select>

</template>
<script>
    import {Paths} from '@/paths';
    export default{
        data(){
            return {
                customers: [],
                customer_id: 0,
            }

        },
        methods: {
            loadCustomers(){
                let app = this;
                this.axios.get(Paths.customer.index).then((response) => {
                    app.customers = response.data;
                }).catch(() => {
                });
            },
        },
        mounted(){
            this.loadCustomers();
        }
    }
</script>