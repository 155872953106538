<template>
    <div class="row">
        <axios :data="{date:full_date,range:range}" :url="url" type="post" :trigger="trigger" v-on:success="done"
               v-on:error="fail"></axios>
        <div class="col-12 form-inline">
            <div class="form-group mr-3 mb-2">
                <!--<label for="">Search By</label>-->
                <select class="form-control w-100" v-model="range">
                    <!--<option value="0">Search By</option>-->
                    <option v-for="(search,index) in types" :value="index" v-bind:key="index">
                        Search by {{search}}
                    </option>
                </select>
            </div>

            <div class="form-group mr-3 mb-2">
                <select id="" class="form-control" v-model="month">
                    <option v-for="(m,index) in months" v-bind:key="index" :value="index">{{m}}</option>
                </select>
            </div>
            <div class="form-group mr-3 mb-2">
                <select class="form-control" v-model="date">
                    <option v-for="(day,index) in days" v-bind:key="index" :value="day">{{day}}</option>
                </select>
            </div>
            <div class="form-group mr-3 mb-2">
                <select class="form-control" v-model="year">
                    <option v-for="(y,index) in years" v-bind:key="index" :value="y">{{y}}</option>
                </select>
            </div>

            <div class="form-group">
                <input type="button" @click="startSearch"
                       class="btn  btn-outline-info btn-lg mb-md-2  mr-2 "
                       value="Search">
                <!--<input type="button" @click="$emit('clear')"-->
                       <!--class="btn  btn-outline-info btn-lg mb-md-2 "-->
                       <!--value="All Records">-->
            </div>

        </div>
    </div>

</template>
<script>
    import Axios from '@/views/actions/Axios';
    export default{
        components: {Axios},
        props: ['types', 'url','refresh'],
        data(){
            return {

                range: 0,
                month: 12,
                date: 1,
                year: 2019,
                full_date: '',
                trigger: false,
                days: [],
                months: {
                    1: 'Jan',
                    2: 'Feb',
                    3: 'Mar',
                    4: 'Apr',
                    5: 'May',
                    6: 'Jun',
                    7: 'Jul',
                    8: 'Aug',
                    9: 'Sep',
                    10: 'Oct',
                    11: 'Nov',
                    12: 'Dec'
                },
                years: [],
            }
        },
        methods: {
            done(response){
                this.$emit('searched', response.data);
                this.trigger = false;
            },
            fail(){
                this.trigger = false;
            },
            startSearch(){
                this.trigger = true;
            },
            getDaysInMonth(){

                return new Date(this.year, this.month, 0).getDate();

            },

            setFullDate(){
                this.full_date = this.month + "/" + this.date + "/" + this.year;
            },
            loadYears(){
                let app = this;
                app.years = [];


                for (let i = 2019; i <= app.year; i++) {
                    app.years.push(i);
                }
                console.log(app.years);

            },
            loadDays(){
                let app = this;
                app.days = [];
                for (let i = 1; i <= this.getDaysInMonth(); i++) {
                    app.days.push(i);
                }



            },

        },
        watch: {
            refresh(){
                this.startSearch();
            },
            month(){
                this.loadDays();
                this.setFullDate();
            },
            year(){
                this.setFullDate();
            }, date(){
                this.setFullDate();
            },
        },
        mounted(){
            this.loadDays();

            let date = new Date();

            this.year = date.getFullYear();

            this.month = date.getMonth() + 1;
            this.date = date.getDate();
            this.setFullDate();
            this.loadYears();

        }
    }
</script>