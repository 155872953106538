<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <!--<div class="modal fade" id="order_detail" tabindex="-1" role="dialog"-->
        <!--aria-labelledby="exampleModalCenterTitle" aria-hidden="true">-->
        <!--<div class="modal-dialog modal-lg" role="document">-->
        <!--<div class="modal-content">-->
        <!--<div class="modal-header">-->
        <!--<h4 class="modal-title custom-color" id="exampleModalCenterTitle">Order Detail</h4>-->
        <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
        <!--<span aria-hidden="true">&times;</span>-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="modal-body text-left">-->
        <!--<detail :order="order" v-on:sales_man_assigned="salesManAssigned" v-on:approved="approved"-->
        <!--v-on:assigned="assigned" v-on:rejected="rejected"-->
        <!--v-on:warehouse_assigned="warehouseAssigned"></detail>-->
        <!--<div class="row">-->
        <!--<div class="col-12 ">-->
        <!--<button type="button" class="btn float-right btn-outline-info btn-sm btn-wide"-->
        <!--data-dismiss="modal">Close-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <div class="row">
            <div class="col-sm-12 mb-3">
                <div class="row ">
                    <div class="col-12 bg-light shadow p-3  text-left">
                        <p class="heading">Search By Date</p>
                        <hr/>
                        <date-filter :refresh="refresh_date" :url="url.date_search"
                                     :types="{1:'Daily',2:'Weekly',3:'Monthly'}"
                                     v-on:searched="searchedByDate" v-on:clear="type=0;fetchOrders()"></date-filter>
                    </div>
                </div>

            </div>

        </div>

        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <p class="heading">Orders <span v-if="date_range.length<15">on</span><span v-else>between</span> <i
                        class="text-success">{{date_range}}</i></p>
                <hr/>
                <div class="row">
                    <div class="col-8 pl-2 form-inline">
                        <div class="form-group mr-2 mb-2">

                            <customer-filter v-on:changed="customerSelected"></customer-filter>
                        </div>
                        <div class="form-group mb-2">
                            <order-types :order_type="type" v-on:changed="orderTypeChanged"></order-types>
                        </div>


                    </div>
                    <div class="col-sm-4 text-right">
                        <router-link class="btn btn-outline-info mr-2" :to="{name:'payment.add'}">Add Payment
                        </router-link>
                        <router-link class="btn btn-outline-info mr-2" :to="{name:'payments'}">Payments </router-link>
                        <router-link class="btn btn-outline-info" :to="{name:'orders.create'}">New Order </router-link>
                    </div>
                </div>


                <stats :orders="computedOrders"></stats>

                <div class="table-responsive">
                    <table class="table table-hover table-striped">
                        <tr>
                            <th scope="col" class="border-top-0">#</th>
                            <th scope="col" class="border-top-0">Quantity</th>
                            <th scope="col" class="border-top-0">Price</th>
                            <th scope="col" class="border-top-0">Status</th>
                            <th scope="col" class="border-top-0">Order Date</th>
                            <th scope="col" class="border-top-0">Customer</th>
                            <th scope="col" class="border-top-0" v-if="$auth.user().role=='admin'">Salesman assigned
                            </th>

                            <th scope="col" class="border-top-0">Detail</th>
                            <th scope="col" class="border-top-0">Action</th>
                            <th scope="col" class="border-top-0">PDF</th>

                        </tr>

                        <tbody>
                        <tr :class="{'table-danger':order.status==3,'table-success':order.status==5}"
                            v-for="(order,index) in computedOrders"
                            v-bind:key="index">
                            <th>OD-{{order.id}}</th>
                            <!--<td>{{order.}}</td>-->
                            <td>{{order.quantity}}</td>
                            <td>{{order.price | currency}}</td>
                            <td>{{readCode(order.status)}}</td>
                            <td>{{new Date(order.order_date).toLocaleDateString('en-US')}}</td>
                            <td>{{order.customer.customer.company}}</td>
                            <td v-if="$auth.user().role=='admin'">
                                {{order.salesman == null ? 'None' : order.salesman.user.full_name}}
                            </td>
                            <td>
                                <router-link class="text-grey"
                                             :to="{name:'orders.admin.detail',params:{order_id:order.id}}">
                                    <icon icon="info" size="1x"></icon>
                                </router-link>
                                <!--<button @click="currentData(order)" class="bg-transparent text-grey border-0 "-->
                                <!--data-toggle="modal"-->
                                <!--data-target="#order_detail">-->

                                <!--</button>-->
                            </td>
                            <td>
                                <router-link class="btn btn-sm btn-outline-info text-small"
                                             :to="{name:'orders.edit',params:{order_id:order.id}}">Edit Order</router-link>
                            </td>
                            <td>
                                <a class="btn btn-sm  btn-outline-info text-small" @click="download_pdf(index)">
                                    Download Invoice
                                </a>
                            </td>
                        </tr>
                        <tr v-if="computedOrders.length==0">
                            <td colspan="12">No Record found </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/loader';
    import Codes from '@/order_codes';
    import {Paths} from '@/paths';
    //    import Detail from './salesman/OrderDetail.vue';
    import DateFilter from '@/views/components/filter/Date.vue';
    import CustomerFilter from '@/views/components/filter/Customer.vue';
    import OrderTypes from '@/views/components/filter/OrderTypes.vue';
    import Stats from '@/views/order/Stats.vue';
    export default{
        extends: Loader,
        components: {DateFilter, CustomerFilter, OrderTypes, Stats},
        data(){
            return {
                orders: [],
                order: null,
                type: 1,
                customer_id: 0,
                total_orders: 0,
                url: {
                    date_search: '',
                },
                date_range: '',
                refresh_date: false,
                pdf_path: '',
            }
        },
        methods: {
            customerSelected(customer_id){
                this.customer_id = customer_id;
            },
            orderTypeChanged(type){
                this.type = type;
            },
            searchedByDate(data){
                this.orders = data.orders;
                this.type = 0;
                if (data.start_date == data.end_date) {
                    this.date_range = data.start_date;
                    return;
                }

                this.date_range = data.start_date + ' to ' + (data.end_date);

            },
            salesManAssigned(data){
                this.order.salesman = data;
            },
            warehouseAssigned(data){
                this.order.warehouse_staff = data;
                this.order.status = 6;
            },
            rejected(){
                this.order.status = Codes.forRejected();
                this.refresh_date = !this.refresh_date;
            },
            approved(){
                this.order.status = Codes.forApproved();
                this.refresh_date = !this.refresh_date;
            }, assigned(data){
                this.order.status = Codes.forAssigned();
                this.order.deliveries = data;
                this.refresh_date = !this.refresh_date;
            },
            currentData(order){
                this.order = order;
            },
            fetchOrders(){
                let app = this;
                this.startLoading();
                this.axios.get(Paths.order.all).then((response) => {
                    app.orders = response.data.orders;
                    app.date_range = response.data.start_date + " to " + response.data.end_date;
                    app.total_orders = response.data.length;
                }).finally(() => {
                    app.endLoading()
                });
            },
            readCode(code){
                return Codes.readCode(code);
            }, countOrders(){
                let app = this;

                if (this.type == 0) {
                    return app.total_orders = app.orders.length;
                }
                app.total_orders = 0;
                for (let i = 0; i < this.orders.length; i++) {
                    if (app.orders[i].status == this.type) {
                        app.total_orders++;
                    }
                }
            },
            download_pdf(index){
                let app = this;

                let order = app.orders[index];
                let file_name = order.customer.first_name + "_" + order.id + '_' + order.created_at;

                this.axios({

                    url: app.pdf_path + "?id=" + order.id,

                    method: 'GET',

                    responseType: 'blob',

                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));

                    var fileLink = document.createElement('a');


                    fileLink.href = fileURL;

                    fileLink.setAttribute('download', file_name + '.pdf');

                    document.body.appendChild(fileLink);


                    fileLink.click();

                });
            }
        },
        watch: {
            type(){
                this.countOrders();
            },
            customer_id(){
                this.countOrders();
            },
        },
        computed: {
            computedOrders(){
                if (this.type == 0 && this.customer_id == 0)
                    return this.orders;
                if (this.type != 0 && this.customer_id != 0) {
                    return this._.filter(this.orders, {'status': parseInt(this.type), 'customer_id': this.customer_id});
                }

                if (this.type != 0) {
//                    console.log(this._.filter(this.orders, {'status': }));
                    return this._.filter(this.orders, {'status': parseInt(this.type)});
                }

                return this._.filter(this.orders, {'customer_id': this.customer_id});

            }
        },

        mounted(){
            this.fetchOrders();
            this.url.date_search = Paths.order.search.date;
            this.pdf_path = Paths.order.pdf;
            console.log(this.pdf_path);
        }
    }
</script>

<style type="text/css">
    .text-small {
        font-size: 1em !important;
        font-weight: normal;
    }
</style>