<template>
    <div class="row">
        <div class="col-sm-6 mx-auto bg-white shadow p-3">
            <user-suggestions v-on:suggested="suggested"></user-suggestions>
            <p v-if="user!=null">You are starting chat with <strong>{{user.full_name}}</strong> who  {{role}}
            </p>
            <p class="text-danger" v-if="has_error">Error occurred on server, please contact support</p>

            <input type="button" v-if="user!=null" :disabled="loading" @click="startChat()"
                   class="btn btn-outline-info btn-sm "
                   value="Start Chat"/>
        </div>
    </div>
</template>

<script>
    import UserSuggestions from '@/views/suggestion/UserSuggestion.vue';
    import loader from '@/loader';
    import {Paths} from '@/paths';
    import {Bus}from '@/main';
    export default{
        extends: loader,
        data(){
            return {
                user: null,
                has_error: false,
            }
        },
        computed: {
            role(){
                if (this.user == null) {
                    return '';
                }
                if (this.user.role == 'admin') {
                    return 'is from administration section';
                }
                if (this.user.role == 'sales') {
                    return 'is form sales section';
                }
                if (this.user.role == 'delivery') {
                    return 'is from delivery section';
                }
                if (this.user.role == 'customer') {
                    return 'is a customer';
                }

                return '';

            }
        },
        components: {UserSuggestions},
        methods: {
            suggested(data){
                this.user = data;

                this.has_error = false;

            },
            startChat(){
                let app = this;
                app.startLoading();
                app.axios.post(Paths.message.create, {'sent_to': app.user.id}).then((response) => {
                    app.has_error = false;
                    Bus.$emit('message-created', response.data);
                }).catch(() => {
                    app.has_error = true;
                }).finally(() => {
                    app.endLoading();
                });
            }
        }
    }
</script>