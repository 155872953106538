<template>
    <div class="modal fade" id="change_password" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Change Password</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body bg-light text-left">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Old Password</label>
                                <input type="password" class="form-control" v-model="input.old_password"/>
                                <span class="text-danger">{{getError('old_password')}}</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="password" class="form-control" v-model="input.password"/>
                                <span class="text-danger">{{getError('password')}}</span>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Confirm Password</label>
                                <input type="password" class="form-control" v-model="input.password_confirmation"/>

                            </div>
                        </div>


                    </div>

                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>
                    <div class="row" v-else="">
                        <div class="col-sm-12">
                            <button @click="update" type="button" class="btn btn-outline-info shadow mb-1 mr-2">
                                Save Changes
                            </button>

                            <button ref="close_btn" type="button" @click="closeDialog"
                                    class="btn btn-danger shadow mb-1"
                                    data-dismiss="modal">Close
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';

    export default{
        extends: forms,
        props: ['path'],
        data(){
            return {

                input: {
                    password: '',
                    old_password: '',
                    password_confirmation: '',
                }, success: false,
            }
        }, methods: {
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('updated');
                this.$refs.close_btn.click();
            },

            clearData(){
                this.input = {
                    password: '',
                    old_password: '',
                    password_confirmation: '',
                };
            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;
                this.clearData();
            },

            update(){
                let app = this;
                this.loading = true;

                this.axios.post(this.path, this.input).then(() => {
                    app.success = true;
                }).catch((errors) => {
                    app.setErrors(errors.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });

            }
        },
        mounted(){


        }
    }
</script>