<template>
    <div class="row">

        <div class="col-sm-12 text-center">
            <pulse-loader :loading="loading"></pulse-loader>
        </div>

        <OrderPaymentButton v-on:payment_done="fetchHistory()" v-if="selected_index>-1 && orders[selected_index].payment_due!=0"
                            :order="orders[selected_index]" :refresh="selected_index"></OrderPaymentButton>

        <div class="col-12">
            <div class="table-responsive custom-table">
                <table class="table table-stripped table-hover">
                    <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Total</th>
                        <th>Paid Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(order,index) in orders" :class="{'bg-info text-light':index==selected_index}"
                        class="clickable" @click="selectOrder(index)" v-bind:key="index">
                        <td>
                            {{order.invoice_number}}
                        </td>
                        <td>
                            {{new Date(order.order_date).toLocaleDateString('US')}}
                        </td>
                        <td>{{order.price | currency}}</td>
                        <td>{{order.amount_paid | currency}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {Paths} from '@/paths';
    import OrderPaymentButton from '@/views/payment/OrderPaymentButton.vue';
    export default{
        props: ['customer_id', 'refresh'],
        components: {OrderPaymentButton},
        data(){
            return {
                orders: [],
                selected_index: -1,
                loading: true,
            }
        },
        methods: {
            fetchHistory(){
                let app = this;
                app.loading = true;
                this.axios.post(Paths.order.customer_summary, {'customer_id': this.customer_id}).then((response) => {
                    app.orders = response.data;
                }).finally(() => {
                    app.loading = false;
                })

            },
            selectOrder(index){
                this.selected_index = index;

            }
        },
        mounted(){
            this.fetchHistory();
        },
        watch: {
            customer_id(){
                this.fetchHistory();
            },
            refresh(){
                this.fetchHistory();
            }
        }
    }
</script>