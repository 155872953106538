<template>
    <div class="row">
        <div class="col-sm-12" v-if="!change">{{intervals[payment_interval]}}  <a href="#"
                                                                                  @click.prevent="change=true">Change</a>
        </div>
        <div class="col-sm-12" v-else="">
            <div class="form-group">
                <select name="" id="" v-model="interval" class="form-control">
                    <option value="0">Select Interval</option>
                    <option v-for="(inter,key,index) in intervals" :value="key" :key="index">{{inter}}</option>
                </select>
                <span class="text-danger">{{getError('payment_interval')}}</span>
            </div>
            <input type="button" class="btn btn-outline-info btn-sm" value="Update" @click="update">
        </div>
    </div>
</template>
<script>
    import forms from '@/forms'
    import {Paths} from '@/paths'
    export default{
        extends: forms,
        props: ['payment_interval', 'customer_id'],
        data(){
            return {
                interval: 0,
                intervals: {1: 'Daily', 2: 'Weekly', 3: 'Monthly'},
                change: false,

            }
        },
        methods: {
            loadDefault(){
                this.interval = this.payment_interval;
            },

            update(){
                let app = this;
                app.loading = true;
                app.axios.post(Paths.customer.payment_interval, {
                    'payment_interval': this.interval,
                    'customer_id': this.customer_id
                }).then(() => {

                    app.$emit('updated', app.interval);
                    app.change = false;
                }).catch((error) => {
                    app.setErrors(error.response);

                }).finally(() => {
                    app.loading = false;
                });
            }

        },
        mounted(){
            this.loadDefault();
        }
    }
</script>