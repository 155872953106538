<template>
    <div class="row">
        <div class="col-sm-12" v-if="!change">
            ${{parseFloat(max_payment_limit).toFixed(2)}}  <a href="#" @click.prevent="change=true;"> Change</a>
        </div>
        <div class="col-sm-12" v-if="change">
            <div class="form-group">
                <input type="text" class="form-control" v-model="payment">
                <span class="text-danger">{{getError('max_payment_limit')}}</span>
            </div>
            <input :disabled="!submit" type="button" @click="updatePayment" class="btn btn-outline-info btn-sm"
                   value="Update">
        </div>


    </div>
</template>
<script>
    import forms from '@/forms';
    import {Paths} from '@/paths';
    export default{
        extends: forms,
        props: ['max_payment_limit', 'customer_id'],
        data(){
            return {
                payment: 0,
                change: false,
                submit: true,
            }
        },
        methods: {
            loadDefault(){
                this.payment = this.max_payment_limit;
            },
            updatePayment(){
                let app = this;
                app.submit = false;
                this.axios.post(Paths.customer.max_payment_limit, {
                    'max_payment_limit': this.payment,
                    'customer_id': this.customer_id
                }).then(() => {
                    app.change = false;
                    app.$emit('updated', app.payment);

                }).catch((error) => {
                    app.setErrors(error.response)
                    console.log(JSON.stringify(app.getErrors()));
                }).finally(() => {
                    app.submit = true;
                });

            }
        },

        mounted(){
            this.loadDefault();
        },
    }
</script>