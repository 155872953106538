<template>
    <select class="form-control" :value="value" v-on:change="$emit('input',$event.target.value)">
        <option value="0">Select</option>
        <option v-for="category in sub_categories" :value="category.id" v-bind:key="category.id">{{category.name}}
        </option>
        <option value="-1">New</option>
    </select>

</template>
<script>

    export default{
        props: ['category_id', 'value'],
        data(){
            return {}
        },

        computed: {
            sub_categories(){
                return this._.filter(this.$store.getters.sub_categories, {category_id: parseInt(this.category_id)});

            }
        },
        mounted(){
        }
    }
</script>