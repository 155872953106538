/**
 * Created by Asad on 12/30/2019.
 */
export default {

    computed: {
        to(){
            if (this.isSender) {
                return this.message.to.first_name.charAt(0) + this.message.to.last_name.charAt(0);
            }
            return this.message.from.first_name.charAt(0) + this.message.from.last_name.charAt(0);


        },
        unread(){
            let unread = this._.filter(this.message.messages, {'is_read': 0, 'sent_by': this.senderId})

            return unread.length;
        },
        getUser(){
            return this.$auth.user().id;
        },
        isSender(){
            return this.$auth.user().id == this.message.sent_by;
        },
        senderId(){
            if (this.isSender) {
                return this.message.sent_to;
            }
            return this.message.sent_by;
        },
        sender(){
            if (this.isSender) {
                return this.message.to.full_name;
            }
            return this.message.from.full_name;


        },
        lastMessage(){
            return this.message.messages[this.message.messages.length - 1];
        },
        hasLastMessage(){
            return this.message.messages.length > 0;
        },

        show(){
            if (this.message.from == null) {
                return false;
            }
            if (this.message.to == null) {
                return false;
            }
            // if (this.message.messages.length == 0) {
            //     return false;
            // }
            return true;
        }
    }
}