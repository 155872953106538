<template>
    <nav class="navbar navbar-expand-lg bg-light">
        <a class="navbar-brand custom-color" href="#" >US HALAL FOOD</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav" v-if="!$auth.check()">
                <li class="nav-item active text-black" v-for="(route,index) in guest" v-bind:key="index">
                    <router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>

                </li>

            </ul>
            <ul class="navbar-nav" v-if="$auth.check('admin')">
                <li class="nav-item active" v-for="(route,index) in admin" v-bind:key="index">
                    <router-link class="nav-link" :to="{name:route.path}">{{route.name}}</router-link>

                </li>

            </ul>
        </div>
    </nav>
</template>
<script>
    export default{
        data(){
            return {
                guest: [
                    {name: 'Home', path: 'index'},
                    {name: 'Login', path: 'login'}
                ],
                admin:[
                    {name: 'Home', path: 'index'},
                    {name: 'Dashboard', path: 'dashboard'},
                    {name: 'Sales Man', path: 'salesman'},
                ],
            }
        }
    }
</script>