<template>
    <div class="form-group">
        <label for="">Search User</label>
        <input type="text" v-on:focus="fetch_suggestion=true;" v-model="query" class="form-control">
        <div class="list-group">

            <a @click.prevent="selectSuggestion(suggestion)" v-for="suggestion in suggestions"
               v-bind:key="suggestion.id" href="#"
               class="list-group-item list-group-item-action">{{suggestion.full_name}}</a>

        </div>

    </div>
</template>

<script>
    import {Paths} from '@/paths';
    import _ from 'lodash';
    export default{
        data(){
            return {
                query: '',
                fetch_suggestion: true,
                suggestions: [],
            }
        },
        methods: {
            selectSuggestion(data){
                this.fetch_suggestion = false;
                this.query = data.full_name;
                this.$emit('suggested', data);
                this.suggestions = [];
            },
            fetch(){
                let app = this;
                this.axios.post(Paths.user.suggestion, {q: this.query}).then((response) => {
                    app.suggestions = response.data;
                });
            },
        },
        watch: {
            query: _.debounce(function (val) {
                if (!this.fetch_suggestion) {
                    return;
                }
                if (val.trim().length == 0) {
                    return;
                }
                this.fetch();

            }, 500),
        }
    }
</script>