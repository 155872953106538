<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <div class="form-check pl-0">
                    <input type="checkbox" v-model="has_discount"/>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="col-12 text-left" v-if="has_discount">
                <div class="form-group">
                    <label>Discount (%)</label>
                    <input type="number" min="0" @keypress="isNumber($event)" step=".01" max="99.9"
                           class="form-control form-control-sm "
                           v-model="discount"/>
                </div>
            </div>
        </transition>
        <axios :url="url" :trigger="trigger" type="post" v-on:success="done" v-on:error="error"
               :data="{inventory_id:inventory_id,discount:discount,has_discount:has_discount}"></axios>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Forms from '@/forms';
    import Axios from '@/views/actions/Axios';
    export default{
        extends: Forms,
        components: {Axios},
        props: ['inventory_id', 'old_discount'],
        data(){
            return {
                has_discount: false,
                url: '',
                trigger: false,
                discount: 0,
                hold: null,
            }
        },
        methods: {
            done(response){
                this.trigger = false;
                this.$emit('discounted', response.data);
            }, error(){
                this.trigger = false;
            },
            isNumber: function (evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                    return;
                }
                return true;
            }
        },
        watch: {
            discount(val){
                if (parseFloat(val) > 99.9) {
                    this.discount = 99.9;
                }
                clearTimeout(this.hold);
                if (val == "") {
                    return;
                }
                let app = this;
                this.hold = setTimeout(function () {

                    app.trigger = true;
                }, 1000);
            },

            has_discount(val){
                if (!val) {
                    this.discount = 0;
                }
            }
        },
        mounted(){
            this.url = Paths.inventory.discount;
            this.has_discount = this.old_discount && this.old_discount.discount > 0;
            this.discount = this.old_discount ? this.old_discount.discount : 0;
        }

    }
</script>