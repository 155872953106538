<template>

    <div class="row">
        <div class="col-sm-12 bg-light shadow p-3 text-left ">
            <p class="heading">Welcome {{$auth.user().first_name}}</p>
            <hr>
            <div class="row">

                <DashboardIcon route="orders.warehouse" src="assets/icons/order.png"
                               name="Orders"></DashboardIcon>
                <!--<div class="col-6 col-sm-3  mb-2">-->
                <!--<div class="row">-->
                <!--<div class="col-12 text-center pt-3 pb-3">-->
                <!--<router-link :to="{name:'deliveries.new'}">-->
                <!--<img class="img-fluid" style="max-width: 128px;"-->
                <!--src="../../../assets/icons/order.png" alt="">-->
                <!--<p class="custom-color ">Orders</p>-->
                <!--</router-link>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <DashboardIcon route="messages" src="assets/icons/message1.png"
                               name="Messages"></DashboardIcon>

            </div>

        </div>
    </div>

</template>

<script>
    import DashboardIcon from '@/views/components/DashboardIcon.vue';
    export default{
        components: {DashboardIcon},
    }
</script>

