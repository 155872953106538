<template>
    <div class="modal fade" id="edit_unit" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalCenterTitle">Update Unit</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-left">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Item Name</label>
                                <input type="text" class="form-control" v-model="input.name">
                                <span class="text-danger">{{getError('name')}}</span>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <button @click="update" type="button" class="btn btn-outline-info shadow">Update</button>
                            &nbsp;
                            <button ref="close_btn" type="button" @click="closeDialog" class="btn btn-danger shadow"
                                    data-dismiss="modal">Close
                            </button>
                        </div>


                    </div>
                    <axios :url="path" :trigger="trigger" type="post" :data="input" v-on:success="done"
                           v-on:error="error"></axios>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';
    import axios from '@/views/actions/Axios';
    export default{
        extends: forms,
        props: ['path', 'data'],
        components: {axios},
        data(){
            return {
                input: {
                    name: '',
                    id: 0,
                },
                trigger: false,
            }
        }, methods: {
            done(response){
                this.trigger = false;
                this.$emit('updated', response.data);
                this.$refs.close_btn.click();
            },
            error(error){
                this.trigger = false;
                this.setErrors(error.response);
            },

            closeDialog(){
                this.$refs.close_btn.click();
            },
            update(){

                this.trigger = true;

            }
        },
        watch: {
            data(val){
                if (val == undefined) {
                    return;
                }
                this.input.name = val.name;
                this.input.id = val.id;
            }
        },
        mounted(){
        }
    }
</script>