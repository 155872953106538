<template>
    <div class="row  bg-light pt-2 pb-2" style="">

        <!--<div class="col-sm-12 text-center">-->
            <!--<span><icon icon="map" size="1x"></icon> </span>&nbsp;&nbsp;&nbsp;2902 N 2nd Ave, Minneapolis MN 55411.-->
        <!--</div>-->

        <!--<div class="col-sm-4 offset-sm-8 col-md-3 offset-md-9 col-lg-2  offset-lg-10">-->
            <!--<div class="card shadow">-->
                <!--<div class="card-header" style="background-color: #ff7f41ff;color:white" @click="chat_clicked">-->
                    <!--<div class="row">-->
                         <!--<span class="d-inline-block btn ">-->
          <!--<img src="../../assets/icons/chat.png" style="width: 20px;" alt="">-->
      <!--</span>-->
                        <!--<span class="d-inline-block text-bold" style="font-size:1.2em">Contact Us</span>-->
                        <!--<span class="d-inline-block btn float-right text-light text-bold"-->
                              <!--style="position: relative;-->
    <!--right: 5px;-->
    <!--letter-spacing: -6px;-->
    <!--font-size: 1.5em !important;-->
    <!--line-height: 15px;">-&#45;&#45;</span>-->
                    <!--</div>-->
                <!--</div>-->

                <!--<transition class="fade">-->
                    <!--<div class="card-body" v-if="show_chat">-->
                        <!--<ul class="list-group">-->
                            <!--<li class="list-group-item"><span><icon icon="map" size="1x"></icon> </span>&nbsp;&nbsp;&nbsp;2902 N 2nd Ave, Minneapolis MN 55411.-->
                            <!--</li>-->
                            <!--<li class="list-group-item"><span><icon icon="phone" size="1x"></icon> </span>&nbsp;&nbsp;&nbsp;+1 612-564-6216-->
                            <!--</li>-->
                            <!--<li class="list-group-item"><span><icon icon="envelope" size="1x"></icon> </span>&nbsp;&nbsp;&nbsp;info@ushalalfoodinc.com-->
                            <!--</li>-->
                        <!--</ul>-->
                    <!--</div>-->
                <!--</transition>-->
            <!--</div>-->
        <!--</div>-->
    </div>
</template>
<script>
    export default{
        data(){
            return {
                show_chat: false,
            }
        },
        methods: {
            chat_clicked: function () {
                this.show_chat = !this.show_chat;
            }
        },
        mounted(){

        }
    }
</script>
<style>
    .card-header:hover {
        cursor: pointer;
    }
</style>