<template>
    <div class="container-fluid content-container pl-5 pr-5">
        <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handle_request"
               v-on:error="setErrors($event.response)"
               v-on:request="trigger=false;"></axios>

        <div class="row">
            <div class="col-sm-12 mb-3" v-if="order.id!=0">
                <div class="row ">
                    <div class="col-12 bg-light shadow p-3  text-left">
                        <p class="heading">Create Order</p>
                        <hr/>
                        <div class="row">
                            <div class="col-sm-12 mb-2 text-right">
                                <router-link :to="{'name':'orders'}" class="btn btn-outline-info ">View Orders
                                </router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Customer</label>  <br>
                                    <strong >{{order.customer.full_name}}</strong>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Ship Type</label>
                                            <select class="form-control" v-model="order.ship_type_id" id="">
                                                <option v-for="(delivery,index,key) in delivery_types()" :value="index"
                                                        v-bind:key="key">{{delivery}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Ship date</label>
                                            <input type="date" class="form-control" v-model="order.ship_date">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Order date</label>
                                            <input type="date" class="form-control" v-model="order.order_date">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Comments</label>
                                    <textarea v-model="order.comments" class="form-control" cols="30"
                                              rows="2"></textarea>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="">Discount</label> <br>
                                            <input type="checkbox" @click="order.is_amount_discount=false;"
                                                   v-model="order.is_percent_discount"> Percentage &nbsp; &nbsp;
                                            <input type="checkbox" @click="order.is_percent_discount=false;"
                                                   v-model="order.is_amount_discount"> Fixed amount <br>
                                            <input type="text" class="form-control" v-model="order.discount">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="table-responsive">
                                    <table class="table table-sm">
                                        <tr>
                                            <th>Credit Limit </th>
                                            <th>{{credit_limit | currency}}</th>
                                        </tr>
                                        <tr>
                                            <th>Balance</th>
                                            <th>
                                                <CustomerBalance :customer_id="customer_user_id"></CustomerBalance>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Last Order</th>
                                            <th>
                                                <CustomerLastOrder :customer_id="customer_user_id"></CustomerLastOrder>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!--<div class="row  " v-if="order.customer_id!=0">-->

                            <!--<div class="col-sm-12 border-top border-bottom pt-2 pb-2 mb-2">-->
                                <!--<div class="row">-->
                                    <!--<div class="col-sm-12">-->
                                        <!--<h4 class="heading">Add Item</h4>-->
                                    <!--</div>-->

                                    <!--&lt;!&ndash;<div class="col-sm-7">&ndash;&gt;-->
                                        <!--&lt;!&ndash;<label for="">Search Item</label>&ndash;&gt;-->
                                        <!--&lt;!&ndash;<v-select :options="items_dropdown"&ndash;&gt;-->
                                                  <!--&lt;!&ndash;v-model="default_item.item_id"&ndash;&gt;-->
                                                  <!--&lt;!&ndash;@search="search_items"/>&ndash;&gt;-->
                                    <!--&lt;!&ndash;</div>&ndash;&gt;-->


                                    <!--<div class="col-sm-5" v-if="default_item.item_id!=0">-->
                                        <!--Quantity on hands :<span>{{ default_item.on_hands}}</span>-->
                                        <!--<br>-->
                                        <!--<input type="text" style="max-width: 200px;"-->
                                               <!--onkeypress="return event.key === 'Enter' || (Number(event.key) >= 0 && Number(event.key) <= 9)"-->
                                               <!--v-model="default_item.quantity" class="form-control"/>-->

                                        <!--&lt;!&ndash;<br>&ndash;&gt;-->

                                        <!--<input type="button" class="mt-1 mr-1"-->
                                               <!--@click="add_quantity(10)" value="+X10">-->
                                        <!--<input type="button" @click="sub_quantity(10)" class="mt-1 mr-1"-->
                                               <!--value="-X10">-->
                                        <!--<input type="button" @click="add_quantity(1)" class="mt-1 mr-1"-->
                                               <!--value="+X1">-->
                                        <!--<input type="button" @click="sub_quantity(1)" class="mt-1 mr-1"-->
                                               <!--value="-X1">-->
                                        <!--<p class="mb-1">-->
                                            <!--Price : <strong>{{ default_item.price | currency}}  {{default_item.base_unit}} </strong>-->
                                        <!--</p>-->
                                        <!--<hr class="m-0">-->
                                        <!--<p>-->
                                            <!--Total Amount : <strong>{{default_item.amount | currency}}</strong> &nbsp;-->
                                            <!--<button @click="add_to_order"-->
                                                    <!--class="btn btn-sm btn-outline-info float-right mt-1 mb-1">-->
                                                <!--Add-->
                                            <!--</button>-->
                                        <!--</p>-->


                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</div>-->

                        <div class="row" v-if="order.customer_id!=0">
                            <div class="col-sm-12 ">
                                <div class="table-responsive pb-2 ">
                                    <table class="table table-bordered mb-2">
                                        <tr>
                                            <!--<td></td>-->
                                            <th>Total Items : {{item_count}}</th>

                                            <th colspan="2">Unit Quantity: {{unit_quantity}}</th>
                                            <!--<th></th>-->
                                            <th colspan="2">Total Amount: {{total_amount | currency}}</th>
                                            <!--<th></th>-->
                                            <th colspan="2">Discount: {{total_discount | currency}}</th>
                                            <!--<th></th>-->
                                            <th colspan="2">Net Amount: {{(total_amount - total_discount) | currency}}
                                            </th>
                                            <!--<th></th>-->
                                        </tr>
                                        <tr>
                                            <td style="min-width: 200px;">Name</td>
                                            <td>Price</td>
                                            <td>Qty</td>
                                            <!--<td style="min-width: 70px;">Free</td>-->
                                            <!--<td>Total Qty</td>-->
                                            <td>Amount</td>
                                            <!--<td>Tax</td>-->
                                            <td>Total Amount</td>
                                            <td>Order Cost</td>
                                            <td>Profit</td>
                                            <td>Profit %</td>
                                            <td>Action</td>
                                        </tr>
                                        <tr v-for="(item,index) in order.items" v-bind:key="index">

                                            <td>
                                                {{item.item_name}}
                                            </td>
                                            <td>
                                                {{item.price | currency}}
                                            </td>
                                            <td>
                                                {{item.quantity}} {{item.base_unit}}
                                            </td>
                                            <!--<td>-->
                                            <!--{{item.free}} {{item.base_unit}}-->
                                            <!--</td>-->
                                            <!--<td>{{item.total_quantity}} {{item.base_unit}}</td>-->
                                            <td>{{(item.cost) | currency}}</td>
                                            <!--<td>{{item.tax | currency}}</td>-->
                                            <td>{{item.cost * item.quantity | currency}}</td>
                                            <td>{{item.price * item.quantity | currency}}</td>
                                            <td>{{(item.price * item.quantity) - (item.cost * item.quantity) | currency}}</td>
                                            <td>
                                                {{(item.cost) == 0 ? '0' : (((item.price * item.quantity) - (item.cost * item.quantity)) / (item.cost * item.quantity)) * 100 | number('0.0')}}%
                                            </td>
                                            <td>
                                                <button @click="delete_item(index)" class="btn btn-outline-info">Delete
                                                </button>
                                            </td>
                                            <!--<td>-->
                                                <!--<button @click="edit_item(index)" class="btn btn-outline-info">Edit-->
                                                <!--</button>-->
                                            <!--</td>-->
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>


                        <div class="row" v-if="order.customer_id!=0">

                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <SelectItem @add_item_to_order="add_item_to_order"></SelectItem>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div class="row" v-if="order.customer_id!=0">
                            <div class="col-sm-12 mt-2">
                                <input type="button" class="btn btn-outline-info " @click="save_order"
                                       value="Save Order">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import forms from '@/forms';

    import {Paths} from '@/paths';
    import CustomerBalance from '@/views/customer/Balance';
    import CustomerLastOrder from '@/views/customer/LastOrder';
    import axios from  '@/views/actions/Axios';
    import SelectItem from '@/views/items/Select';
    import lists from '@/mixins/list';
    export default{
        extends: forms,
        components: {axios, CustomerBalance, CustomerLastOrder,SelectItem},
        mixins: [lists],
        props: ['order_id'],
        data(){
            return {
                request: 'post',
                url: '',
                data: '',
                trigger: false,
                customers: [],

                order: {
                    customer_id: 0,
                    ship_type_id: 1,
                    ship_date: null,
                    comments: '',
                    discount: 0,
                    price: 0,
                    cost: 0,
                    quantity: 0,
                    is_percent_discount: true,
                    is_amount_discount: false,
                    items: [],
                    order_date: null,
                    id:0,

                },
                default_item: {},
                items: [],
            }
        },
        computed: {
            customer_user_id(){
                if (this.order.customer_id == 0) {
                    return 0;
                }

                if (this.order == null) {
                    return 0;
                }

                if (this.order.customer_id == null) {
                    return 0;
                }

                return this.order.customer_id.code;
            },
            customers_dropdown(){
                let options = [];

                let app = this;

                for (let i = 0; i < this.customers.length; i++) {
                    options.push({
                        label: app.customers[i].company,
                        code: app.customers[i].user_id,
                        credit_limit: app.customers[i].max_payment_limit
                    });
                }
                return options;
            },
            item_count(){
                return this.order.items.length;
            },
            credit_limit(){
                if (this.order == null) {
                    return 0;
                }
                if (this.order.customer_id == null) {
                    return 0;
                }
                return this.order.customer_id.credit_limit;
            },
            unit_quantity(){
                let sum = 0;
                this._.each(this.order.items, function (value) {
                    sum += parseInt(value.quantity);
                })
                return sum;
            },
            total_discount(){

                if (!this.order.discount > 0) {
                    return 0;
                }

                if (this.total_amount == 0) {
                    return 0;
                }

                if (this.order.is_percent_discount) {
                    return (this.total_amount * (this.order.discount / 100)).toFixed(2);
                }

                return this.order.discount;
            },
            total_amount(){
                let amount = 0;
                this._.each(this.order.items, function (value) {
                    amount += parseFloat(value.price)*value.quantity;
                });
                return amount;
            },
            total_cost(){
                let amount = 0;
                this._.each(this.order.items, function (value) {
                    amount += parseFloat(value.cost)*value.quantity;
                });
                return amount;
            }
            , items_dropdown(){

                let options = [];
                let app = this;
                for (let i = 0; i < this.items.length; i++) {
                    options.push({
                        label: app.items[i].name,
                        code: app.items[i].id,
                        price: app.items[i].price,
                        cost: app.items[i].cost,
                        base_unit: app.items[i].weight_base_unit,
                        on_hand: app.items[i].inventory_relation == null ? 0 : app.items[i].inventory_relation.quantity
                    });
                }
                return options;
            },


        },
        methods: {
            add_item_to_order(event){
                this.order.items.push(event.item);
            },
            load_order(){
                let app = this;
                this.startRequest();
                this.axios.post(Paths.order.get, {order_id: app.order_id}).then((response) => {
                    app.order = response.data;
                    app.order.ship_date=app.order.ship_date.slice(0, 10);
                    app.order.order_date=app.order.order_date==null?(new Date()).toDateString():app.order.order_date.slice(0, 10);
                }).finally(() => {
                    app.endRequest();
                });
            },
            add_quantity(quantity){

                if (this.default_item.quantity + quantity > this.default_item.on_hands) {
                    alert('Not enough quantity is available in inventory');
                    return;
                }

                this.default_item.quantity += quantity;

            },
            sub_quantity(quantity){
                if (this.default_item.quantity - quantity < 0) {
                    this.default_item.quantity = 0;
                    return;
                }

                this.default_item.quantity -= quantity;
            },
            search_customers(data){
                this.url = Paths.customer.search;
                this.data = {company: data};
                this.request = 'post';
                this.trigger = true;
            },

            search_items(data){
                this.url = Paths.item.search_by_name;
                this.data = {name: data};
                this.request = 'post';
                this.trigger = true;
            },

            handle_request(data){
                if (this.url == Paths.customer.search) {
                    this.customers = data.data;
                    return;
                }

                if (this.url == Paths.item.search_by_name) {
                    this.items = data.data;
                    return;
                }

                if (this.url == Paths.order.update) {
                    this.$router.push({'name': 'orders'});
                }

            },
            reset_item(){
                return {
                    item_id: 0,
                    item_name: '',
                    free: 0,
                    quantity: 0,
                    tax: 0,
                    cost: 0,
                    amount: 0,
                    total_amount: 0,
                    total_quantity: 0,
                    order_cost: 0,
                    profit: 0,
                    profit_p: 0,
                    base_unit: '',
                    on_hands: 0,
                };
            },

            save_order(){
                this.order.price = this.total_amount;
                this.order.cost = this.total_cost;
                this.order.quantity = this.unit_quantity;
                this.url = Paths.order.update;
                this.request = 'post';
                this.data = this.order;
                this.trigger = true;

            },

            delete_item(index){

                this.order.items.splice(index, 1);
            },
            add_to_order(){
                this.order.items.splice(0, 0, this.default_item);
                this.default_item = this.reset_item();
            },
            load_customers(){
                this.url = Paths.customer.index;
                this.request = 'get';
                this.trigger = true;
            },


        },
        watch: {

            'default_item.item_id': function (val) {
                if (val == null) {
                    this.default_item = this.reset_item();
                    return;
                }

                this.default_item.cost = val.cost;
                this.default_item.item_name = val.label;
                this.default_item.price = val.price;
                this.default_item.base_unit = val.base_unit;
                this.default_item.on_hands = val.on_hand;
            },
            'default_item.quantity': function (val) {
                if (isNaN(val)) {
                    return;
                }
                if (val == '') {
                    val = 0;
                }
                this.default_item.total_quantity = parseInt(val) + parseInt(this.default_item.free);
                this.default_item.amount = this.default_item.total_quantity * this.default_item.price;
                this.default_item.total_amount = this.default_item.tax + this.default_item.amount;
                this.default_item.order_cost = this.default_item.cost * parseInt(this.default_item.total_quantity);
            },
            'default_item.free': function (val) {
                if (isNaN(val)) {
                    return;
                }
                this.default_item.total_quantity = parseInt(val) + parseInt(this.default_item.quantity);
                this.default_item.order_cost = this.default_item.cost * parseInt(this.default_item.total_quantity);
//                this.default_item.amount=this.default_item.total_quantity*this.default_item.price;
            },

            'default_item.tax': function (val) {
                if (isNaN(val)) {
                    return;
                }
                this.default_item.total_amount = parseInt(val) + this.default_item.amount;

            },

            'default_item.order_cost': function (val) {
                if (isNaN(val)) {
                    return;
                }
                this.default_item.profit = this.default_item.amount - val;
            },
            'default_item.amount': function (val) {
                if (isNaN(val)) {
                    return;
                }
                this.default_item.profit = val - this.default_item.order_cost;
            },
            'default_item.profit': function (val) {
                if (isNaN(val) || isNaN((val / this.default_item.order_cost))) {
                    return;
                }
                this.default_item.profit_p = this.default_item.order_cost == 0 ? 100 : ((val / this.default_item.order_cost) * 100).toFixed(2);
            }


        },


        mounted(){

            this.default_item = this.reset_item();
//            this.order.ship_date = new Date().toISOString().slice(0, 10);
//            this.order.order_date = new Date().toISOString().slice(0, 10);
            this.load_order();
        }
    }


</script>

<style type="text/css">
    label {
        margin-bottom: 2px !important;
    }

    .form-group {
        margin-bottom: 10px !important;
    }
</style>