<template>
    <div class="row">
        <div class="col-sm-12 bg-light shadow p-3  text-left ">
            <p class="heading">Select Food Items</p>
            <hr>

            <div class="row">
                <div class="col-sm-4">
                    <h4>Categories</h4>
                    <div class="row">
                        <div class="col-sm-12" style="max-height: 300px;overflow-y: scroll;height: 300px;">
                            <ul class="list-group">
                                <li v-for="(category,index) in categories" v-bind:key="category.id"><a href="#"
                                                                                                       @click.prevent="load_items(index)">{{category.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="col-sm-12">
                        <h4>Items  <input type="text" placeholder="Search Item" v-model="search_item"></h4>
                        <div class="row" style="max-height: 300px; overflow-y: scroll; height: 300px;">
                            <div class="col-sm-12 " v-for="(item,item_index) in items" v-bind:key="item_index">
                                <div class="row border" v-if="search_item.length==0||item.name.toLowerCase().indexOf(search_item.toLocaleLowerCase())>-1">
                                    <div class="col-sm-2">
                                        <img class="img-fluid" :src="$store.getters.rootPath+'item?image='+item.image"
                                             alt="">
                                    </div>
                                    <div class="col-sm-6">
                                        <h4>{{item.name}}</h4>
                                        <h5>Category : <span class="text-danger">{{category_name}}</span></h5>
                                        <h5 class="text-info">
                                            Item Weight: {{item.weight_base + ' ' + item.weight_base_unit}}</h5>
                                    </div>
                                    <div class="col-sm-4">
                                        <h4>Quantity On Hands: {{item.quantity}}</h4>
                                        <p>Price:
                                            <button class="btn btn-success btn-xs">{{item.price | currency}}</button>
                                        </p>
                                        <div class="col-sm-12">
                                            <input type="text" style="max-width: 200px;"
                                                   onkeypress="return event.key === 'Enter' || (Number(event.key) >= 0 && Number(event.key) <= 9)"
                                                   class="form-control" v-model="selected_items[item_index].quantity"/>

                                            <!--<br>-->

                                            <input type="button" class="mt-1 mr-1"
                                                   @click="add_quantity(10,item_index)" value="+X10">
                                            <input type="button" @click="sub_quantity(10,item_index)" class="mt-1 mr-1"
                                                   value="-X10">
                                            <input type="button" @click="add_quantity(1,item_index)" class="mt-1 mr-1"
                                                   value="+X1">
                                            <input type="button" @click="sub_quantity(1,item_index)" class="mt-1 mr-1"
                                                   value="-X1">
                                            <button class="class mt-1 mr-1" @click="delete_quantity(item_index)">
                                                <icon icon="trash" size="1x" alt="Delete"></icon>
                                            </button>

                                            <!--<hr class="m-0">-->
                                            <p class="mb-0">
                                                Total Amount : <strong>{{selected_items[item_index].quantity * item.price | currency}}</strong>


                                            </p>
                                            <div style="text-align: left">
                                                <button @click="add_to_order(item_index)"
                                                        class="btn btn-sm btn-outline-info float-left mt-1 mb-1">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

    import {Paths} from '@/paths';

    export default{

        data(){
            return {
                items: [],
                categories: [],
                category: null,
                selected_items: [],
                item_id: 0,
                search_item: '',
            }
        }
        ,
        computed: {
            category_name: function () {
                if (this.category == null) {
                    return '';
                }

                return this.category.name;
            }
        },
        methods: {
            load_items(index){
                let app = this;

                app.category = app.categories[index];
                app.axios.post(Paths.item.for_order, {category_id: app.categories[index].id}).then((response) => {
                    app.items = response.data;
                    app.load_selected_items();
                });
            },
            load_selected_items(){
                let app = this;
                app.selected_items = [];
                app.items.forEach(function (i) {

                    app.selected_items.push({
                        quantity: 0,
                        item_id: i.id,
                        order_id: 0,
                        price: parseFloat(i.price),
                        cost: parseFloat(i.cost),
                        free: 0,
                        item_name: i.name,
                        base_unit: i.unit,
                    });
                });
            },
            load_categories(){
                let app = this;
                app.axios.get(Paths.category.index).then((response) => {
                    app.categories = response.data;
                });
            },
            sub_quantity(quantity, index){
                this.selected_items[index].quantity -= quantity;
                if (this.selected_items[index].quantity < 0) {
                    this.selected_items[index].quantity = 0;
                }
            },
            add_quantity(quantity, index){
                this.selected_items[index].quantity += quantity;
//                console.log(this.selected_items[index]);
            },
            delete_quantity(index){
                this.selected_items[index].quantity = 0;
            }
            ,
            add_to_order(index){
                if(this.selected_items[index].quantity==0){
                    alert('Please select quantity');
                    return
                }
                this.$emit('add_item_to_order', {
                    item: this.selected_items[index]
                });
            }
        },
        mounted(){
            this.load_categories();
        }
    }
</script>