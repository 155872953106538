<template>
    <div class="modal fade" id="edit_item" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Add Food Item</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body bg-light text-left">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Select category for food item</label>
                                <select name="" class="form-control" id="" v-model="input.category_id">
                                    <option value="0">Select</option>
                                    <option v-for="(category,index) in $store.getters.categories" v-bind:key="index"
                                            :value="category.id">{{category.name}}
                                    </option>
                                </select>
                                <span class="text-danger">{{getError('category_id')}}</span>
                            </div>
                            <div class="form-group">
                                <label for="">Name of food item</label>
                                <input type="text" class="form-control" v-model="input.name">
                                <span class="text-danger">{{getError('name')}}</span>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="">Select Unit</label>
                                        <select class="form-control" v-model="input.unit_id">
                                            <option value="0">Select</option>
                                            <option v-for="(unit,index) in $store.getters.units" v-bind:key="index"
                                                    :value="unit.id">{{unit.name}}
                                            </option>
                                        </select>
                                        <span class="text-danger">{{getError('category_id')}}</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="">Per Unit Price</label>
                                        <input type="text" class="form-control" v-model="input.price">
                                        <span class="text-danger">{{getError('price')}}</span>
                                    </div>

                                </div>
                            </div>


                            <div class="form-group">
                                <label for="">Image <sub class="text-info">(Optional)</sub></label>
                                <p class="text-info font-italic">
                                    Upload new image to replace old one, or ignore to keep old one</p>
                                <p v-if="image.length>0">Selected file: {{image}}</p>
                                <div class="row">
                                    <div class="col-sm-6 mb-2">
                                        <input type="button" class="btn btn-outline-info btn-block"
                                               @click="$refs.file.click()"
                                               value="Select Image">
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="button" class="btn btn-outline-dark btn-block"
                                               @click="removeImage"
                                               value="Remove Image">
                                    </div>
                                </div>
                                <input @change="setFile" style="display: none;" type="file" ref="file">
                                <p class="text-danger">{{getError('image')}}</p>
                            </div>

                        </div>

                    </div>

                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>
                    <div class="row" v-else="">
                        <div class="col-sm-12">
                            <button @click="store" type="button" class="btn btn-outline-info shadow mb-1 mr-3">Save Changes
                            </button>

                            <button ref="close_btn" type="button" @click="closeDialog"
                                    class="btn btn-danger shadow mb-1"
                                    data-dismiss="modal">Close
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';

    export default{
        extends: forms,
        props: ['path', 'item'],
        data(){
            return {
                image: '',
                input: {
                    category_id: 0,
                    unit_id: 0,
                    name: '',
                    price: 0,
                }, success: false,
            }
        }, methods: {
            addSuccess(){
                if (!this.success) {
                    return;
                }
                this.success = false;

                this.$emit('updated');
                this.$refs.close_btn.click();
            },

            clearData(){
                this.input = {
                    name: '',
                    category_id: 0,
                    unit_id: 0,
                    price: 0,
                    id: 0,

                };
                this.image = '';
            },
            closeDialog(){
                this.clearErrors();
                this.loading = false;

//                this.clearData();


            },
            setFile: function (event) {
                this.file = event.target.files[0];
                this.image = this.file.name;
            }, removeImage: function () {
                this.file = null;
                this.image = '';
            },
            getFormData: function () {
                let formData = new FormData();
                formData.append('category_id', this.input.category_id);
                formData.append('unit_id', this.input.unit_id);
                formData.append('name', this.input.name);
                formData.append('price', this.input.price);
                formData.append('id', this.input.id);
                if (this.file == null) {
                    return formData;
                }
                formData.append('image', this.file);
                return formData;
            },
            populateData(data){
                if (data == null) {
                    return;
                }
                this.input = {
                    category_id: data.category_id,
                    unit_id: data.unit_id,
                    name: data.name,
                    price: data.price,
                    id: data.id,
                };
            },
            store(){
                let app = this;
                this.loading = true;
                let formData = this.getFormData();

                this.axios.post(this.path, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(() => {
                    app.success = true;

                }).catch((errors) => {
                    app.setErrors(errors.response);
                }).finally(() => {
                    app.loading = false;
                    setTimeout(function () {

                        app.addSuccess();
                    }, 100)
                });

            }
        },
        watch: {
            item: function (newVal) {
                this.populateData(newVal);
            }
        },
        mounted(){
            this.populateData(this.item);
        }
    }
</script>