/**
 * Created by Asad on 6/21/2020.
 */

export default {


    post(route, data) {

        return window.axios.post(route, data);

    },

    get(route, data) {
        return window.axios.get(route, data);
    }


}