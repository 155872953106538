<template>
    <div class="row mb-5 pb-5" v-if="pages.length>0 && total>10">
        <div class="col-12">
            <ul class="pagination pagination-sm ">
                <li class="page-item" v-if="current_page!=0"><a class="page-link text-success"
                                                                @click.prevent="current_page=0"
                                                                href="#">Start</a>
                </li>
                <li class="page-item" v-bind:key="page"
                    v-for="page in paging"><a
                        @click.prevent="current_page=page" class="page-link text-success"
                        href="#">{{page + 1}}</a></li>
                <li class="page-item"><a class="page-link text-success"
                                         @click.prevent="current_page=pages.length-1"
                                         href="#">End</a></li>
                <!--<li class="page-item"><a class="page-link" href="#">3</a></li>-->
                <!--<li class="page-item"><a class="page-link" href="#">Next</a></li>-->
            </ul>
        </div>
    </div>
</template>

<script>
    export default{

        props: ['total'],
        data(){
            return {

                pages: [],
                current_page: 0,
            }
        },
        watch: {
            current_page: function (new_page) {
                this.$emit('page_changed', new_page);
            },
            total: function (newTotal) {
                let app = this;
                app.pages = [];
                for (let i = 0; i <= (newTotal / 50); i++) {
                    app.pages.push(i)
                }

//                if (newTotal % 50 > 0 && newTotal > 50) {
//                    app.pages.push(app.pages.length);
//                }
            }
        },
        computed: {
            paging(){
                let app = this;
                let end = app.current_page + 10;
                let start = app.current_page
                if (end >= app.pages.length) {
                    end = app.pages.length;

                }

                if (end - start < 10) {
                    start = end - 10;
                }

//                console.log(start);
//                console.log(end);

                return app.pages.slice(start, end);
//                return this._.filter(this.pages, function (page) {
//                    return app.current_page < 10 || Math.abs(page - app.current_page) < 5;
//                });
            },
        },

    }
</script>