<template>
    <div class="table-responsive" v-if="user!=null">
        <table class="table table-striped">
            <tr v-if="$auth.check('customer')">
                <td>Company</td>
                <td>{{user.company}}</td>
            </tr>
            <tr v-if="$auth.check('customer')">
                <td>Contact Person</td>
                <td>{{user.first_name+' '+user.last_name}}</td>
            </tr>
            <tr v-if="!$auth.check('customer')">
                <td class="border-top-0">Name</td>
                <td class="border-top-0">{{user.first_name + ' ' + user.last_name}}</td>
            </tr>
            <tr>
                <td>Username</td>
                <td>{{user.user_name}}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>{{user.email}}</td>
            </tr>
            <tr>
                <td>Phone</td>
                <td>{{user.phone}}</td>
            </tr>
            <tr>
                <td>Address</td>
                <td>{{user.address}}</td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default{
        props: ['user'],
        watch: {
            user(){

            }
        }
    }
</script>