<template>
    <div class="row" v-if="registration!=null">
        <div class="col-sm-12">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <td class="border-top-0">Name</td>
                        <td class="border-top-0">{{registration.customer.full_name}}</td>
                    </tr>

                    <tr>
                        <td>Email</td>
                        <td>{{registration.customer.email}}</td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>
                            {{registration.customer.customer.phone == null ? 'Not Provided' : registration.customer.customer.phone}}
                        </td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>
                            {{registration.customer.customer.address == null ? 'Not Provided' : registration.customer.customer.address}}
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td v-if="registration.status==3" class="text-success">Approved</td>
                        <td v-if="registration.status==4" class="text-danger">Rejected</td>
                    </tr>
                    <tr v-if="registration.status==4">
                        <td>Reason</td>
                        <td>{{registration.reason}}</td>
                    </tr>
                </table>
            </div>
        </div>


    </div>
</template>
<script>


    export default{

        props: ['registration'],

        data(){
            return {}
        }, methods: {},
        mounted(){
        }
    }
</script>