<template>
    <div class="card " :class="card_theme">
        <div class="card-header">
            Order No : {{order.id}}
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <strong>Items</strong>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        <!--<tr>-->
                        <!--<td>{{JSON.stringify(order.items)}}</td>-->
                        <!--<td></td>-->
                        <!--</tr>-->
                        <tr v-for="(item,index) in order.items" :key="index">
                            <td  :class="text_color">{{item.item_name}}</td>
                            <td  :class="text_color">{{item.quantity}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <strong>Driver</strong>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(driver,index) in order.deliveries" :key="index">
                            <td  :class="text_color">{{driver.delivery_person.full_name}}</td>
                            <td  :class="text_color">{{driver.delivery_person_detail.phone}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm-12">
                    <OrderPreparation :order="order" v-on:start="started" v-on:complete="completed"></OrderPreparation>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
    import OrderPreparation from '@/views/order/preparation/OrderPreparation.vue';
    export default{
        props: ['order'],
        components: {OrderPreparation},
        data(){
            return {
                card_theme: 'bg-light text-dark',
                text_color: 'text-dark',
            }
        },
        methods: {
            started(data){
                this.$emit('start', data);

            },
            completed(data){
                this.$emit('complete', data);

            },
            updateClass(){
                if (!this.order.preparation) {
                    return;
                }
                if (this.order.preparation.status == 1) {
                    this.card_theme = 'bg-warning text-dark';
                    this.text_color = 'text-dark';
                    return;
                }
                this.card_theme = 'bg-info text-light';
                this.text_color = 'text-light';
            }
        },
        watch: {
            order: {
                handler() {
                    // do something with the object
                    this.updateClass();
//                    console.log('Order changed' + newVal);

                },
                deep: true,

            }
        },
        mounted(){
            this.updateClass();

        }
    }
</script>