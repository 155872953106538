<template>
    <div class="row" v-if="order_item!=null">
        <div class="col-sm-12">
            <form action="">
                <div class="form-group">
                    <label for="">Item Name : <strong>{{order_item.item_name}}</strong></label>
                </div>


                <div class="form-group">
                    <label for="">Price Per {{order_item.unit}} : <strong>{{order_item.price | currency}}</strong></label>
                </div>

                <div class="form-group">
                    <label for="">Quantity ({{order_item.unit}})</label>
                    <input type="number" min="0" v-model="order_item.quantity" class="form-control">
                </div>

                <div class="form-group">
                    <label for="">Quantity Free({{order_item.unit}})</label>
                    <input type="number" min="0" v-model="order_item.free" class="form-control">
                </div>

                <div class="form-group">
                    <label for="">Price : <strong>{{order_item.quantity * order_item.price | currency}}</strong></label>
                    <!--<input type="number" :value="" class="form-control">-->
                </div>

                <button @click="saveItem()" type="button"
                        class="btn btn-outline-info custom-btn">Save
                </button>
            </form>
        </div>
    </div>

</template>

<script>
    import {Paths} from '@/paths';
    export default{
        props: ['order_item'],
        data(){
            return {}
        },
        methods: {
            saveItem(){
                let app = this;
                this.axios.post(Paths.order_items.update, {
                    'order_id': app.order_item.order_id,
                    'item_id': app.order_item.item_id,
                    'quantity': app.order_item.quantity,
                    'free': app.order_item.free,
                }).then(() => {
                }).finally(() => {
                    app.$emit('item_changed');
                })

            }
        },
        mounted(){

        },
    }
</script>