<template>
    <div>

        <div class="container-fluid pl-5 pr-5 content-container">
            <AdminDashboard v-if="$auth.check('admin')"></AdminDashboard>
            <SalesDashboard v-else-if="$auth.check('sales')"></SalesDashboard>
            <CustomerDashboard v-else-if="$auth.check('customer')"></CustomerDashboard>
            <DeliveryDashboard v-else-if="$auth.check('delivery')"></DeliveryDashboard>
            <WareHouseStaffDashboard v-else-if="$auth.check('warehouse')"></WareHouseStaffDashboard>

        </div>
    </div>
</template>


<script>
    import AdminDashboard from '../account/admin/AdminDashboard.vue';
    import SalesDashboard from '../account/salesman/SalesDashboard.vue';
    import CustomerDashboard from '../account/customer/CustomerDashboard.vue';
    import DeliveryDashboard from '../account/delivery/DeliveryDashboard.vue';
    import WareHouseStaffDashboard from '../account/inventory/WareHouseStaffDashboard.vue';




    export default{
        components: {
            AdminDashboard,
            SalesDashboard,
            CustomerDashboard,
            DeliveryDashboard,
            WareHouseStaffDashboard,

        },
        data(){
            return {

                interval: null,
            }
        },
        methods: {},
        mounted()
        {


            if (!this.$auth.check()) {
                this.$store.commit('setAllowShopping', false);
            }
            let user = this.$auth.user();
            if (user.role == 'customer' && (user.customer.registration == null || user.customer.registration.status == 3)) {
                this.$store.commit('setAllowShopping', true);
            }

//            if (user.role == 'customer') {
//                if (this.$route.query.redirect) {
//                    this.$router.push({'name': this.$route.query.redirect});
//                    return;
//                }
//                this.$router.push({'name': 'shop'});
//            }

//            if (this.$auth.check()) {
//                app.interval = setInterval(function () {
//                    app.loadNotifications();
//                }, 60000)
//                app.loadNotifications();
//            }


//            this.post('data', {});

            return false;


        }
    }
</script>